import classNames from 'classnames';
import { ApprovalProgressBar, ContributorsList } from 'components';
import { ProgressData, TreeNodeElement } from 'models';
import { FC, memo } from 'react';

import { StyledCompanyTreeNode } from './CompanyTreeNode.styled';

export type CompanyTreeNodeProps = Omit<
  TreeNodeElement,
  'children' | 'parent' | 'created' | 'modified'
> & {
  contributors?: string[];
  progress: ProgressData;
  children?: CompanyTreeNodeProps[];
  parent?: CompanyTreeNodeProps | null;
};

const CompanyTreeNode: FC<CompanyTreeNodeProps> = ({
  children,
  element,
  contributors,
  progress,
}) => (
  <StyledCompanyTreeNode className="esg-company-tree-node">
    <div
      className={classNames('esg-company-tree-node__section', {
        'esg-company-tree-node__section--folder': children,
      })}
    >
      {element}
    </div>

    {contributors && (
      <ContributorsList
        className="esg-company-tree-node__contributors"
        contributors={contributors}
      />
    )}

    <ApprovalProgressBar
      className="esg-company-tree-node__status"
      {...progress}
    />
  </StyledCompanyTreeNode>
);

export default memo(CompanyTreeNode);
