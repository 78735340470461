import { DropdownDataModule } from 'models';
import { FC, PropsWithChildren } from 'react';

import SelectModuleField from '../../../../../components/_fields/SelectModuleField';
import { useRemoveConditionalModule } from '../../../../../hooks';
import { BASE_CONFIGURABLE_MODULES } from '../../baseModules';
import BaseDropdownFieldConfiguration from '../../baseModules/BaseDropdownFieldConfiguration/BaseDropdownFieldConfiguration.component';

export type ConditionalDropdownFieldConfigurationProps =
  PropsWithChildren<DropdownDataModule>;

const ConditionalDropdownFieldConfiguration: FC<
  ConditionalDropdownFieldConfigurationProps
> = (props) => {
  const { handleRemoveModule } = useRemoveConditionalModule(props.id);
  return (
    <BaseDropdownFieldConfiguration
      {...props}
      onRemoveModule={(v) => handleRemoveModule(v)}
      renderModuleField={(fieldName) => (
        <SelectModuleField
          name={fieldName}
          label="If selected"
          parentId={props.id}
          configurableModules={BASE_CONFIGURABLE_MODULES}
        />
      )}
    />
  );
};

export default ConditionalDropdownFieldConfiguration;
