import { Organisation } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

//Get one root organisation - Toyota
const useGetOrganisations = () => {
  const { data, ...rest } = useSWR<{ data: Organisation }>(
    API_ROUTES.COMPANIES.ORGANISATIONS
  );

  return {
    organisation: data?.data,
    ...rest,
  };
};

export { useGetOrganisations };
