import { InputProps, Switch } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';
import { ConditionalField, InputField } from 'components';
import { InputDataModule, InputModuleConfig } from 'models';
import { FC, useState } from 'react';

import QuickConfigPreview from '../QuickConfigPreview';
import {
  StyledSimpleInput,
  StyledSimpleInputField,
} from './SimpleInput.styled';

type SimpleInputFieldProps<
  T = InputModuleConfig,
  ComponentProps = InputProps,
> = FieldProps<string, (value: string) => void> &
  Partial<ComponentProps> & {
    configKey: keyof T;
    config: T;
    component?: FC<ComponentProps>;
    switchLabel?: string;
  };

export function SimpleInputField<
  T = InputModuleConfig,
  ComponentProps = InputProps,
>(props: SimpleInputFieldProps<T, ComponentProps>) {
  const {
    config,
    configKey,
    component = InputField,
    placeholder: initialPlaceholder,
    switchLabel,
    ...rest
  } = props as SimpleInputFieldProps<T, InputProps>;

  const [enabled, setEnabled] = useState<boolean>(
    !!config?.[configKey] || false
  );

  return (
    <StyledSimpleInputField className="esg-simple-input-field">
      <Switch
        value={enabled}
        label={switchLabel}
        onChange={(e) => setEnabled(e.target.checked)}
      />

      <ConditionalField
        condition={enabled}
        disabled={!enabled}
        name={configKey as string}
        component={component}
        placeholder={initialPlaceholder}
        {...rest}
      />
    </StyledSimpleInputField>
  );
}

export type SimpleInputProps = InputDataModule;

const SimpleInput: FC<SimpleInputProps> = (props) => {
  return (
    <StyledSimpleInput className="esg-simple-input">
      <QuickConfigPreview {...props} />

      <SimpleInputField
        switchLabel="Tooltip helper text"
        configKey="helperText"
        placeholder="Helper text"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="Custom placeholder"
        configKey="placeholder"
        placeholder="Placeholder"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="XBRL Tag"
        configKey="xbrl"
        placeholder="XBRL"
        config={props.config as InputModuleConfig}
      />
    </StyledSimpleInput>
  );
};

export default SimpleInput;
