import { Button, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled from 'styled-components';

const StyledRunSessionButton = styled(Button)`
  &.button.button--primary {
    background-color: var(--RED-EB);

    &:hover {
      background-color: var(--RED-C4);
    }

    &::after {
      border-color: var(--RED-EB);
    }
  }
`;

const StyledSubheader = styled.p`
  ${fontSize(theme.fontSizes.FONT_14)};
  color: var(--GRAY-70);
  margin: 0;
`;

const StyledRunSession = styled(BaseLayout)`
  .esg-base-layout__content {
    padding: ${theme.sizes.SIZE_32};
  }

  .company-checkbox-label {
    ${flex('row', 'flex-start', 'center')};
    ${fontSize(theme.fontSizes.FONT_20)};
    gap: ${theme.sizes.SIZE_8};
    height: ${theme.sizes.SIZE_32};
    font-weight: 600;
    margin: 0;
  }

  .esg-run-session {
    &__organisation {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_32};

      .esg-checkbox-icon-label {
        > p {
          ${fontSize(theme.sizes.SIZE_32)};
        }
      }

      svg {
        ${size(theme.sizes.SIZE_24)}
      }
    }
    &__header {
      &--title {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_16};
        margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_8};

        h1 {
          font-weight: 400;
        }

        svg {
          ${size('30px')};
        }
      }

      &--actions {
        ${flex('row', 'space-between', 'center')}
        margin-bottom: ${theme.sizes.SIZE_16};
      }
    }

    &__content {
      ${flex('column', 'flex-start', 'flex-start')};

      gap: ${theme.sizes.SIZE_40};
      margin-top: ${theme.sizes.SIZE_80};

      &__row {
        > p {
          margin: 0;
        }
        ${flex('column')};
        gap: ${theme.sizes.SIZE_16};
      }
    }

    &__subtitle {
      margin: ${theme.sizes.SIZE_40} 0;
    }
  }
`;

export { StyledRunSession, StyledRunSessionButton, StyledSubheader };
