import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCampaignEntity = styled.div`
  padding: ${theme.sizes.SIZE_32};
  width: 100%;
  height: fit-content;
  overflow: auto;

  .esg-campaign-entity {
    &__add {
      margin-top: ${theme.sizes.SIZE_32};

      &--no-margin {
        margin-top: unset;
      }
    }

    &__items {
      ${flex('column')};
      margin-top: ${theme.sizes.SIZE_48};
      gap: ${theme.sizes.SIZE_20};
      max-width: 50%;
    }
  }
`;

export { StyledCampaignEntity };
