export const NAVIGATE_TO_MAP = {
  organisation: 'companies',
  company: 'topics',
  topic: 'subtopics',
  subtopic: 'subsubtopics',
  subsubtopic: 'reports',
};

export const MAP_ID = {
  organisation: 'sessionId',
  company: 'company',
  topic: 'topic',
  subtopic: 'subtopic',
  subsubtopic: 'subsubtopic',
};
