import { Button, useModalUtilities } from '@faxi/web-component-library';
import { useGetOrganisations } from 'api';
import { SectionCard } from 'components';
import Icon from 'components/Icon';
import { BlockUI, Each } from 'helpers';
import { Organisation } from 'models';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CompanyModal from '../../modals/CompanyModal';
import { StyledOrganisations } from './Organisations.styled';

const Organisations: FC = () => {
  const navigate = useNavigate();

  const { open, triggerRef, openModal, closeModal } = useModalUtilities();
  const btnTrigger = triggerRef.current as HTMLButtonElement;

  const { organisation, isLoading, mutate } = useGetOrganisations();
  const [activeOrg, setActiveOrg] = useState<Organisation>();

  const organisations = useMemo(
    () => Array(organisation) as Organisation[],
    [organisation]
  );

  return (
    <BlockUI loading={isLoading}>
      <StyledOrganisations
        className="esg-organisations"
        title="Organisations"
        direction="column"
      >
        <Button
          className="esg-organisations__create-btn"
          variant="outline"
          iconPosition="left"
          disabled
          icon={<Icon name="plus" />}
          onClick={() => {}}
        >
          Create an Organisation
        </Button>

        <Each
          containerAs="div"
          className="esg-organisations__container"
          of={organisations as Organisation[]}
          render={(org) => (
            <SectionCard
              view="list"
              title={org.name}
              description={org.description}
              prefixElement={<Icon name="building" />}
              onSelect={() => navigate(org.id)}
              onAddDescriptionCallback={(e) => {
                openModal(e as React.MouseEvent<HTMLButtonElement>);
                setActiveOrg(org);
              }}
            />
          )}
        />

        {open && (
          <CompanyModal
            renderAsPortal
            conditionallyControlled
            initialData={activeOrg}
            triggerRef={btnTrigger}
            mutator={mutate}
            onClose={closeModal}
            fieldsConfiguration={{
              NAME: true,
              DESCRIPTION: true,
              EMAIL: false,
              ROLE: false,
              TYPE: false,
            }}
          />
        )}
      </StyledOrganisations>
    </BlockUI>
  );
};

export default Organisations;
