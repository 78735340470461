import {
  ButtonProps,
  Image,
  StatusElementProps,
  Tooltip,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { ThreeDotMenu } from 'components';
import { FC, memo, MouseEvent, ReactNode, useMemo } from 'react';
import { copyToClipboard } from 'utils';

import BasicTag from '../../_atoms/BasicTag/BasicTag.component';
import Icon, { INameExtended } from '../../Icon';
import { StyledUserCard } from './UserCard.styled';

type EventCardType = (event: MouseEvent) => void;

export type UserCardProps = {
  className?: string;
  prefixElement?: ReactNode;
  status?: StatusElementProps<INameExtended>;
  image?: string;
  user: string;
  title: string;
  titleTag?: { text: string; color: string };
  type?: string;
  description?: string;
  withMenu?: boolean;
  view: 'grid' | 'list';
  menuItems?: ButtonProps[];
  hasActions?: boolean;
  onSelect?: () => void;
  onClick?: () => void;
  onEdit?: EventCardType;
  onDelete?: EventCardType;
  onDuplicate?: EventCardType;
  onAddDescriptionCallback?: EventCardType;
};

const UserCard: FC<UserCardProps> = (props) => {
  const {
    image,
    user,
    title,
    titleTag,
    description = '',
    withMenu = false,
    view = 'grid',
    menuItems: pMenuItems,
    className,
    onClick,
    onEdit,
    onDelete,
  } = props;

  const { showSnackBar } = useUtilities();

  const menuItems = useMemo(
    () =>
      pMenuItems ||
      ([
        {
          children: 'Edit',
          icon: <Icon name="pen" />,
          variant: 'ghost',
          iconPosition: 'left',
          onClick: (e) => {
            e.stopPropagation();
            onEdit?.(e);
          },
        },
        {
          children: 'Delete',
          icon: <Icon name="trash-can" className="color-secondary" />,
          iconPosition: 'left',
          variant: 'delete-ghost',
          onClick: (e) => {
            e.stopPropagation();
            onDelete?.(e);
          },
        },
      ] as ButtonProps[]),
    [pMenuItems, onDelete, onEdit]
  );

  return (
    <StyledUserCard
      onClick={onClick}
      $view={view}
      className={classNames(
        'esg-user-card',
        `esg-user-card--${view}`,
        'hover-effect',
        className
      )}
    >
      <div className="esg-user-card__header">
        {withMenu && (
          <ThreeDotMenu
            className="esg-user-card__header__menu"
            menuItems={menuItems}
          />
        )}
      </div>

      <div className="esg-user-card__content">
        <div className="user-image">
          <div className="user-image-container">
            {image ? (
              <Image src={image} alt={user} />
            ) : (
              <Icon name="user-solid" />
            )}
          </div>

          {titleTag && <BasicTag text={titleTag.text} color={titleTag.color} />}
        </div>

        <div
          className="esg-user-card__info"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="esg-user-card__info__name">{title}</div>

          <a
            className="esg-user-card__info__description"
            href={`mailto:${description}`}
          >
            <Icon name="envelope" className="envelope-icon" />

            <Tooltip content={description} placement="bottom">
              <span>{description}</span>
            </Tooltip>

            <Icon
              name="copy"
              onClick={(e) => {
                copyToClipboard(() => {
                  showSnackBar({
                    text: `Copied ${description} to clipboard.`,
                    variant: 'success',
                  });
                })(e, description);
              }}
            />
          </a>
        </div>
      </div>
    </StyledUserCard>
  );
};

export default memo(UserCard);
