import { Image, Input } from '@faxi/web-component-library';
import InlineEditable from 'components/_molecules/InlineEditable';
import {
  InlineModuleElement,
  IntegrationDataModule,
  IntegrationTypes,
} from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type IntegrationCanvasModuleProps =
  InlineModuleElement<IntegrationDataModule>;

export const INTEGRATION_IMAGES = {
  'kinto': '/assets/images/kinto-join.png',
  'jira': '/assets/images/jira.png',
  'slack': '/assets/images/slack.png',
} as Record<IntegrationTypes, string>;

const IntegrationCanvasModule: FC<IntegrationCanvasModuleProps> = ({
  showIcon,
  ...props
}) => {
  const integrationSrc =
    INTEGRATION_IMAGES[props.config?.integration as IntegrationTypes];

  return (
    <StyledModuleContainer className="esg-integration-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit integration label'}
        {...props}
      />

      {showIcon && (
        <div className="esg-integration-canvas-module__content">
          {props.config?.integration && (
            <Image
              className="esg-integration-canvas-module__image"
              src={integrationSrc}
              alt={props.config?.integration || ''}
            />
          )}
          <Input disabled placeholder="Value" />
        </div>
      )}
    </StyledModuleContainer>
  );
};

export default memo(IntegrationCanvasModule);
