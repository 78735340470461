import { Dropdown, Icon } from '@faxi/web-component-library';
import { CheckListDataModule, InlineModuleElement } from 'models';
import { FC, memo } from 'react';

import InlineEditable from '../../../../_molecules/InlineEditable';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type ChecklistCanvasModuleProps =
  InlineModuleElement<CheckListDataModule>;

const ChecklistCanvasModule: FC<ChecklistCanvasModuleProps> = ({
  showIcon,
  ...props
}) => {
  const { config, ...rest } = props;
  const { options } = config || {};

  return (
    <StyledModuleContainer className="esg-checklist-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit checklist label'}
        {...rest}
      />
      {showIcon &&
        (options?.length ? (
          <Dropdown
            data-no-dnd="true"
            renderAsPortal
            portalClassName="esg-canvas-module--dropdown"
            body={
              <ul className="esg-canvas-module--dropdown__ul">
                {options.map((option) => (
                  <li key={option.label?.toString()}>
                    <span />
                    <p>{option.label}</p>
                  </li>
                ))}
              </ul>
            }
            trigger={
              <div>
                {`(${options.length} elements)`} <Icon name="chevron-down" />
              </div>
            }
          />
        ) : (
          <p>(0 elements)</p>
        ))}
    </StyledModuleContainer>
  );
};

export default memo(ChecklistCanvasModule);
