import { BreadcrumbsProps } from '@faxi/web-component-library/build/components/_molecules/Breadcrumbs/Breadcrumbs.component';
import { useLocation, useParams } from 'react-router-dom';

type PageParams = {
  sessionId: string;
  company?: string;
  topic?: string;
  subtopic?: string;
  subsubtopic?: string;
  report?: string;
};

const generateCrumbs = (
  params: Readonly<Partial<PageParams>>,
  basePath: string,
  names?: string[]
): BreadcrumbsProps['crumbs'] => {
  const companyPath = `${basePath}/companies/${params.company}`;
  const topicPath = `${companyPath}/topics/${params.topic}`;
  const subtopicPath = `${topicPath}/subtopics/${params.subtopic}`;
  const subsubtopicPath = `${subtopicPath}/subsubtopics/${params.subsubtopic}`;
  const reportPath = `${subsubtopicPath}/reports/${params}`;

  const crumbs = [
    params.company && {
      text: names?.[1] || params.company,
      href: companyPath,
    },
    params.topic && {
      text: names?.[2] || params.topic,
      href: topicPath,
    },
    params.subtopic && {
      text: names?.[3] || params.subtopic,
      href: subtopicPath,
    },
    params.subsubtopic && {
      text: names?.[4] || params.subsubtopic,
      href: subsubtopicPath,
    },
    params.report && {
      text: names?.[5] || params.report,
      href: reportPath,
    },
  ].filter(Boolean) as BreadcrumbsProps['crumbs'];

  return crumbs;
};

const useBreadcrumbs = (names?: string[]) => {
  const params = useParams<PageParams>();
  const location = useLocation();
  const basePath = `/sessions/${params.sessionId}/report`;

  const crumbs = [
    { text: 'Toyota Belgium', href: basePath },
    ...generateCrumbs(params, basePath, names),
  ];

  return { crumbs, location };
};

export default useBreadcrumbs;
