import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled from 'styled-components';

const StyledOrganisations = styled(BaseLayout)`
  .esg-organisations {
    &__container {
      margin-top: ${theme.sizes.SIZE_48};
      ${flex('column', 'flex-start')};
      gap: ${theme.sizes.SIZE_20};
      max-width: 50%;
    }
  }
`;

export { StyledOrganisations };
