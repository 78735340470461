import { FC, PropsWithChildren } from 'react';

import ProfileAssignment from '../../../../../../components/ProfileAssignment';
import { USER_ASSIGNMENT_NODE_DATA } from './mock';

export type UserByIdAssignmentProps = PropsWithChildren<{}>;

const UserByIdAssignment: FC<UserByIdAssignmentProps> = () => {
  return (
    <ProfileAssignment data={USER_ASSIGNMENT_NODE_DATA} onCheck={() => {}} />
  );
};

export default UserByIdAssignment;
