import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledSessionDashboardCard = styled(NavLink)`
  border-radius: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_16};

  text-decoration: none;
  color: var(--BLACK-00);

  ${elevate_l}

  h2 {
    ${fontSize(theme.fontSizes.FONT_24)};
  }

  .esg-session-dashboard-card {
    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_8};
    }

    &__content {
      ${flex('column')};
      ${fontSize(theme.fontSizes.FONT_14)};

      gap: ${theme.sizes.SIZE_8};
      margin-top: ${theme.sizes.SIZE_32};

      &__item {
        ${size('100%', theme.sizes.SIZE_40)};

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${theme.sizes.SIZE_8};

        &__name {
          ${flex('row', 'flex-start', 'center')};
          gap: ${theme.sizes.SIZE_12};
        }

        .kinto-progress-bar[class] .kinto-progress-bar__container__percentage {
          flex: 0 0 44px;
        }
      }
    }
  }
`;

export { StyledSessionDashboardCard };
