import { Button, ModalProps, ModalRef } from '@faxi/web-component-library';
// TODO: export properly from WCL...
import classNames from 'classnames';
import { FolderNavHeader, Loading, SectionCard } from 'components';
import { EntityFormModal } from 'components/_forms';
import { EntityFormFieldProps } from 'components/_forms/EntityFormModal/EntityFormModal.component';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { Campaign, CampaignItem } from 'models';
import { FC, useCallback, useMemo, useRef } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { useDeleteCampaignItem } from '../../../../hooks';
import { useCampaignProvider } from '../../../../store/CampaignProvider';
import { useCampaignItem } from '../../context/CampaignItem';
import {
  generateCampaignItemCrumbs,
  mappedSectionTypeToIcon,
  mappedSectionTypeToStatus,
  mappedSectionTypeToTitle,
} from '../../utils';
import { StyledCampaignEntity } from './CampaignEntity.styled';

type EntityType = 'campaign' | 'topic' | 'subtopic' | 'subsubtopic';

export type CampaignEntityProps = {
  defaultItems?: Campaign[];
  campaignItem?: CampaignItem;
  entityType: EntityType;
  isLoading?: boolean;
  modalProps?: ModalProps & EntityFormFieldProps & { chooseType?: boolean };
  onSubmit: (data: Record<string, any>) => Promise<void>;
  navigateTo?: (v: string) => To;
  onMutate: () => void;
};

const mapEntityTypeToRegularText: Record<EntityType, string> = {
  'campaign': 'campaign',
  'topic': 'topic',
  'subtopic': 'sub topic',
  'subsubtopic': 'sub sub topic',
};

const CampaignEntity: FC<CampaignEntityProps> = (
  props: CampaignEntityProps
) => {
  const {
    defaultItems,
    modalProps,
    campaignItem,
    isLoading,
    entityType,
    onMutate,
    navigateTo,
    onSubmit,
  } = props;

  const {
    path: { names = [], ids = [] } = {},
    name = '',
    children: campaignItemsChildren,
    id = '',
  } = campaignItem || {};

  const { pathname } = useLocation();
  const {
    campaignItem: activeCampaignItem,
    setCampaignItem,
    removeActiveCampaignItem,
  } = useCampaignItem();

  const { campaignItemsMutationRequest } = useCampaignProvider();

  const onDeleteSubmit = useCallback(
    async (id: string) => {
      await campaignItemsMutationRequest('DELETE', { params: { id } });
      onMutate();
    },
    [onMutate, campaignItemsMutationRequest]
  );

  const { onDelete } = useDeleteCampaignItem(onDeleteSubmit);

  const modalRef = useRef<ModalRef>(null);

  const navigate = useNavigate();

  const crumbs = useMemo(
    () => generateCampaignItemCrumbs(names, ids, id, name),
    [id, ids, name, names]
  );

  const openEditModal = useCallback(
    (item: CampaignItem) => {
      setCampaignItem(item);
      modalRef?.current?.open();
    },
    [setCampaignItem]
  );

  const items = defaultItems ?? campaignItemsChildren;

  const buttonNoMargin = useMemo(
    () => pathname === APP_URI.CAMPAIGNS,
    [pathname]
  );

  const entityTitle = useMemo(
    () => `Create a new ${mapEntityTypeToRegularText[entityType]}`,
    [entityType]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <StyledCampaignEntity className="esg-campaign-entity">
      {campaignItem && (
        <FolderNavHeader
          crumbs={crumbs}
          title={name}
          description={campaignItem.description}
          updateDescription={() => openEditModal(campaignItem)}
          icon="clipboard-list"
        />
      )}
      <Button
        icon={<Icon name="plus" />}
        variant="outline"
        onClick={() => modalRef?.current?.open()}
        className={classNames('esg-campaign-entity__add', {
          'esg-campaign-entity__add--no-margin': buttonNoMargin,
        })}
      >
        {entityTitle}
      </Button>
      <EntityFormModal
        ref={modalRef}
        title={
          !activeCampaignItem ? entityTitle : `Edit ${activeCampaignItem.name}`
        }
        onSubmit={onSubmit}
        initialData={activeCampaignItem}
        onClose={removeActiveCampaignItem}
        {...modalProps}
        fieldsConfiguration={{
          TYPE:
            (modalProps?.chooseType && !activeCampaignItem) ||
            !!activeCampaignItem?.type,
          NAME: true,
          DESCRIPTION: true,
          EMAIL: false,
          ROLE: false,
        }}
      />
      {items && (
        <div className="esg-campaign-entity__items">
          {items.map((item) => {
            const { name, type, id, description } = item;

            const itemType = type === 'topic' ? 'folder' : 'data-collection';

            return (
              <SectionCard
                view="list"
                prefixElement={<Icon name="clipboard-list" />}
                key={id}
                withMenu
                title={name}
                description={description}
                {...(type && {
                  status: {
                    children: mappedSectionTypeToTitle[itemType],
                    status: mappedSectionTypeToStatus[itemType],
                    icon: mappedSectionTypeToIcon[itemType],
                  },
                })}
                onDelete={onDelete(id, name)}
                onSelect={() => {
                  navigateTo &&
                    navigate(
                      `${navigateTo(id)}${type === 'data_collection_element' ? '?form=yes' : ''}`
                    );
                }}
                onEdit={() => openEditModal(item as CampaignItem)}
                onAddDescriptionCallback={() =>
                  openEditModal(item as CampaignItem)
                }
              />
            );
          })}
        </div>
      )}
    </StyledCampaignEntity>
  );
};

export default CampaignEntity;
