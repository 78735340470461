import { ApiData, User } from 'models';
import useSWR, { SWRConfiguration } from 'swr';

import { API_ROUTES } from '../../routes';

const useGetUserProfile = (config?: SWRConfiguration) => {
  const { data, ...rest } = useSWR<ApiData<User>, Error>(
    API_ROUTES.USERS.PROFILE,
    config
  );

  return { ...rest, ...data };
};

export default useGetUserProfile;
