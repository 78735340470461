import { SelectField } from 'components';
import Icon from 'components/Icon';
import { FORM_APPROVAL, FORM_STATUS, FormApproval, FormStatus } from 'models';
import { FC, PropsWithChildren, useMemo } from 'react';

import ApprovalProgressBar from '../ApprovalProgressBar';
import { ApprovalProgressBarProps } from '../ApprovalProgressBar/ApprovalProgressBar.component';
import { ApprovalChip } from '../ApprovalProgressBar/components';
import { StyledReportStatus } from './ReportStatus.styled';

export type ReportStatusProps = PropsWithChildren<
  {
    title?: string;
    asAdmin?: boolean;
    loading?: boolean;
    formStatus?: FormStatus;
    formApproval?: FormApproval;
    onStatusChange?: (value: FormStatus, isAdmin?: boolean) => void;
  } & Pick<ApprovalProgressBarProps, 'progress'>
>;

const USER_STATUS_OPTIONS = [
  {
    value: 'In Progress',
    label: 'In progress',
    icon: <Icon color="var(--ORANGE-D0)" name="check" />,
  },
  {
    value: 'In Review',
    label: 'In Review',
    icon: <Icon color="var(--ORANGE-D0)" name="eye-solid" />,
  },
  {
    value: 'Completed',
    label: 'Completed',
    icon: <Icon color="var(--GREEN-12)" name="circle-check" />,
  },
];

const ADMIN_APPROVAL_OPTIONS = [
  {
    value: 'To Review',
    label: 'To review',
    icon: <Icon color="var(--ORANGE-D0)" name="eye-solid" />,
  },
  {
    value: 'Approved',
    label: 'Approved',
    icon: <Icon color="var(--GREEN-12)" name="thumbs-up-solid" />,
  },
];

const ReportStatus: FC<ReportStatusProps> = ({
  title = 'Status',
  asAdmin = false,
  formStatus,
  formApproval,
  progress = 0,
  loading,
  onStatusChange,
}) => {
  const userStatuses = useMemo(
    () => (
      <div className="esg-report-status__approval">
        <p>Status</p>

        {formStatus === FORM_STATUS.COMPLETED &&
        formApproval === FORM_APPROVAL.APPROVED ? (
          <ApprovalChip status={formStatus} />
        ) : (
          <SelectField
            name="approval"
            renderAsPortal
            loading={loading}
            disabled={asAdmin}
            value={formStatus}
            placeholder="None"
            options={USER_STATUS_OPTIONS}
            onChange={(v) => onStatusChange?.(v as FormStatus)}
          />
        )}
      </div>
    ),
    [loading, asAdmin, formApproval, formStatus, onStatusChange]
  );

  const adminStatuses = useMemo(
    () => (
      <div className="esg-report-status__approval">
        <p>Approval</p>

        {asAdmin ? (
          <SelectField
            name="approval"
            renderAsPortal
            loading={loading}
            value={formApproval}
            placeholder="None"
            options={ADMIN_APPROVAL_OPTIONS}
            onChange={(v) => onStatusChange?.(v as FormStatus)}
          />
        ) : (
          <ApprovalChip status={formApproval} />
        )}
      </div>
    ),
    [loading, asAdmin, formApproval, onStatusChange]
  );

  return (
    <StyledReportStatus className="esg-report-status">
      <strong className="esg-report-status__title">{title}</strong>

      <div className="esg-report-status__content">
        <ApprovalProgressBar
          title="Progress"
          status={progress < 100 ? 'In Progress' : 'Completed'}
          progress={progress}
          className="esg-report-status__approval"
        />

        {userStatuses}
        {adminStatuses}
      </div>
    </StyledReportStatus>
  );
};

export default ReportStatus;
