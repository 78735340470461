import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledApprovalProgressBar = styled.div`
  ${flex('row', 'flex-end', 'center')};
  gap: ${theme.sizes.SIZE_12};
  min-width: 176px;
  width: 100%;

  .kinto-progress-bar,
  .esg-approval-chip {
    min-width: 176px;
    width: 100%;
  }

  .esg-approval-progress-bar {
    &__title {
      ${fontSize(theme.fontSizes.FONT_16)}
      margin: 0;
      flex: 0 0 ${theme.sizes.SIZE_72};
    }
  }
`;

export { StyledApprovalProgressBar };
