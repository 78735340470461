import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionReportsReport = styled.div`
  ${flex('row')};
  height: 100%;

  .esg-session-card {
    max-width: 100%;
  }

  .esg-collapsible-side-panel {
    height: 100%;
  }

  &.esg-session-reports-report--error {
    ${flex('row', 'center', 'center')};

    .wcl-icon {
      ${fontSize(theme.fontSizes.FONT_28)};
    }
  }

  .esg-session-reports-report {
    &__content {
      padding: ${theme.sizes.SIZE_32};
      flex-grow: 1;
      overflow-y: auto;

      .input,
      .wcl-switch,
      .wcl-dropdown,
      .wcl-radio-group {
        pointer-events: none;
      }

      &__header h1 {
        margin: ${theme.sizes.SIZE_32} 0;
      }

      &__elements {
        ${flex('column')};
        ${flexGap(theme.sizes.SIZE_20, 'column')};
        ${size('100%')};
      }
    }

    &__contributors {
      hr {
        margin: ${theme.sizes.SIZE_32} 0;
      }
    }
  }
`;

export { StyledSessionReportsReport };
