import { useUtilities } from '@faxi/web-component-library';
import { RunSessionData, SessionData, SessionsDataContextType } from 'models';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import useMutation from '../../api/hooks/mutations/useMutation';
import useMutationWithDynamicPath from '../../api/hooks/mutations/useMutationWithDynamicPath';
import { useGetSessions } from '../../api/hooks/sessions';
import { API_ROUTES } from '../../api/routes';
import { Loading } from '../../components';
import { APP_URI } from '../../config';
import { useDebouncedValue } from '../../hooks';
import SessionsDataContext from './SessionsData.context';

type SessionsDataProps = {
  children?: ReactNode;
};

const SessionsDataProvider: FC<SessionsDataProps> = (props) => {
  const { children } = props;

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebouncedValue(search, 500);

  const { sessions, isLoading, mutate } = useGetSessions({
    search: debouncedSearch,
  });

  const { request, isMutating: isAddSessionMutating } =
    useMutation<SessionData>(API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSION_ROOT);

  const { showOverlay, hideOverlay } = useUtilities();

  const { request: runSessionApi, isMutating: isRunMutating } =
    useMutationWithDynamicPath<SessionData>({
      onSuccess: (data) => {
        showSnackBar({
          text: `${data.name} has been started.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        navigate(APP_URI.SESSIONS);

        if (sessions) {
          mutate({
            sessions: sessions.map((curr) =>
              data.id === curr.id ? data : curr
            ),
          });
        }
      },
    });

  const { showSnackBar } = useUtilities();
  const navigate = useNavigate();

  const addSession = useCallback(
    async (newSession: Pick<SessionData, 'name' | 'description'>) => {
      const isSessionExists = sessions?.some(
        ({ name }) => name === newSession.name
      );

      if (isSessionExists) {
        showSnackBar({
          text: `Session with name '${newSession.name}' already exists.`,
          variant: 'error',
          actionButtonText: 'Dismiss',
        });

        return;
      }

      const createdSession = await request('POST', { data: newSession });

      mutate({
        sessions: sessions ? [...sessions, createdSession] : [createdSession],
      });

      showSnackBar({
        text: `${newSession.name} has been created.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [mutate, request, sessions, showSnackBar]
  );

  const removeSession = useCallback((sessionName: string) => {}, []);

  const runSession = useCallback(
    (sessionId: string, data: RunSessionData) => {
      runSessionApi(
        API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSION_RUN(sessionId),
        'POST',
        { data }
      );
    },
    [runSessionApi]
  );

  useEffect(() => {
    isRunMutating
      ? showOverlay('body', 'center', <Loading />)
      : hideOverlay('body');
  }, [hideOverlay, isRunMutating, showOverlay]);

  return (
    <SessionsDataContext.Provider
      value={{
        sessions: sessions || [],
        search,
        setSearch,
        addSession,
        removeSession,
        runSession,
        isLoading,
        isRunMutating,
        isAddSessionMutating,
      }}
    >
      {children}
    </SessionsDataContext.Provider>
  );
};

const useSessionsData = (): SessionsDataContextType =>
  useContext(SessionsDataContext);

export { SessionsDataProvider, useSessionsData };
