import { FC, PropsWithChildren } from 'react';

import Icon, { INameExtended } from '../../Icon';
import { StyledEmptyFolder } from './EmptyFolder.styled';

export type EmptyFolderProps = PropsWithChildren<{
  title?: string;
  iconName?: INameExtended;
}>;

const EmptyFolder: FC<EmptyFolderProps> = ({
  title = 'This folder is empty',
  iconName = 'folder-open',
}) => {
  return (
    <StyledEmptyFolder className="esg-empty-folder">
      <Icon className="esg-empty-folder__icon" name={iconName} />
      <p className="esg-empty-folder__title">{title}</p>
    </StyledEmptyFolder>
  );
};

export default EmptyFolder;
