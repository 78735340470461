import { useGetSessionsProgress } from 'api';
import { SessionData } from 'models';
import React, { useContext, useMemo } from 'react';
import { useSessionsData } from 'store/SessionsData';

import {
  convertProgressApiToProgress,
  mockProgressAndApproval,
} from '../../utils';
import SelectedSessionContext from './SelectedSession.context';

type SelectedSessionProviderProps = {
  sessionId: string;
  children: React.ReactNode;
};

const SelectedSessionProvider: React.FC<SelectedSessionProviderProps> = ({
  sessionId,
  children,
}) => {
  const { sessions } = useSessionsData();
  const { data: progressArray } = useGetSessionsProgress();

  const selectedSession = useMemo(() => {
    const selectedSessionProgress = progressArray?.find(
      (progress) => progress?.id === sessionId
    );

    const session = sessions.find((session) => session.id === sessionId);
    const convertedProgress = convertProgressApiToProgress(
      selectedSessionProgress
    );
    return {
      ...session,
      progress: convertedProgress,
      approval: mockProgressAndApproval(),
    };
  }, [progressArray, sessions, sessionId]);

  if (!selectedSession) return <></>;

  return (
    <SelectedSessionContext.Provider value={selectedSession as SessionData}>
      {children}
    </SelectedSessionContext.Provider>
  );
};
const useSelectedSession = () => useContext(SelectedSessionContext);

export { SelectedSessionProvider, useSelectedSession };
