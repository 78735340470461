import {
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable';
import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';

export const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export const extractFromDndModule = (
  what: 'id' | 'dnd-type',
  moduleIdentifier: UniqueIdentifier
) => `${moduleIdentifier}`.split('_')[what === 'dnd-type' ? 0 : 1];

export const getModuleIdsAndTypes = (
  ev: DragMoveEvent | DragOverEvent | DragEndEvent
) => {
  const activeModuleId = extractFromDndModule('id', ev.active.id);
  const overModuleId = ev.over
    ? extractFromDndModule('id', ev.over.id)
    : undefined;

  const activeModuleType = extractFromDndModule('dnd-type', ev.active.id);
  const overModuleType = ev.over
    ? extractFromDndModule('dnd-type', ev.over.id)
    : undefined;

  return { activeModuleId, overModuleId, activeModuleType, overModuleType };
};

export const findModule = (
  moduleId: string,
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
): {
  parent: ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined;
  module: ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined;
  moduleIndex: number;
} => {
  let parent: ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined;
  let module: ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined;
  let moduleIndex = -1;

  const findModuleRec = (
    modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[],
    parentModule?: ModuleConfig<ModuleConfigType, DataModuleEnum>
  ) => {
    for (const m of modules) {
      if (m.id === moduleId) {
        module = m;
        moduleIndex = modules.indexOf(m);
        parent = parentModule;
        return;
      }

      if (m.elements) {
        findModuleRec(m.elements, m);
      }
    }
  };

  findModuleRec(modules);

  return { parent, module, moduleIndex };
};

export const hasCollisionWithChildren = (
  overModuleId: string,
  elementsArr: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
) => findModule(overModuleId, elementsArr).module;

export const addNewModuleToDragArea = (
  activeModuleId: string,
  overModuleIndex: number,
  arrRef: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
): ModuleConfig<ModuleConfigType, DataModuleEnum>[] => {
  arrRef.splice(
    // if overModuleIndex is -1, then the drag area was previously empty
    Math.max(overModuleIndex, 0),
    0,
    {
      id: activeModuleId,
      index: arrRef.length + 1,
      title: '',
      type: activeModuleId as DataModuleEnum,
      isNew: true,
    }
  );

  return arrRef;
};

export const moveModuleOutOfSection = (
  activeModuleIndex: number,
  section: ModuleConfig<ModuleConfigType, DataModuleEnum>
) => {
  section.elements?.splice(activeModuleIndex, 1);
};

export const moveModuleIntoSection = (
  activeModule: ModuleConfig<ModuleConfigType, DataModuleEnum>,
  overModule: ModuleConfig<ModuleConfigType, DataModuleEnum>[],
  overModuleIndex: number,
  moveOutOfSectionDirection: 'up' | 'down'
) => {
  overModule.splice(
    moveOutOfSectionDirection === 'down'
      ? overModuleIndex + 1
      : overModuleIndex,
    0,
    activeModule
  );
};
