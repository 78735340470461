import env from 'env';

const apiVersion = env.VITE_API_VERSION;

export const CAMPAIGNS = {
  CAMPAIGN_ITEMS: '/campaign-items/v2',
  CAMPAIGN_ITEM: (id: string) => `/campaign-items/v2/${id}`,
  CAMPAIGN_TREE: (id: string) => `/campaign-items/v2/${id}/tree`,
  CAMPAIGN_SESSION_ROOT: `/${apiVersion}/campaign-sessions/root`,
  CAMPAIGN_SESSIONS_PROGRESS: `/${apiVersion}/campaign-sessions/progress`,
  CAMPAIGN_SESSION_TREE: (id: string) =>
    `/${apiVersion}/campaign-sessions/${id}/tree`,
  CAMPAIGN_SESSION_FORM: (sessionId: string, formId: string) =>
    `/${apiVersion}/campaign-sessions/${sessionId}/forms/${formId}`,
  CAMPAIGN_SESSION_PROGRESS: (id: string) =>
    `/${apiVersion}/campaign-sessions/${id}/progress`,
  CAMPAIGN_SESSION_RUN: (id: string) =>
    `/${apiVersion}/campaign-sessions/${id}/run`,
  CAMPAIGN_SESSION_SUBMIT_FORM: (companyId: string) =>
    `/${apiVersion}/campaign-sessions/${companyId}/submit-form`,
};
