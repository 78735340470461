import { SessionLayout } from 'layouts';
import styled from 'styled-components';

const StyledTasksLayout = styled(SessionLayout)`
  .esg-tasks-layout {
    &__assigned {
    }
  }
`;

export { StyledTasksLayout };
