import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import { css } from 'styled-components';

export const menuStyles = css`
  .wcl-portal {
    &.esg-three-dot-menu-content,
    &.wcl-menu {
      .wcl-dropdown {
        &__content {
          border: unset;
          overflow: hidden;
          padding: 6px;

          .wcl-menu__content {
            ${flex('column')};

            padding: unset;
            gap: ${theme.sizes.SIZE_4};

            .button {
              ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)}

              min-height: unset;
              border-radius: 6px;
              color: var(--BLACK-00);
              padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8};

              &:focus {
                background-color: VAR(--GRAY-EF);
              }

              svg {
                font-size: ${theme.fontSizes.FONT_18};
              }
            }
          }
        }
      }
    }
  }
`;

export default menuStyles;
