import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';
import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';

const removeElementRecursively =
  (parentId: string, name: string) =>
  (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) => {
    if (module.id === parentId) {
      const { conditionalElements, ...restModule } = module;

      const clonedConditionalElements = conditionalElements
        ? cloneDeep(conditionalElements)
        : {};

      delete clonedConditionalElements[name];

      const updatedModule = isEmpty(clonedConditionalElements)
        ? restModule
        : { ...restModule, conditionalElements: clonedConditionalElements };

      return updatedModule;
    } else if (module.elements) {
      module.elements = module.elements.map(
        removeElementRecursively(parentId, name)
      );
    }

    return module;
  };

/**
 * Function to remove a specific key from the conditionalElements
 * of a module with a matching parentId, recursively handling nested modules.
 */
export const removeModuleElement =
  (parentId: string, name: string) =>
  (
    modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  ): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
    modules.map(removeElementRecursively(parentId, name));
