import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledSessionSidebarLink = styled(Link)`
  ${flex('row', 'space-between', 'center')};
  gap: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_8};
  border-radius: ${theme.sizes.SIZE_8};
  color: var(--BLACK-00);
  text-decoration: none;

  &.esg-session-sidebar-link {
    &--active {
      background-color: var(--GRAY-EF);
    }

    &:hover {
      background-color: var(--GRAY-EF);
    }
  }

  .esg-session-sidebar-link {
    &__main {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_8};
    }
  }
`;

export { StyledSessionSidebarLink };
