import { useUtilities } from '@faxi/web-component-library';
import { AxiosError } from 'axios';
import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { snackBarErrorMessage } from 'utils/snack';

export type CallbackAsyncArgs<T> = {
  spinnerParent?: string;
  showSpinner?: boolean;
  deps?: DependencyList;
  initialLoading?: boolean;
  onFinish?: () => void;
  onError?: (error: AxiosError) => void;
  callback: (...args: any[]) => Promise<T>;
  condition?: boolean | ((...args: any[]) => boolean | Promise<boolean>);
};

export default function useCallbackAsync<T = void>(args: CallbackAsyncArgs<T>) {
  const {
    onError,
    onFinish,
    callback,
    spinnerParent = 'body',
    initialLoading = false,
    showSpinner = true,
    deps = [],
    condition = true,
  } = args;

  const { showOverlay, hideOverlay } = useUtilities();

  const componentIsMounted = useRef<boolean>(true);

  const [loading, setLoading] = useState<boolean>(initialLoading);
  const callbackRef = useRef<(...args: any[]) => Promise<T>>(callback);

  const conditionRef = useRef(condition);
  const finishCallbackRef = useRef(onFinish);

  const asyncCallback = useCallback(
    async (...args: any[]) => {
      try {
        if (
          typeof conditionRef.current === 'function'
            ? !(await conditionRef.current(...args))
            : !conditionRef.current
        ) {
          return;
        }

        setLoading(true);
        showSpinner && showOverlay(spinnerParent);

        return await callbackRef.current(...args);
      } catch (e) {
        console.error(e);
        onError?.(e as AxiosError);
        if ((e as AxiosError)?.code === 'ERR_CANCELED') return;
        snackBarErrorMessage(e as AxiosError);
      } finally {
        showSpinner && hideOverlay(spinnerParent);
        if (componentIsMounted.current) setLoading(false);
        if (finishCallbackRef.current) finishCallbackRef.current?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [spinnerParent, ...deps]
  );

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    callbackRef.current = callback;
    finishCallbackRef.current = onFinish;
    conditionRef.current = condition;
  });

  return [asyncCallback, loading] as [(...args: any[]) => Promise<T>, boolean];
}
