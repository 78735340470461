import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledEmptyFolder = styled.div`
  ${flex('column', 'center', 'center')};
  ${size('100%')};

  flex: 1;

  .esg-empty-folder {
    &__title {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      margin: 0;
      color: var(--BLACK-00);
    }

    &__icon {
      ${size(theme.sizes.SIZE_40)};

      color: var(--PRIMARY_1_1);
      margin: 0 0 ${theme.sizes.SIZE_12};

      svg {
        ${size('100%')}
      }
    }
  }
`;

export { StyledEmptyFolder };
