import env from 'env';

const apiVersion = env.VITE_API_VERSION;

export const COMPANIES = {
  ORGANISATIONS: '/organisations/v2',
  COMPANY_CAMPAIGNS: '/organisation-campaigns/v2',
  ORGANISATION: (id: string) => `/organisations/v2/${id}`,
  ORGANISATION_CAMPAIGN_SESSIONS: (id: string, search: string) =>
    `/${apiVersion}/organisations/${id}/campaign-sessions?searchString=${search}`,
  COMPANY_CAMPAIGN: (id: string) => `/organisation-campaigns/v2/${id}`,
  ORGANISATION_TREE: (id: string) => `/organisations/v2/${id}/tree`,
};
