import { applyRef, ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form, FormField } from '@faxi/web-form';
import { FormFooter, InputField, SelectField } from 'components';
import { useValidations } from 'hooks';
import useRoleOptions from 'hooks/useRoleOptions';
import { UserForm } from 'models';
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
} from 'react';
import UserProfileImage from 'routes/subrouters/Users/components/UserProfileImage';

import { StyledManageUserModal } from './ManageUserModal.styled';

export type ManageUserModalProps = PropsWithChildren<
  ModalProps & {
    initialData?: UserForm;
    onSubmit: (data: UserForm) => void;
  }
>;

const ManageUserModal: ForwardRefRenderFunction<
  ModalRef,
  ManageUserModalProps
> = (props, ref) => {
  const { onSubmit: pOnSubmit, initialData, ...rest } = props;

  const isEdit = !!initialData;

  const modalRef = useRef<ModalRef>();

  const { roleOptions, isLoading } = useRoleOptions();

  const { validations } = useValidations();

  const onSubmit = useCallback(
    async (data: UserForm) => {
      await pOnSubmit(data);
    },
    [pOnSubmit]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialData}
        onSubmit={onSubmit}
      />
    ),
    [initialData, onSubmit]
  );

  return (
    <StyledManageUserModal
      className="esg-manage-user-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      childrenWrapper={formWrapper}
      title={isEdit ? 'Edit user' : 'Create new user'}
      footer={
        <FormFooter
          modal={modalRef as RefObject<ModalRef>}
          submitLabel={isEdit ? 'Save' : 'Create'}
        />
      }
      {...rest}
    >
      <p>{!isEdit ? 'New User' : 'Edit User'}</p>
      <UserProfileImage />
      <FormField
        key="firstName"
        name="firstName"
        component={InputField}
        autoComplete="off"
        placeholder="First Name"
        validate={validations.name}
      />
      <FormField
        key="lastName"
        name="lastName"
        component={InputField}
        autoComplete="off"
        placeholder="Last Name"
        validate={validations.name}
      />
      <FormField
        key="email"
        name="email"
        component={InputField}
        autoComplete="off"
        placeholder="Email"
        validate={validations.email}
      />
      <FormField
        key="jobTitle"
        name="jobTitle"
        component={InputField}
        autoComplete="off"
        placeholder="Job Title"
        validate={validations.name}
      />
      <FormField
        key="roleId"
        name="roleId"
        component={SelectField}
        loading={isLoading}
        autoComplete="off"
        placeholder="Role"
        options={roleOptions}
        validate={validations.role}
        portalClassName="role-select-portal"
      />
    </StyledManageUserModal>
  );
};

export default forwardRef(ManageUserModal);
