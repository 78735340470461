import { Checkbox, CheckboxProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import { FC, Fragment, ReactNode, useCallback } from 'react';

import { StyledCheckboxListField } from './CheckboxListField.styled';
import { CheckboxConditionalEl } from './components';

export type CheckboxListOption = Pick<CheckboxProps, 'label' | 'value'> & {
  customLabel?: ReactNode;
  toggleContent?: ReactNode;
  onChangeOptionCallback?: (value: string[]) => string[];
};

export type CheckboxListFieldProps = FieldProps<string[]> & {
  options: Array<
    Pick<CheckboxProps, 'label' | 'value' | 'className'> & {
      customLabel?: ReactNode;
      conditionalEl?: ReactNode;
    }
  >;
} & Omit<CheckboxProps, 'ref'>;

const toggleArrayValue = <T,>(array: T[], value: T): T[] =>
  array.includes(value) ? array.filter((v) => v !== value) : [...array, value];

const CheckboxListField: FC<CheckboxListFieldProps> = (props): JSX.Element => {
  const {
    value = [],
    onChange,
    error,
    label,
    touched,
    dirty,
    options,
    className,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const handleCheckboxChange = useCallback(
    (option: CheckboxListOption) => {
      const newValue = toggleArrayValue<string>(value, option.value as string);
      const finalValue = option?.onChangeOptionCallback?.(newValue) ?? newValue;

      onChange?.(finalValue);
    },
    [onChange, value]
  );

  return (
    <StyledCheckboxListField className={className}>
      {options.map((option) => {
        const isChecked = value?.includes(option.value as string);

        return (
          <Fragment key={option.label}>
            <div className={option.className}>
              <Checkbox
                {...rest}
                label={option.customLabel ? undefined : option.label}
                aria-label={option.label}
                checked={isChecked}
                onChange={() => handleCheckboxChange(option)}
              />
              {option.customLabel}
            </div>

            {option.conditionalEl && (
              <CheckboxConditionalEl isChecked={isChecked}>
                {option.conditionalEl}
              </CheckboxConditionalEl>
            )}
          </Fragment>
        );
      })}
      {showError && <small>{error}</small>}
    </StyledCheckboxListField>
  );
};

export default CheckboxListField;
