import { AxiosRequestConfig, Method } from 'axios';

import api from './api';

export type RequestArgs = Omit<AxiosRequestConfig, 'method' | 'url'>;

export type MutationArgs = {
  method: Method;
  args: RequestArgs;
};

const defaultMutation = async <T>(
  url: string,
  { arg }: { arg: MutationArgs }
) => {
  const { method, args } = arg;

  const response = await api.request<T>({
    url,
    method,
    ...args,
  });

  return response.data;
};

export default defaultMutation;
