import { useCallbackRef } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { SwitchField } from 'components';
import { DataModuleEnum, SwitchDataModule } from 'models';
import { FC, Fragment, useState } from 'react';
import { generatePreviewFieldName } from 'utils';

import { useHeightAnimation } from '../../../../../../hooks';
import {
  StyledConditionalField,
  StyledSwitchPreview,
} from './SwitchPreview.styled';

export type SwitchPreviewProps = SwitchDataModule;

const SwitchPreview: FC<SwitchPreviewProps> = ({
  title,
  id,
  conditionalElements,
  config,
  moduleElement: ModuleElement,
}) => {
  const [checked, setChecked] = useState<boolean>(!!config?.defaultValue);

  const { on: moduleOn, off: moduleOff } = conditionalElements ?? {};
  const [switchOnField, switchOnFieldRef] = useCallbackRef<HTMLDivElement>();

  useHeightAnimation({
    element: switchOnField,
    isOpen: checked,
  });

  const [switchOffField, switchOffFieldRef] = useCallbackRef<HTMLDivElement>();

  useHeightAnimation({
    element: switchOffField,
    isOpen: !checked,
  });

  return (
    <Fragment>
      <StyledSwitchPreview className="esg-switch-preview">
        <p>{title || 'Switch'}</p>
        <FormField
          name={generatePreviewFieldName(id, DataModuleEnum.SWITCH)}
          component={SwitchField}
          onChange={(v) => setChecked(v as boolean)}
        />
      </StyledSwitchPreview>

      <div className="width-100">
        <StyledConditionalField ref={switchOnFieldRef} $open={checked}>
          {moduleOn && ModuleElement && (
            <ModuleElement
              modulesType="preview-module"
              type={moduleOn.type}
              module={moduleOn}
            />
          )}
        </StyledConditionalField>

        <StyledConditionalField ref={switchOffFieldRef} $open={!checked}>
          {moduleOff && ModuleElement && (
            <ModuleElement
              modulesType="preview-module"
              type={moduleOff.type}
              module={moduleOff}
            />
          )}
        </StyledConditionalField>
      </div>
    </Fragment>
  );
};

export default SwitchPreview;
