import { InputField } from 'components';
import { InputPreviewType } from 'models';
import { FC } from 'react';
import { ExtractComponentProps } from 'types';

export type NumericFieldProps = ExtractComponentProps<typeof InputField> &
  InputPreviewType;

const NumericField: FC<NumericFieldProps> = (props) => {
  const { config } = props;

  return (
    <InputField
      unit={config?.unit}
      placeholder={config?.placeholder}
      {...props}
    />
  );
};

export default NumericField;
