import { getTextFromReactNode } from '@faxi/web-component-library';
import { ReactNode } from 'react';

export const generateOptionsByLabels = (
  options?: { label: ReactNode }[]
): { label: ReactNode; value: string; [key: string]: any }[] =>
  (options || []).map((option) => ({
    ...option,
    value: getTextFromReactNode(option.label),
  }));
