import { FC, PropsWithChildren } from 'react';

import ProfileAssignment from '../../../../../../components/ProfileAssignment';
import { USER_ASSIGNMENT_NODE_DATA } from './mock';

export type UserAssignmentProps = PropsWithChildren<{}>;

const UserAssignment: FC<UserAssignmentProps> = () => {
  return <ProfileAssignment data={USER_ASSIGNMENT_NODE_DATA} />;
};

export default UserAssignment;
