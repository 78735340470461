import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import { css } from 'styled-components';

export const progressBarStyles = css`
  .kinto-progress-bar[class] {
    .kinto-progress-bar__container {
      &__percentage {
        color: var(--BLACK-00);
        ${fontSize(theme.fontSizes.FONT_14)};
        font-weight: 400;

        flex: 0 0 ${theme.sizes.SIZE_56};
      }

      &__bar--rich {
        margin-right: 0;
      }

      &__bar {
        border-radius: ${theme.sizes.SIZE_8};

        &:after {
          border-radius: ${theme.sizes.SIZE_8};
        }
      }
    }
  }
`;

export default progressBarStyles;
