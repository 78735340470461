import {
  GlobalStyle as WCLGlobalStyle,
  UtilitiesProvider,
} from '@faxi/web-component-library';
import swrConfig from 'api/swrConfig';
import { Router } from 'routes';
import { SWRConfig } from 'swr';

import { GlobalStyle } from './globalStyles';

const App = () => {
  return (
    <>
      <WCLGlobalStyle />
      <GlobalStyle />
      <UtilitiesProvider>
        <SWRConfig value={swrConfig}>
          <Router />
        </SWRConfig>
      </UtilitiesProvider>
    </>
  );
};

export default App;
