import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBadgeElement = styled.div`
  height: fit-content;

  &.esg-badge-element {
    ${flex('row')};

    gap: ${theme.sizes.SIZE_10};
    padding: 6px ${theme.sizes.SIZE_10};
    border-radius: ${theme.sizes.SIZE_8};
    border: ${theme.sizes.SIZE_1} solid var(--GRAY-C1);

    span {
      ${fontSize(theme.fontSizes.FONT_14)};
      font-weight: 400;
    }

    &--data-collection {
      color: #0066d5;
      background: #e7f3ff;
    }

    &--report {
      color: #a35501;
      background: #e7f3ff;
    }
  }
`;

export { StyledBadgeElement };
