import { css } from 'styled-components';

const breadcrumbsStyles = css`
  .wcl-breadcrumbs[class] {
    color: var(--SHADE_1_1);

    .wcl-breadcrumbs {
      &__link {
        font-weight: 400;
        text-decoration: none;

        &--active,
        &:hover {
          text-shadow: 0 0 0.5px var(--SHADE_1_1);
          text-decoration: none;
        }
      }
    }
  }
`;

export default breadcrumbsStyles;
