import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserRolesNotFound = styled.div`
  ${flex('column', 'center', 'center')};

  gap: ${theme.sizes.SIZE_32};
  margin: auto;
`;

export { StyledUserRolesNotFound };
