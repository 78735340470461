import { useCallbackRef } from '@faxi/web-component-library';
import { useCallback, useRef } from 'react';

import useHeightAnimation from './useHeightAnimation';

/**
 * Use this hook for animating the height of an array of elements (e.g., dropdown, choice options)
 * when the selected option changes.
 */
const useHeightAnimationArrayElements = () => {
  const [elementPreviewWrapper, elementPreviewWrapperRef] =
    useCallbackRef<HTMLDivElement>();

  const lastElementPreviewWrapper = useRef<number>(
    elementPreviewWrapper?.clientHeight || 1
  );

  useHeightAnimation({
    element: elementPreviewWrapper,
    isOpen: true,
    animateInitially: true,
    shouldUseFitContent: false,
    startHeight: lastElementPreviewWrapper.current,
  });

  const onElementChange = useCallback(() => {
    lastElementPreviewWrapper.current = elementPreviewWrapper?.clientHeight;
  }, [elementPreviewWrapper?.clientHeight]);

  return { onElementChange, elementPreviewWrapperRef };
};

export default useHeightAnimationArrayElements;
