import {
  extendPropToFaNameMap,
  Icon,
  IconProps,
  IName,
  propToFaNameMap,
} from '@faxi/web-component-library';
import { FC } from 'react';

export type INameExtended =
  | (
      | 'arrow-down-to-arc'
      | 'ballot-check'
      | 'bars'
      | 'bars-progress-solid'
      | 'bars-progress'
      | 'building'
      | 'buildings'
      | 'buildings-solid'
      | 'calendar-days'
      | 'caret-down'
      | 'chart-bar-solid'
      | 'chart-bar'
      | 'chart-simple'
      | 'clipboard'
      | 'clipboard-list'
      | 'cog-solid'
      | 'cog'
      | 'copy'
      | 'crown'
      | 'download'
      | 'ellipsis-vertical'
      | 'envelope'
      | 'eye-solid'
      | 'eye'
      | 'eye-slash'
      | 'file-check'
      | 'file-pdf'
      | 'file-regular'
      | 'file'
      | 'folder-open'
      | 'folder'
      | 'function'
      | 'gauge-solid'
      | 'gauge'
      | 'gears'
      | 'grid'
      | 'grip-dots-vertical'
      | 'grip-solid'
      | 'grip'
      | 'heading'
      | 'input-pipe'
      | 'leaf-solid'
      | 'leaf'
      | 'list'
      | 'magnifying-glass-chart'
      | 'option'
      | 'rectangle'
      | 'shield-solid'
      | 'shield'
      | 'square-check'
      | 'text'
      | 'thumbs-up-solid'
      | 'toggle-on'
      | 'upload'
      | 'user'
      | 'user-circle'
      | 'user-solid'
      | 'users-light'
      | 'users-solid'
      | 'users'
      | 'user-gear'
      | 'wave-pulse-regular'
      | 'wave-pulse-solid'
      | 'wave-pulse'
      | 'wrench'
    )
  | IName;

export const customMapNameToFa: Record<INameExtended, string[]> = {
  'arrow-down-to-arc': ['fa-light', 'fa-arrow-down-to-arc'],
  'ballot-check': ['fa-regular', 'fa-ballot-check'],
  'bars': ['fa-solid', 'fa-bars'],
  'bars-progress-solid': ['fa-solid', 'fa-bars-progress'],
  'bars-progress': ['fa-light', 'fa-bars-progress'],
  'building': ['fa-light', 'fa-building'],
  'buildings': ['fa-light', 'fa-buildings'],
  'buildings-solid': ['fa-solid', 'fa-buildings'],
  'calendar-days': ['fa-light', 'fa-calendar-days'],
  'caret-down': ['fa-light', 'fa-square-caret-down'],
  'chart-bar-solid': ['fa-solid', 'fa-chart-bar'],
  'chart-bar': ['fa-light', 'fa-chart-bar'],
  'chart-simple': ['fa-solid', 'fa-chart-simple'],
  'clipboard': ['fa-light', 'fa-clipboard'],
  'clipboard-list': ['fa-light', 'fa-clipboard-list'],
  'cog-solid': ['fa-solid', 'fa-cog'],
  'cog': ['fa-light', 'fa-cog'],
  'copy': ['fa-light', 'fa-copy'],
  'crown': ['fa-solid', 'fa-crown'],
  'download': ['fa-regular', 'fa-download'],
  'ellipsis-vertical': ['fa-light', 'fa-ellipsis-vertical'],
  'envelope': ['fa-light', 'fa-envelope'],
  'eye-solid': ['fa-solid', 'fa-eye'],
  'eye': ['fa-light', 'fa-eye'],
  'eye-slash': ['fa-light', 'fa-eye-slash'],
  'file-check': ['fa-light', 'fa-file-check'],
  'file-pdf': ['fa-light', 'fa-file-pdf'],
  'file-regular': ['fa-regular', 'fa-file'],
  'file': ['fa-light', 'fa-file'],
  'folder-open': ['fa-light', 'fa-folder-open'],
  'folder': ['fa-light', 'fa-folder'],
  'function': ['fa-light', 'fa-function'],
  'gauge-solid': ['fa-solid', 'fa-gauge'],
  'gauge': ['fa-light', 'fa-gauge'],
  'gears': ['fa-regular', 'fa-gears'],
  'grid': ['fa-regular', 'fa-grid'],
  'grip-dots-vertical': ['fa-solid', 'fa-grip-dots-vertical'],
  'grip-solid': ['fa-solid', 'fa-grip'],
  'grip': ['fa-light', 'fa-grip'],
  'heading': ['fa-light', 'fa-heading'],
  'input-pipe': ['fa-solid', 'fa-input-pipe'],
  'leaf-solid': ['fa-solid', 'fa-leaf'],
  'leaf': ['fa-light', 'fa-leaf'],
  'list': ['fa-solid', 'fa-list'],
  'magnifying-glass-chart': ['fa-solid', 'fa-magnifying-glass-chart'],
  'option': ['fa-solid', 'fa-option'],
  'rectangle': ['fa-light', 'fa-rectangle'],
  'shield-solid': ['fa-solid', 'fa-shield'],
  'shield': ['fa-light', 'fa-shield'],
  'square-check': ['fa-light', 'fa-square-check'],
  'text': ['fa-solid', 'fa-text'],
  'thumbs-up-solid': ['fa-solid', 'fa-thumbs-up'],
  'toggle-on': ['fa-regular', 'fa-toggle-on'],
  'upload': ['fa-solid', 'fa-upload'],
  'user': ['fa-light', 'fa-user'],
  'user-circle': ['fa-light', 'fa-circle-user'],
  'user-gear': ['fa-solid', 'fa-user-gear'],
  'user-solid': ['fa-solid', 'fa-user'],
  'users-light': ['fa-light', 'fa-users'],
  'users-solid': ['fa-solid', 'fa-users'],
  'users': ['fa-light', 'fa-users'],
  'wave-pulse-regular': ['fa-regular', 'fa-wave-pulse'],
  'wave-pulse-solid': ['fa-solid', 'fa-wave-pulse'],
  'wave-pulse': ['fa-light', 'fa-wave-pulse'],
  'wrench': ['fa-light', 'fa-wrench'],
  ...propToFaNameMap,
};

extendPropToFaNameMap(customMapNameToFa);

export default Icon as FC<IconProps<INameExtended>>;
