import { Divider } from '@faxi/web-component-library';
import { DividerDataModule } from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type DividerCanvasModuleProps = DividerDataModule;

const DividerCanvasModule: FC<DividerCanvasModuleProps> = () => {
  return (
    <StyledModuleContainer className="esg-divider-canvas-module">
      <Divider />
    </StyledModuleContainer>
  );
};

export default memo(DividerCanvasModule);
