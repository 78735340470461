import { RadioGroup, RadioGroupProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';
import { FC } from 'react';

export type RadioGroupFieldProps = FieldProps<string> & RadioGroupProps;

const RadioGroupField: FC<RadioGroupFieldProps> = (
  props: RadioGroupFieldProps
): JSX.Element => {
  const { value, error, dirty, touched, ...rest } = props;

  return <RadioGroup value={value || undefined} {...rest} />;
};

export default RadioGroupField;
