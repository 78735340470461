export const ORGANISATION_PATH_CHECKPOINTS = ['organisations', 'companies'];

export const getPathSegment = (path: string, from = 0, to = 2) => {
  const segments = path.split('/').filter(Boolean);
  return `/${segments.slice(from, to).join('/')}`;
};

export const buildOrganisationTreePath = (
  depth: number,
  id: string,
  path = ''
): string => {
  let newPath = '';

  if (depth < 2)
    newPath = `${path}/${ORGANISATION_PATH_CHECKPOINTS[depth]}/${id}`;
  else {
    const basePath = getPathSegment(path);
    newPath = `${basePath}/${ORGANISATION_PATH_CHECKPOINTS[1]}/${id}`;
  }

  return newPath;
};

export const generateOrganisationItemCrumbs = (
  names = [] as string[],
  ids = [] as string[],
  id: string,
  name: string
) => {
  const namesTemp = [...names, name];
  const idsTemp = [...ids, id];

  const rootCrumb = {
    text: 'Organisations',
    href: `/${ORGANISATION_PATH_CHECKPOINTS[0]}`,
    id: 'campaigns-link',
  };

  let path = '';

  return [
    rootCrumb,
    ...namesTemp.map((name, index) => {
      path = buildOrganisationTreePath(index, idsTemp[index], path);

      return {
        text: name,
        href: path,
        id: idsTemp[index],
      };
    }),
  ];
};
