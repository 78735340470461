import {
  CampaignSessionTreeNode,
  FORM_APPROVAL,
  FORM_STATUS,
  SessionProgress,
} from 'models';

export const findCurrentSessionEntry = (
  root: CampaignSessionTreeNode,
  currentId: string
): CampaignSessionTreeNode | undefined => {
  if (currentId === root?.id) {
    return root;
  }
  if (!root?.children || root?.children?.length === 0) {
    return undefined;
  }

  for (const child of root.children) {
    const result = findCurrentSessionEntry(child as any, currentId);
    if (result) {
      return result;
    }
  }
};

export const findAllAncestorNames = (
  root: CampaignSessionTreeNode,
  currentId: string
): string[] | undefined => {
  if (currentId === root?.id) {
    return [root.name];
  }

  if (!root?.children || root?.children?.length === 0) {
    return undefined;
  }

  for (const child of root.children) {
    const result = findAllAncestorNames(child as any, currentId);
    if (result !== undefined) {
      return [root.name, ...result];
    }
  }
};

export const convertProgressApiToProgress = (
  progress?: Pick<
    SessionProgress,
    'progress' | 'completedElements' | 'totalElements'
  >
) => {
  return {
    currentStep: progress ? progress.completedElements : 0,
    numberOfSteps: progress ? progress.totalElements : 1,
    status:
      progress?.progress === 100
        ? FORM_STATUS.COMPLETED
        : FORM_STATUS.IN_PROGRESS,
  };
};

const statusArray = [
  ...Object.values(FORM_STATUS),
  ...Object.values(FORM_APPROVAL),
  ...new Array(2).fill(FORM_STATUS.IN_PROGRESS),
];

export const mockProgressAndApproval = (isProgress?: boolean) => {
  const statusMock = parseInt((Math.random() * 10).toFixed()) % 7;
  const currentStep = parseFloat((Math.random() * 100).toFixed(2));
  const numberOfSteps = parseFloat(
    (Math.random() * (100 + currentStep) + currentStep + 1).toFixed(2)
  );

  return {
    currentStep: currentStep,
    numberOfSteps: numberOfSteps,
    status: statusArray[isProgress && statusMock !== 0 ? 1 : statusMock],
  };
};
