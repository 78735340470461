import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseCheckListFieldConfiguration = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_40};

  .esg-select-field {
    width: fit-content;

    .wcl-select__trigger {
      width: unset;
    }
  }

  .esg-check-list-field-configuration {
    &__default {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_20};
      margin-bottom: ${theme.sizes.SIZE_40};

      > button {
        text-decoration: underline;
      }
    }
    &__fields {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_32};

      &__field {
        &__checkbox {
          ${size('24px')};

          background-color: var(--GRAY-EF);
          border: 1px solid var(--GRAY-54);
        }

        > button {
          margin-left: auto;
          text-decoration: underline;
        }
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_16};

        .button--ghost {
          padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8};
          white-space: nowrap;
        }

        .esg-inline-editable {
          width: fit-content;
        }
      }
    }
  }
`;

export { StyledBaseCheckListFieldConfiguration };
