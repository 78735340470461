import { DataModuleEnum } from 'models';

import ChecklistCanvasModule from '../components/_organisms/BuilderCanvas/components/ChecklistCanvasModule';
import ChoiceCanvasModule from '../components/_organisms/BuilderCanvas/components/ChoiceCanvasModule';
import DividerCanvasModule from '../components/_organisms/BuilderCanvas/components/DividerCanvasModule';
import DropdownCanvasModule from '../components/_organisms/BuilderCanvas/components/DropdownCanvasModule';
import FormulaCanvasModule from '../components/_organisms/BuilderCanvas/components/FormulaCanvasModule';
import InputCanvasModule from '../components/_organisms/BuilderCanvas/components/InputCanvasModule';
import IntegrationCanvasModule from '../components/_organisms/BuilderCanvas/components/IntegrationCanvasModule';
import SectionCanvasModule from '../components/_organisms/BuilderCanvas/components/SectionCanvasModule';
import SwitchCanvasModule from '../components/_organisms/BuilderCanvas/components/SwitchCanvasModule';
import TextCanvasModule from '../components/_organisms/BuilderCanvas/components/TextCanvasModule';
import UploadCanvasModule from '../components/_organisms/BuilderCanvas/components/UploadCanvasModule';

export default {
  [DataModuleEnum.CHECKLIST]: ChecklistCanvasModule,
  [DataModuleEnum.CHOICE]: ChoiceCanvasModule,
  [DataModuleEnum.FORMULA]: FormulaCanvasModule,
  [DataModuleEnum.DIVIDER]: DividerCanvasModule,
  [DataModuleEnum.DROPDOWN]: DropdownCanvasModule,
  [DataModuleEnum.INPUT]: InputCanvasModule,
  [DataModuleEnum.INTEGRATION]: IntegrationCanvasModule,
  [DataModuleEnum.SECTION]: SectionCanvasModule,
  [DataModuleEnum.SWITCH]: SwitchCanvasModule,
  [DataModuleEnum.TEXT_BOX]: TextCanvasModule,
  [DataModuleEnum.UPLOAD]: UploadCanvasModule,
} as const;
