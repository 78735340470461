import { Divider, Heading } from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import { useGetSessionTree } from 'api/hooks/sessions';
import classNames from 'classnames';
import {
  AssignContributors,
  CollapsibleSidePanel,
  ErrorFallback,
  ReportStatus,
} from 'components';
import ModuleElement from 'components/_organisms/BuilderCanvas/components/ModuleElement';
import { BlockUI, Each } from 'helpers';
import { useFormModules } from 'hooks';
import { CampaignSessionTreeNode } from 'models';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { findNode } from 'utils';

import { MAP_ID } from '../../constants';
import { findCurrentSessionEntry } from '../../utils';
import SessionBreadCrumbs from '../SessionBreadCrumbs';
import { StyledSessionReportsReport } from './SessionReportsReport.styled';

export type SessionReportsReportProps = {};

const SessionReportsReport: FC<SessionReportsReportProps> = () => {
  const params = useParams();

  const { sessionTreeWithOrganisationRoot, isLoading, error } =
    useGetSessionTree(params?.sessionId as string);

  const currentEntry = useMemo(() => {
    return findCurrentSessionEntry(
      sessionTreeWithOrganisationRoot as CampaignSessionTreeNode,
      params?.[MAP_ID['subsubtopic']] as string
    );
  }, [params, sessionTreeWithOrganisationRoot]);

  const subSessionId = useMemo(
    () =>
      sessionTreeWithOrganisationRoot &&
      findNode(
        params[MAP_ID['company']] as string,
        Array(sessionTreeWithOrganisationRoot) as CampaignSessionTreeNode[]
      )?.companySessionId,
    [params, sessionTreeWithOrganisationRoot]
  );

  const {
    modules,
    progress,
    formData,
    formStatus,
    formApproval,
    loadingStatus,
    updateFormStatus,
  } = useFormModules({
    asAdmin: true,
    sessionId: subSessionId,
    collectionId: params[MAP_ID['subsubtopic']],
  });

  const loading = isLoading;

  return (
    <StyledSessionReportsReport
      className={classNames('esg-session-reports-report', {
        'esg-session-reports-report--error': error,
      })}
    >
      <BlockUI
        loading={loading}
        fallbackCondition={!!error}
        fallback={
          <ErrorFallback title="Something went wrong. We're unable to retrieve the data." />
        }
      >
        <div className="esg-session-reports-report__content">
          <div className="esg-session-reports-report__content__header">
            <SessionBreadCrumbs name="subsubtopic" />
            <Heading level="1">{currentEntry?.name}</Heading>
          </div>

          {currentEntry?.description && (
            <p className="esg-session-reports-report__content__subheader">
              {currentEntry?.description}
            </p>
          )}

          <Form
            className="esg-session-reports-report__content__elements"
            initialData={formData}
            onSubmit={async () => {}}
          >
            <Each
              of={modules}
              render={(module) => (
                <ModuleElement
                  modulesType="preview-module"
                  type={module.type}
                  module={module}
                  key={module.id}
                />
              )}
            />
          </Form>
        </div>

        <CollapsibleSidePanel className="esg-session-reports-report__contributors">
          <AssignContributors />
          <Divider />
          <ReportStatus
            asAdmin
            progress={progress}
            loading={loadingStatus}
            formStatus={formStatus}
            formApproval={formApproval}
            onStatusChange={updateFormStatus}
          />
        </CollapsibleSidePanel>
      </BlockUI>
    </StyledSessionReportsReport>
  );
};

export default SessionReportsReport;
