import { Method } from 'axios';
import { Campaign, TreeNodeElement } from 'models';
import { createContext, MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';

import { RequestArgs } from '../../api/defaultMutation';

export type CampaignProviderContextProps = {
  tree?: TreeNodeElement;
  deletedCampaign: MutableRefObject<boolean>;
  mutateTree: KeyedMutator<{ data: Campaign }>;
  campaignItemsMutationRequest: (
    method: Method,
    args: RequestArgs
  ) => Promise<any>;
};

export default createContext<CampaignProviderContextProps>({
  deletedCampaign: { current: false },
  mutateTree: () => new Promise(() => {}),
  campaignItemsMutationRequest: () => new Promise(() => {}),
});
