import { FC, useCallback, useState } from 'react';

import { StyledFlag } from './StatusFlag.styled';

interface StatusFlagProps {
  initialIsRead: boolean;
  onClick?: (isRead: boolean) => void;
}

const StatusFlag: FC<StatusFlagProps> = ({ initialIsRead, onClick }) => {
  const [isRead, setIsRead] = useState(initialIsRead);

  const handleClick = useCallback(() => {
    const newIsRead = !isRead;
    setIsRead(newIsRead);
    onClick?.(newIsRead);
  }, [isRead, onClick]);

  return <StyledFlag $isRead={isRead} onClick={handleClick} />;
};

export default StatusFlag;
