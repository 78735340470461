import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';

import { StyledEllipsisText } from './EllipsisText.styled';

export type EllipsisTextProps = PropsWithChildren<{
  text: string;
  onClick?: () => void;
}>;

const EllipsisText: ForwardRefRenderFunction<
  HTMLParagraphElement,
  EllipsisTextProps
> = (props, ref) => {
  const { text, onClick } = props;

  return (
    <StyledEllipsisText
      onClick={onClick}
      ref={ref}
      className="esg-ellipsis-text"
    >
      {text}
    </StyledEllipsisText>
  );
};

export default forwardRef(EllipsisText);
