import { Tooltip } from '@faxi/web-component-library';
import { EllipsisText } from 'components';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { StyledOverflowTooltipText } from './OverflowTooltipText.styled';

export type OverflowTooltipTextProps = PropsWithChildren<{
  text: string;
  onClick?: () => void;
}>;

const OverflowTooltipText: FC<OverflowTooltipTextProps> = (props) => {
  const { text, children, onClick } = props;

  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [text]);

  return (
    <StyledOverflowTooltipText className="esg-overflow-tooltip-text">
      {isOverflowing ? (
        <Tooltip content={text} arrow={true} placement="bottom">
          <EllipsisText onClick={onClick} ref={textRef} text={text} />
        </Tooltip>
      ) : (
        <EllipsisText onClick={onClick} ref={textRef} text={text} />
      )}
      {children}
    </StyledOverflowTooltipText>
  );
};

export default OverflowTooltipText;
