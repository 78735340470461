import { Menu, theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledThreeDotMenu = styled(Menu)`
  .wcl-dropdown {
    &__content {
      background-color: red;
    }

    &__trigger[class] {
      ${size(theme.sizes.SIZE_32)};

      color: black;
      min-height: unset;
      border-radius: ${theme.sizes.SIZE_8};
      transition: background 150ms ease-in-out;

      &:hover {
        color: var(--PRIMARY_1_2);
        background-color: var(--GRAY-D9);
      }
      i {
        transform: none;
      }
    }
  }

  .esg-three-dot-menu {
  }
`;

const MenuContentStyle = css`
  .esg-three-dot-menu {
    &__content {
      background-color: blue;
    }
  }
`;

export { MenuContentStyle, StyledThreeDotMenu };
