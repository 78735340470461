import { UniqueIdentifier } from '@dnd-kit/core';
import { DraggingModuleConfig } from 'models';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';

export type DndDataContextProps = {
  activeId?: UniqueIdentifier;
  setActiveId: Dispatch<SetStateAction<UniqueIdentifier | undefined>>;

  draggingModule?: DraggingModuleConfig;

  setDraggingModule: Dispatch<SetStateAction<DraggingModuleConfig | undefined>>;

  droppedInDataModules: MutableRefObject<boolean>;
};

export const DndDataContext = createContext<DndDataContextProps>({
  setActiveId: () => {},
  setDraggingModule: () => {},

  droppedInDataModules: { current: false },
});
