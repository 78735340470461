import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import EntityFormModal, {
  EntityFormModalProps,
} from 'components/_forms/EntityFormModal';
import { Organisation } from 'models';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { API_ROUTES } from '../../../../../api/routes';

type CompanyFormType = Pick<Organisation, 'name' | 'description'>;

function CompanyModal<T extends Record<string, any>>(
  props: Omit<EntityFormModalProps<T>, 'onSubmit'> & {
    mutator: KeyedMutator<T>;
  }
) {
  const { initialData: company, mutator, ...rest } = props;

  const { showSnackBar } = useUtilities();

  const { pathname } = useLocation();

  // entity can be either an organisation or a company (the parent entity)
  const entityId = useMemo(() => pathname.split('/').at(-1), [pathname]);

  const showSnackMessage = useCallback(
    (data: Organisation) => {
      showSnackBar({
        text: `${data.name} ${company ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [company, showSnackBar]
  );

  const { request: updateCompany, isMutating: updateMutating } =
    api.useMutation(API_ROUTES.COMPANIES.ORGANISATION(company?.id), {
      onSuccess: (response) => showSnackMessage(response.data),
    });

  const { request: createCompany, isMutating: createMutating } =
    api.useMutation<{ data: Organisation }>(
      API_ROUTES.COMPANIES.ORGANISATION(entityId as string),
      {
        onSuccess: (response) => showSnackMessage(response.data),
      }
    );

  const loading = useMemo(
    () => updateMutating || createMutating,
    [createMutating, updateMutating]
  );

  const handleSubmit = useCallback(
    async (data: CompanyFormType) => {
      const { name, description } = data;

      if (company) {
        await updateCompany('PATCH', {
          data: { description },
        });
      } else {
        await createCompany('POST', {
          data: { name, description },
        });
      }

      mutator();
    },
    [company, mutator, updateCompany, createCompany]
  );

  return (
    <EntityFormModal
      className="esg-company-modal"
      loading={loading}
      initialData={
        company
          ? {
              name: company.name,
              description: company.description,
            }
          : undefined
      }
      title={company ? `Edit ${company.name} company` : 'Add company'}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
}

export default CompanyModal;
