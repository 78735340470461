import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, position, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

interface StyledUserCardProps {
  $view: 'grid' | 'list';
}

const StyledUserCard = styled.div<StyledUserCardProps>`
  ${elevate_l};

  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  padding: ${theme.sizes.SIZE_32};
  border-radius: ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_8};
  max-width: ${theme.sizes.SIZE_320};

  &.esg-user-card {
    &--list {
      max-width: unset;
    }
  }

  img {
    border-radius: 50%;
    ${size(theme.sizes.SIZE_152)};
  }

  .esg-user-card {
    &__header {
      ${position(
        'absolute',
        `top ${theme.sizes.SIZE_16} right ${theme.sizes.SIZE_16}`
      )};
    }

    &__content {
      position: relative;
      ${(props) =>
        props.$view === 'list'
          ? css`
              ${flex('row', 'flex-start', 'center')};
              @media screen and (max-width: 600px) {
                ${flex('column', 'center', 'center')};
                .esg-user-card__info {
                  align-items: center !important;
                }
              }

              .user-image-container,
              img {
                ${size(theme.sizes.SIZE_112)};
                min-width: ${theme.sizes.SIZE_112};
              }

              .wcl-icon {
                ${fontSize(theme.sizes.SIZE_88)};
              }

              .esg-basic-tag {
                ${fontSize(theme.sizes.SIZE_12, theme.sizes.SIZE_12)};
              }
            `
          : css`
              ${flex('column', 'center', 'center')};

              .user-image-container,
              img {
                ${size(theme.sizes.SIZE_152)};
              }

              .wcl-icon {
                ${fontSize(theme.sizes.SIZE_120)};
              }

              .esg-basic-tag {
                ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_16)};
              }
            `};

      gap: ${theme.sizes.SIZE_20};

      .user-image {
        position: relative;

        .esg-basic-tag {
          ${position('absolute', `left 50% bottom -${theme.sizes.SIZE_8}`)};
          transform: translate(-50%);
          width: fit-content;
          padding-top: ${theme.sizes.SIZE_4};
        }

        &-container {
          border-radius: 50%;
          background: linear-gradient(var(--GRAY-EF), var(--GRAY-D9));
          color: var(--GRAY-8F-B3);
          position: relative;
          overflow: hidden;

          .wcl-icon {
            position: absolute;
            bottom: 0;
            left: 15%;
          }
        }
      }

      .esg-user-card__info {
        ${flex('column', 'center', 'center')};
        ${(props) =>
          props.$view === 'list'
            ? css`
                align-items: flex-start;
                gap: ${theme.sizes.SIZE_32};
              `
            : css`
                gap: ${theme.sizes.SIZE_24};

                &__description {
                  display: flex;
                  justify-content: center;
                  text-align: center;

                  span {
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                  }
                }
              `};

        &__name {
          ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_32)};
          font-weight: 600;
          ${(props) =>
            props.$view === 'grid' &&
            css`
              height: ${theme.sizes.SIZE_40};
              align-items: flex-start;
              p {
                width: min-content;
                text-align: center;
              }
            `};
        }

        &__description {
          ${flex('row', 'center', 'center')};
          gap: ${theme.sizes.SIZE_10};
          ${fontSize(theme.fontSizes.FONT_16)};
          color: var(--BLACK) !important;
          text-decoration: unset !important;

          .wcl-icon {
            margin-top: ${theme.sizes.SIZE_2};
            svg {
              ${fontSize(theme.fontSizes.FONT_18)};
            }
          }

          .envelope-icon {
            margin-top: ${theme.sizes.SIZE_4};
          }
        }
      }
    }
  }

  .esg-user-card__delete-btn {
    height: fit-content;
    min-height: unset;
    ${position(
      'absolute',
      `bottom ${theme.sizes.SIZE_12} right ${theme.sizes.SIZE_12}`
    )}
  }
`;

export { StyledUserCard };
