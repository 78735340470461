import {
  actionLabels,
  AssignNotification,
  MarkNotification,
  Notification,
  RoleUpdateNotification,
} from 'models';
import { FC, PropsWithChildren } from 'react';

import { BasicTag } from '../../../components';
import { ApprovalChip } from '../../../components/_molecules/ApprovalProgressBar/components';
import Icon from '../../../components/Icon';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../pages/Users/constants';
import StatusFlag from './components/StatusFlag';
import { StyledNotificationsGrid } from './NotificationsGrid.styled';

const isAssignNotification = (
  notification: Notification
): notification is AssignNotification => {
  return notification.action === 'assign';
};

const isRoleUpdateNotification = (
  notification: Notification
): notification is RoleUpdateNotification => {
  return notification.action === 'role-update';
};

const isMarkNotification = (
  notification: Notification
): notification is MarkNotification => {
  return notification.action === 'mark';
};

export type NotificationsGridProps = PropsWithChildren<{
  notifications: Array<Notification>;
}>;

const NotificationsGrid: FC<NotificationsGridProps> = ({ notifications }) => {
  return (
    <StyledNotificationsGrid className="esg-notifications-grid">
      {notifications?.map((notification, index) => (
        <div
          key={index}
          className="esg-notifications-grid__container"
          tabIndex={0}
        >
          <div className="esg-notifications-grid__container__column">
            <StatusFlag initialIsRead={notification.isRead} />
            <img src="/assets/images/avatar.png" alt="profile-picture" />
            {notification.name}
            <BasicTag
              text={
                ROLE_VALUE_TO_DESCRIPTION_MAPPER[notification.userRole.name]
              }
              color={notification.userRole.color}
            />
          </div>
          <p className="esg-notifications-grid__container__column">
            {actionLabels[notification.action]}
          </p>
          <div className="esg-notifications-grid__container__column">
            {isAssignNotification(notification) ? (
              <>
                <Icon
                  className="esg-session-card__container__column__icon"
                  name="file"
                />
                {notification.assignedForm}
              </>
            ) : isMarkNotification(notification) ? (
              <>
                <Icon
                  className="esg-session-card__container__column__icon"
                  name="file"
                />
                {notification.marked}
              </>
            ) : isRoleUpdateNotification(notification) ? (
              <BasicTag
                text={
                  ROLE_VALUE_TO_DESCRIPTION_MAPPER[
                    notification.updatedRole.name
                  ]
                }
                color={notification.updatedRole.color}
              />
            ) : null}
          </div>
          {isMarkNotification(notification) && (
            <div className="esg-notifications-grid__container__column">
              <span>As</span>
              <ApprovalChip status={notification.markedAs} />
            </div>
          )}
        </div>
      ))}
    </StyledNotificationsGrid>
  );
};

export default NotificationsGrid;
