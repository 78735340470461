import { SwitchDataModule } from 'models';
import { FC, PropsWithChildren } from 'react';

import SelectModuleField from '../../../../../components/_fields/SelectModuleField';
import { BASE_CONFIGURABLE_MODULES } from '../../baseModules';
import BaseSwitchFieldConfiguration from '../../baseModules/BaseSwitchFieldConfiguration/BaseSwitchFieldConfiguration.component';

export type ConditionalSwitchFieldConfigurationProps =
  PropsWithChildren<SwitchDataModule> & {
    hasConditions?: boolean;
    initialData?: Record<string, any>;
  };

const ConditionLabel: FC<{ isOn: boolean }> = ({ isOn }) => (
  <>
    If Switch is <strong>{isOn ? 'ON' : 'OFF'}</strong>
  </>
);

const ConditionalSwitchFieldConfiguration: FC<
  ConditionalSwitchFieldConfigurationProps
> = (props) => {
  return (
    <BaseSwitchFieldConfiguration
      {...props}
      renderModuleField={(fieldName) => (
        <SelectModuleField
          name={fieldName}
          label={<ConditionLabel isOn={fieldName === 'on'} />}
          parentId={props.id}
          configurableModules={BASE_CONFIGURABLE_MODULES}
          fullWidth
        />
      )}
    />
  );
};

export default ConditionalSwitchFieldConfiguration;
