import cloneDeep from 'lodash.clonedeep';
import { CheckStatus, TreeNodeElement } from 'models';

const updateNodeChildren = (
  node: TreeNodeElement,
  checked: TreeNodeElement['checked']
) => {
  node.checked = checked;

  if (node.children)
    node.children.forEach((child) => updateNodeChildren(child, checked));
};

const updateNodeParents = (node: TreeNodeElement) => {
  let nextNode = node.parent;

  while (nextNode) {
    const allChildrenChecked = nextNode.children!.every(
      (child) => child.checked === CheckStatus.Checked
    );

    if (allChildrenChecked) {
      nextNode.checked = CheckStatus.Checked;
    } else {
      const someChildrenChecked = nextNode.children!.some((child) =>
        [CheckStatus.Checked, CheckStatus.Indeterminate].includes(
          child.checked!
        )
      );

      nextNode.checked = someChildrenChecked
        ? CheckStatus.Indeterminate
        : CheckStatus.Unchecked;
    }

    nextNode = nextNode.parent;
  }
};

export const toggleCheck = (
  node: TreeNodeElement,
  checked: TreeNodeElement['checked']
) => {
  updateNodeChildren(node, checked);
  updateNodeParents(node);
};

const buildNodeMap = (
  root: TreeNodeElement,
  map: Map<string, TreeNodeElement> = new Map()
) => {
  map.set(root?.id as string, root);
  if (root.children) {
    root.children.forEach((child) => buildNodeMap(child, map));
  }
  return map;
};

export const toggleCheckByIds = (root: TreeNodeElement, ids: string[]) => {
  const rootCopy = cloneDeep(root);

  const nodeMap = buildNodeMap(rootCopy);

  ids.forEach((id) => {
    const node = nodeMap.get(id);

    if (node && ids.includes(node.id as string)) {
      updateNodeChildren(node, CheckStatus.Checked);
      updateNodeParents(node);
    }
  });

  return rootCopy;
};
