import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionReportsEntry = styled.div`
  padding: ${theme.sizes.SIZE_32};

  &.esg-session-reports-entry--loading {
    ${flex('row')};
    height: 100%;
  }

  .esg-session-reports-entry {
    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};
      margin: ${theme.sizes.SIZE_32} 0;

      .wcl-icon {
        ${fontSize(theme.sizes.SIZE_32)};
      }
    }

    &__subheader {
      ${fontSize(theme.fontSizes.FONT_14)};
      margin: 0 0 ${theme.sizes.SIZE_46};
    }

    &__list {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_32};
    }
  }
`;

export { StyledSessionReportsEntry };
