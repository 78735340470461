import { FieldProps } from '@faxi/web-form';
import InlineEditable from 'components/_molecules/InlineEditable';
import { InlineEditableType } from 'models';
import { FC } from 'react';

export type InlineEditableFieldProps = FieldProps<string> &
  Omit<InlineEditableType, 'onSave' | 'text'> & {
    shouldSave?: (value: string) => boolean;
    handleNoSave?: (string?: string) => void;
  };

const InlineEditableField: FC<InlineEditableFieldProps> = (props) => {
  const {
    placeholderText,
    onDiscard,
    onChange,
    shouldSave,
    handleNoSave,
    dirty,
    touched,
    ...rest
  } = props;

  return (
    <InlineEditable
      {...rest}
      placeholderText={placeholderText}
      onDiscard={onDiscard}
      onSave={(text) => {
        if (shouldSave?.(text as string)) {
          onChange?.(text as string);
        } else {
          onChange?.('');
          handleNoSave?.(text);
        }
      }}
      onChange={onChange}
    />
  );
};

export default InlineEditableField;
