import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCheckboxIconLabel = styled.div`
  ${flex('row', 'flex-start', 'center')};
  gap: ${theme.sizes.SIZE_8};
  height: ${theme.sizes.SIZE_32};

  p {
    ${fontSize(theme.fontSizes.FONT_24)};
    font-weight: 600;
    margin: 0;
  }

  .wcl-checkbox {
    margin-right: ${theme.sizes.SIZE_24};
  }
`;

export { StyledCheckboxIconLabel };
