import { useEffectSkipFirst, useUtilities } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import useGetUsers from '../../../../../../../api/hooks/users/useGetUsers';
import { Loading } from '../../../../../../../components';
import UserRoleHeader from '../../../../pages/UserRole/components/UserRoleHeader';
import UserRolePermissions from '../../../../pages/UserRole/components/UserRolePermissions';
import { USER_ROLE_FORM_DATA } from '../../../../pages/UserRole/data';

export type UserByIdNotificationsProps = PropsWithChildren<{}>;

const UserByIdNotifications: FC<UserByIdNotificationsProps> = (props) => {
  //TODO: change with get user by id request when BE is ready
  const { data: users, isLoading } = useGetUsers();
  const { userId } = useParams();
  const userRole = users?.find((user) => user.id === userId)?.roles[0]!;

  const { showSnackBar } = useUtilities();

  useEffectSkipFirst(() => {
    showSnackBar({
      variant: 'error',
      text: 'Missing backend logic for user permissions',
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <UserRoleHeader
        description={userRole.description}
        name={userRole.name}
        color={userRole.color}
      />
      <UserRolePermissions
        onSubmit={() => Promise.resolve()}
        permissions={USER_ROLE_FORM_DATA}
      />
    </>
  );
};

export default UserByIdNotifications;
