import CompanyTreeNode, {
  CompanyTreeNodeProps,
} from './components/CompanyTreeNode/CompanyTreeNode.component';

export const COMPANY_TREE_NODE_DATA_WITHOUT_PARENTS: CompanyTreeNodeProps = {
  id: '1',
  element: 'All',
  name: 'All',
  contributors: [
    'dule.jpeg',
    'kumca.png',
    'cvele.png',
    'joca.png',
    'ivca.png',
    'apox.jpeg',
    'mateja.jpeg',
    'nemca.jpeg',
  ],
  progress: {
    currentStep: 85,
    numberOfSteps: 100,
    status: 'In Progress',
  },
  children: [
    {
      id: '2',
      element: 'Environment',
      name: 'Environment',
      contributors: ['cvele.png', 'nemca.jpeg', 'ivca.png', 'joca.png'],
      progress: {
        currentStep: 2,
        numberOfSteps: 8,
        status: 'Completed',
      },
      children: [
        {
          id: '3',
          element: 'E1 - Climate Change',
          name: 'E1 - Climate Change',
          contributors: ['dule.jpeg', 'apox.jpeg'],
          progress: {
            currentStep: 3,
            numberOfSteps: 5,
            status: 'In Progress',
          },
          children: [
            {
              id: '4',
              element: 'E1 - 1 - Climate Change Adaptation',
              name: 'E1 - 1 - Climate Change Adaptation',
              contributors: ['joca.png', 'cvele.png', 'mateja.jpeg'],
              progress: {
                currentStep: 4,
                numberOfSteps: 4,
                status: 'In Progress',
              },
            },
            {
              id: '5',
              element: 'E1 - 2 - Climate Change Mitigation',
              name: 'E1 - 2 - Climate Change Mitigation',
              contributors: ['dule.jpeg', 'kumca.png', 'ivca.png'],
              progress: {
                currentStep: 4,
                numberOfSteps: 4,
                status: 'In Progress',
              },
            },
          ],
        },
        {
          id: '6',
          element: 'E2 - Pollution',
          name: 'E2 - Pollution',
          contributors: ['kumca.png', 'joca.png', 'cvele.png', 'apox.jpeg'],
          progress: {
            currentStep: 3,
            numberOfSteps: 5,
            status: 'In Progress',
          },
          children: [
            {
              id: '7',
              element: 'E2 - 1 - Pollution of air',
              name: 'E2 - 1 - Pollution of air',
              contributors: ['nemca.jpeg', 'mateja.jpeg'],
              progress: {
                currentStep: 4,
                numberOfSteps: 4,
                status: 'In Progress',
              },
            },
            {
              id: '8',
              element: 'E2 - 2 - Pollution of water',
              name: 'E2 - 2 - Pollution of water',
              contributors: ['dule.jpeg', 'kumca.png', 'joca.png', 'ivca.png'],
              progress: {
                currentStep: 4,
                numberOfSteps: 4,
                status: 'In Progress',
              },
            },
          ],
        },
      ],
    },
    {
      id: '9',
      element: 'Social',
      name: 'Social',
      contributors: [
        'joca.png',
        'cvele.png',
        'nemca.jpeg',
        'dule.jpeg',
        'apox.jpeg',
      ],
      progress: {
        currentStep: 1,
        numberOfSteps: 6,
        status: 'In Progress',
      },
      children: [
        {
          id: '10',
          element: 'Freedom of Expression',
          name: 'Freedom of Expression',
          contributors: ['kumca.png', 'mateja.jpeg'],
          progress: {
            currentStep: 2,
            numberOfSteps: 7,
            status: 'Completed',
          },
          children: [
            {
              id: '11',
              element: 'E1 - 1 - Right to Education',
              name: 'E1 - 1 - Right to Education',
              contributors: ['joca.png', 'cvele.png', 'ivca.png'],
              progress: {
                currentStep: 3,
                numberOfSteps: 3,
                status: 'In Review',
              },
            },
            {
              id: '12',
              element: 'E2 - 2 - Protection from Discrimination',
              name: 'E2 - 2 - Protection from Discrimination',
              contributors: ['nemca.jpeg', 'dule.jpeg', 'apox.jpeg'],
              progress: {
                currentStep: 4,
                numberOfSteps: 5,
                status: 'In Progress',
              },
            },
          ],
        },
        {
          id: '13',
          element: 'E2 - Social Welfare',
          name: 'E2 - Social Welfare',
          contributors: ['apox.jpeg', 'joca.png', 'kumca.png', 'ivca.png'],
          progress: {
            currentStep: 1,
            numberOfSteps: 3,
            status: 'In Progress',
          },
          children: [
            {
              id: '14',
              element: 'E2 - 1 - Community Support',
              contributors: ['cvele.png', 'nemca.jpeg'],
              progress: {
                currentStep: 2,
                numberOfSteps: 2,
                status: 'Completed',
              },
              name: 'E2 - 1 - Community Support',
            },
          ],
        },
      ],
    },
  ],
};

const createTreeWithCompanyNodes = (
  node: CompanyTreeNodeProps
): CompanyTreeNodeProps => {
  node.element = <CompanyTreeNode {...node} />;
  node.children = node.children?.map((child) =>
    createTreeWithCompanyNodes(child)
  );

  return node;
};

const COMPANY_TREE_NODE_DATA = createTreeWithCompanyNodes(
  COMPANY_TREE_NODE_DATA_WITHOUT_PARENTS
);

export { COMPANY_TREE_NODE_DATA };
