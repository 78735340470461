import { Users } from 'pages';
import { Navigate, RouteObject } from 'react-router-dom';

import { USER_MANAGEMENT_NAV } from '../../../constants';
import UserProfile from '../../../pages/UserProfile';
import UserRole from './pages/UserRole';
import UserRoles from './pages/UserRoles';
import UserRolesNotFound from './pages/UserRolesNotFound';
import UserByIdAssignment from './subrouters/UserProfile/pages/UserByIdAssignment';
import UserByIdDetails from './subrouters/UserProfile/pages/UserByIdDetails';
import UserByIdPermissions from './subrouters/UserProfile/pages/UserByIdPermissions';

export default [
  { index: true, element: <Users /> },
  {
    path: ':userId',
    element: <UserProfile navLinks={USER_MANAGEMENT_NAV} />,
    children: [
      { path: 'personal-details', element: <UserByIdDetails /> },
      {
        path: 'permissions',
        element: <UserByIdPermissions />,
      },
      {
        path: 'assignments',
        element: <UserByIdAssignment />,
      },
      {
        index: true,
        element: <Navigate to={'personal-details'} replace />,
      },
    ],
  },
  {
    path: 'user-roles',
    element: <UserRoles />,
    children: [
      {
        index: true,
        element: <UserRolesNotFound />,
      },
      {
        path: ':roleId',
        element: <UserRole />,
      },
    ],
  },
] as RouteObject[];
