import { useEffectSkipFirst, useUtilities } from '@faxi/web-component-library';
import { FC, PropsWithChildren } from 'react';

import { BasicTreeView } from '../../../components';
import { UserAssignementsTreeNodeProps } from '../../subrouters/Users/subrouters/UserProfile/pages/UserAssignment/mock';
import { StyledProfileAssignment } from './ProfileAssignment.styled';

export type ProfileAssignmentProps = PropsWithChildren<{
  data: UserAssignementsTreeNodeProps;
  onCheck?: () => void;
}>;

const ProfileAssignment: FC<ProfileAssignmentProps> = (props) => {
  const { data, onCheck } = props;

  const { showSnackBar } = useUtilities();

  //TODO: Remove snackbar when we implement BE for user profile pages
  useEffectSkipFirst(() => {
    showSnackBar({
      variant: 'error',
      text: 'User assignemets are mocked. (Missing BE for functionalities)',
    });
  }, []);

  return (
    <StyledProfileAssignment className="esg-profile-assignment">
      <h2>Assignments</h2>
      <BasicTreeView data={data} onCheck={onCheck} />
    </StyledProfileAssignment>
  );
};

export default ProfileAssignment;
