import { useCallback } from 'react';

import { removeModuleElement } from '../components/_fields/SelectModuleField/utils';
import { useModulesStore } from '../store/modulesStore';

/**
 * This hook is used to remove a conditional module field by its name and id.
 * @param id - The id of the module to be removed.
 */
const useRemoveConditionalModule = (id: string) => {
  const { localFormModules, setLocalFormModules: setCurrentModules } =
    useModulesStore();

  const handleRemoveModule = useCallback(
    (name: string) => {
      setCurrentModules(removeModuleElement(id, name)(localFormModules));
    },
    [setCurrentModules, id, localFormModules]
  );

  return { handleRemoveModule };
};

export default useRemoveConditionalModule;
