import { OrganisationAPI } from 'models';
import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from 'swr';

import { API_ROUTES } from '../routes';

/**
 * This hook supports dependent queries where the new query relies on the 
 * organisation data. 
 * 
 * Example usage: 
 *  const { data, isLoading, error } = api.useOrganisationsSWR(
 *    (organisationsId: string) => `/campaign-sessions/${organisationsId}/tree`
    );
 */
const useOrganisationsSWR = <TData = any, TError = Error>(
  generateKey: (organisationId: string) => string | boolean,
  config?: SWRConfiguration<TData, TError, BareFetcher<TData>>,
  shouldFetch = true
): SWRResponse<TData, TError> => {
  const {
    isLoading: organisationsLoading,
    error: organisationsError,
    data: organisations,
  } = useSWR<{ data: OrganisationAPI }>(
    shouldFetch && API_ROUTES.COMPANIES.ORGANISATIONS
  );

  const { isLoading, error, ...rest } = useSWR<TData, TError>(
    () => organisations?.data.id && generateKey(organisations?.data.id),
    config
  );

  return {
    isLoading: organisationsLoading || isLoading,
    error: organisationsError || error,
    ...rest,
  };
};

export default useOrganisationsSWR;
