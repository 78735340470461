import {
  ButtonProps,
  Checkbox,
  MenuRef,
  useCallbackRef,
  useEventListener,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { useHeightAnimation } from 'hooks';
import { CheckStatus, TreeNodeElement } from 'models';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Icon from '../../../../Icon';
import ThreeDotMenu from '../../../ThreeDotMenu';
import { LeafIcon, NodeIcon } from './components';
import { StyledTreeNode } from './TreeNode.styled';

export type TreeNodeProps = {
  onCheck?: (
    node: TreeNodeElement,
    checked: TreeNodeElement['checked']
  ) => void;
  activeNodeId?: string;
  className?: string;
  level: number;
  node: TreeNodeElement & { canDelete?: boolean };
  withMenu?: boolean;
  onDelete?: (node: TreeNodeElement) => void;
  onDuplicate?: (node: TreeNodeElement) => void;
};

const TreeNode: FC<TreeNodeProps> = (props) => {
  const {
    level,
    node,
    activeNodeId,
    withMenu = false,
    className,
    onCheck,
    onDelete,
    onDuplicate,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const menuRef = useRef<MenuRef>(null);
  const hoveringRef = useRef<boolean>(false);

  const [container, containerRef] = useCallbackRef<HTMLDivElement>();
  const [subFolders, subFoldersRef] = useCallbackRef<HTMLDivElement>();

  const [hovering, setHovering] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useHeightAnimation({ element: subFolders, isOpen: isExpanded });

  const handleToggle = useCallback(() => setIsExpanded((prev) => !prev), []);

  const handleCheck = (checked: boolean) => {
    const checkStatus = checked ? CheckStatus.Checked : CheckStatus.Unchecked;
    onCheck?.(node, checkStatus);
  };

  const checkClosingMenu = useCallback(() => {
    if (hoveringRef.current) return;
    setHovering(false);
  }, []);

  const nodeMenuItems = useMemo(() => {
    const onDuplicateAction: ButtonProps[] = onDuplicate
      ? [
          {
            children: 'Duplicate',
            icon: <Icon name="copy" />,
            variant: 'ghost',
            onClick: (e: any) => {
              e.stopPropagation();

              onDuplicate?.(node);
              checkClosingMenu();
            },
          },
        ]
      : [];

    //TODO: this is temporary
    const onDeleteAction: ButtonProps[] =
      [undefined, true].includes(node.canDelete) && onDelete
        ? [
            {
              children: 'Delete',
              icon: <Icon name="trash-can" className="color-secondary" />,
              variant: 'delete-ghost',
              onClick: (e: any) => {
                e.stopPropagation();
                onDelete?.(node);
                checkClosingMenu();
              },
            },
          ]
        : [];

    return [...onDuplicateAction, ...onDeleteAction];
  }, [node, checkClosingMenu, onDelete, onDuplicate]);

  useEventListener({
    condition: withMenu,
    element: container,
    listener: 'mouseenter',
    callback: () => {
      hoveringRef.current = true;
      setHovering(true);
    },
  });

  useEventListener({
    condition: withMenu,
    element: container,
    listener: 'mouseleave',
    callback: () => {
      hoveringRef.current = false;

      if (menuRef.current?.open) return;
      setHovering(false);
    },
  });

  return (
    <StyledTreeNode
      className={classNames('esg-tree-node-component', className)}
      $level={level}
    >
      <div
        className={classNames('esg-tree-node-component__base-node', {
          'esg-tree-node-component__base-node--active':
            // IF THERE QUERY PARAMS, IGNORE THEM
            node.to?.split('?')[0] === location.pathname,
        })}
      >
        {onCheck && (
          <Checkbox
            checked={[CheckStatus.Indeterminate, CheckStatus.Checked].includes(
              node.checked!
            )}
            indeterminate={node.checked === CheckStatus.Indeterminate}
            onChange={handleCheck}
          />
        )}
        <div
          ref={containerRef}
          data-uniq-id={node.uniqName}
          onClick={() =>
            node.to ? navigate(node.to as string) : handleToggle()
          }
          className={classNames('esg-tree-node-component__icon', {
            'esg-tree-node-component__icon--leaf': !node.children,
            'esg-tree-node-component__icon--active': node.id === activeNodeId,
          })}
        >
          {node.children ? (
            <NodeIcon node={node} isOpen={isExpanded} onClick={handleToggle} />
          ) : (
            <LeafIcon node={node} />
          )}
          {node.element ?? node.name}
          {withMenu && hovering && !!nodeMenuItems.length && (
            <ThreeDotMenu
              ref={menuRef}
              className="esg-tree-node-component__icon__menu"
              menuItems={nodeMenuItems}
              onClose={checkClosingMenu}
            />
          )}
        </div>
      </div>

      {node.children && (
        <div
          ref={subFoldersRef}
          className="esg-tree-node-component__sub-folders"
        >
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              activeNodeId={activeNodeId}
              node={child}
              withMenu={withMenu}
              level={level + 1}
              onCheck={onCheck}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
            />
          ))}
        </div>
      )}
    </StyledTreeNode>
  );
};

export default TreeNode;
