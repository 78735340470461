import {
  Button,
  useEffectSkipFirst,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { CollapsibleSidePanel, Loading, ThreeDotMenu } from 'components';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { useDefaultCardMenu } from 'hooks';
import { FC, useEffect } from 'react';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useGetRoles from '../../../../../api/hooks/users/useGetRoles';
import { ColorRoleIdentifier } from '../UserRole/styles';
import { CRUMBS, USER_ROLE_NAME_MAPPER } from './constants';
import { StyledUserRoles } from './UserRoles.styled';

export type UserRolesProps = {};

const UserRoles: FC<UserRolesProps> = () => {
  const { pathname } = useLocation();
  const { roleId } = useParams();
  const navigate = useNavigate();
  const { data: userRoles, isLoading } = useGetRoles(); //TODO: to be replaced the actual api call for fetching roles

  const { showSnackBar } = useUtilities();

  //TODO: implement actions when BE is ready
  const defaultMenuItems = useDefaultCardMenu(
    () => {
      showSnackBar({
        variant: 'error',
        text: 'No edit role action (Missing BE)',
      });
    },

    () => {
      showSnackBar({
        variant: 'error',
        text: 'No delete role action (Missing BE)',
      });
    }
  );

  useEffect(() => {
    if (userRoles?.length && APP_URI.USER_ROLES === pathname) {
      navigate(userRoles[0].id, { replace: true });
    }

    if (!userRoles?.length && roleId) {
      navigate(APP_URI.USER_ROLES, { replace: true });
    }
  }, [navigate, pathname, roleId, userRoles]);

  //TODO: remove when BE is connected
  useEffectSkipFirst(() => {
    showSnackBar({
      variant: 'error',
      text: 'User roles are missing BE functionalities',
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledUserRoles
      title="User Roles"
      className="esg-user-roles"
      breadcrumbs={CRUMBS}
      padding={0}
    >
      <CollapsibleSidePanel expandDirection="right">
        <Button icon={<Icon name="plus" />} variant="outline" disabled={true}>
          Create new role
        </Button>
        <nav className="esg-user-roles__nav">
          {userRoles?.map((role) => (
            <div key={role.id}>
              <NavLink
                className={classNames('esg-user-roles__nav__link', {
                  'esg-user-roles__nav__link--active': role.id === roleId,
                })}
                key={role.id}
                to={role.id}
                replace
              >
                <div>
                  <ColorRoleIdentifier color={role.color} />
                  <span>{USER_ROLE_NAME_MAPPER[role.name]}</span>
                </div>
                <ThreeDotMenu menuItems={defaultMenuItems} />
              </NavLink>
            </div>
          ))}
        </nav>
      </CollapsibleSidePanel>
      <div className="esg-user-roles__container">
        <Outlet />
      </div>
    </StyledUserRoles>
  );
};

export default UserRoles;
