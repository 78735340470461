import { AuthBody, NewUserAuthBody } from 'models';

import credentialsService from './credentialsService';

export default {
  isLoggedIn: () => !!credentialsService.token,
  isLoggedInNewUser: () => !!credentialsService.newUserToken,
  loginUser: (authBody: AuthBody) => credentialsService.saveAuthBody(authBody),
  loginNewUser: (authBody: NewUserAuthBody) =>
    credentialsService.saveNewUserAuthBody(authBody),
  logoutUser: () => credentialsService.removeAuthBody(),
  logoutNewUser: () => credentialsService.removeNewUserAuthBody(),
  isNewUser: (data: AuthBody | NewUserAuthBody): data is NewUserAuthBody =>
    'userIdForSrp' in data,
};
