import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import { FC, ReactNode, useContext, useEffect,useState } from 'react';

import CampaignItemContext from '../CampaignItem/CampaignItem.context';
import FormBuilderContext, {
  FormBuilderContextProps,
} from './FormBuilder.context';

type FormBuilderProps = {
  children?: ReactNode;
};

export const generateModulesFromCampaignItem = (
  elements?: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
  elements
    ?.map(({ index, type, title = '', id, elements, ...rest }) => ({
      id: `${id}+${type}`,
      index,
      type,
      title,
      elements: generateModulesFromCampaignItem(elements),
      ...rest,
    }))
    .sort(({ index: index1 }, { index: index2 }) =>
      index1 < index2 ? -1 : 1
    ) || [];

const FormBuilderProvider: FC<FormBuilderProps> = (props) => {
  const { children } = props;

  const { campaignItem, mutating } = useContext(CampaignItemContext);

  const [selectedDataModule, setSelectedDataModule] = useState<string>();

  const [modules, setModules] = useState<
    ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  >([]);

  const selectDataModule = (dataModule: string) =>
    setSelectedDataModule((prev) =>
      prev === dataModule ? undefined : dataModule
    );

  useEffect(() => {
    if (!campaignItem?.form?.elements) return;

    setModules(generateModulesFromCampaignItem(campaignItem.form.elements));
  }, [campaignItem]);

  return (
    <FormBuilderContext.Provider
      value={{
        loadingNewModule: mutating,
        selectedDataModule,
        selectDataModule,
        modules,
        setModules,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

const useFormBuilder = (): FormBuilderContextProps =>
  useContext(FormBuilderContext);

export { FormBuilderProvider, useFormBuilder };
