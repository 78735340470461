import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import Icon from 'components/Icon';
import { Organisation, TreeNodeElement } from 'models';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { findNode } from 'utils';

import { API_ROUTES } from '../../../../../api/routes';
import { buildOrganisationTreePath, getPathSegment } from '../../utils';
import OrganisationContext, {
  OrganisationContextProps,
} from './Organisation.context';

type OrganisationProviderProps = PropsWithChildren<{}>;

const redirectAfterDeletion = (
  navigate: NavigateFunction,
  deleteId: string,
  activeOrganisation?: Organisation
) => {
  const parentIds = activeOrganisation?.path.ids ?? [];
  const isSubCompanyDeleted = parentIds.includes(deleteId);

  if (isSubCompanyDeleted) {
    const indexOfElement = parentIds.indexOf(deleteId) - 1;

    const baseOrganisationPath = getPathSegment(window.location.pathname);

    let newPath = baseOrganisationPath;

    // if we are deleting direct child from organisation we need to redirect user to the organisation
    if (indexOfElement > 0) {
      newPath += `/companies/${parentIds[indexOfElement]}`;
    }

    navigate(newPath);
  }
};

const mapOrganisationToTreeNodeElement = (
  { id, name, description, created, modified, children }: Organisation,
  depth: number,
  path: string,
  navigate: NavigateFunction
): TreeNodeElement => {
  const newPath = buildOrganisationTreePath(depth, id, path);

  return {
    id,
    name,
    description,
    created,
    modified,
    children: children?.map((child) => {
      return mapOrganisationToTreeNodeElement(
        child,
        depth + 1,
        newPath,
        navigate
      );
    }),
    iconName: 'folder-open',
    to: newPath,
  };
};

const OrganisationProvider: FC<OrganisationProviderProps> = () => {
  const navigate = useNavigate();
  const { prompts, showOverlay, hideOverlay } = useUtilities();

  const params = useParams<{
    organisation: string;
    company?: string;
  }>();

  const [deletingOrgId, setDeletingOrgId] = useState<string>();
  const { request: deleteOrg, isMutating } = api.useMutation(
    API_ROUTES.COMPANIES.ORGANISATION(deletingOrgId as string)
  );

  const {
    data: { data: organisations } = {},
    isLoading,
    mutate: mutateOrganisations,
  } = api.useOrganisationsSWR((orgId) =>
    API_ROUTES.COMPANIES.ORGANISATION_TREE(orgId)
  );

  const loading = isLoading || isMutating;
  const currentOrganisationId = params.company || params.organisation!;

  const activeOrganisation = useMemo(
    () =>
      organisations
        ? findNode<Organisation>(
            currentOrganisationId,
            Array(organisations) as Organisation[]
          )
        : undefined,
    [currentOrganisationId, organisations]
  );

  const deleteOrganisation = useCallback(
    async (
      org: Organisation,
      trigger?: HTMLButtonElement,
      callback?: () => void
    ) => {
      setDeletingOrgId(org.id);

      const proceed = await prompts.delete({
        type: 'delete',
        title: `Remove ${org?.name}`,
        content: `Are you sure you want to remove company ${org?.name}?`,
        submitBtnText: 'Remove',
        cancelBtnText: 'Do not remove',
        btnIcon: 'trash-can',
        iconPosition: 'left',
        submitBtnVariant: 'delete-ghost',
        triggerRef: trigger as HTMLButtonElement,

        titleIcon: <Icon name="triangle-exclamation" />,
      });

      if (!proceed) return;
      showOverlay('body');

      try {
        await deleteOrg('DELETE', {});
        await mutateOrganisations();
        callback?.();
        redirectAfterDeletion(navigate, org.id, activeOrganisation);

        setDeletingOrgId(undefined);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [
      navigate,
      activeOrganisation,
      prompts,
      showOverlay,
      deleteOrg,
      mutateOrganisations,
      hideOverlay,
    ]
  );

  const tree = useMemo(
    () =>
      organisations &&
      mapOrganisationToTreeNodeElement(organisations, 0, '', navigate),
    [organisations, navigate]
  );

  return (
    <OrganisationContext.Provider
      value={{
        tree,
        loading,
        activeOrganisation,
        deleteOrganisation,
        mutateOrganisations,
      }}
    >
      <Outlet />
    </OrganisationContext.Provider>
  );
};

const useOrganisationProvider = (): OrganisationContextProps =>
  useContext(OrganisationContext);

export { OrganisationProvider, useOrganisationProvider };
