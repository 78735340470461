import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCompanyTreeNode = styled.div`
  ${flex('row', 'space-between', 'center')};
  width: 100%;
  gap: ${theme.sizes.SIZE_64};

  .esg-company-tree-node {
    &__section {
      flex-grow: 1;

      &--folder {
        font-weight: 600;
        cursor: pointer;
      }
    }

    &__contributors {
      flex: 0 0 204px;
    }

    &__status {
      flex: 0 0 174px;
    }
  }
`;

export { StyledCompanyTreeNode };
