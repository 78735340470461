import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledConditionalCanvasModule = styled.div`
  ${flex('row', 'flex-start', 'center')};
  border: 1px solid;
  min-height: ${theme.sizes.SIZE_48};

  border-radius: ${theme.sizes.SIZE_8};
  padding: 0 ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_12};

  width: ${theme.sizes.SIZE_320};

  * > div:has(.esg-inline-editable__input--is-editing) {
    width: 100%;
  }
`;

export { StyledConditionalCanvasModule };
