import { InputField } from 'components';
import { InputPreviewType } from 'models';
import { FC } from 'react';
import { ExtractComponentProps } from 'types';

export type SimpleFieldProps = ExtractComponentProps<typeof InputField> &
  InputPreviewType;

const SimpleField: FC<SimpleFieldProps> = (props) => {
  const { config, onSave, ...rest } = props as any;

  return (
    <InputField placeholder={config?.placeholder} config={config} {...rest} />
  );
};

export default SimpleField;
