import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledBasicTag = styled.div<{ $bgColor: string }>`
  padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_10};
  border-radius: ${theme.sizes.SIZE_8};
  color: var(--WHITE-FF);
  background-color: ${({ $bgColor }) => $bgColor};
`;

export { StyledBasicTag };
