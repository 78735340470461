import { RadioGroupOption } from '@faxi/web-component-library';
import { FormField, validators } from '@faxi/web-form';
import { TextDataModule } from 'models';
import { FC } from 'react';

import RadioGroupField from '../../../../../components/_fields/RadioGroupField';
import { StyledBaseTextFieldConfiguration } from './BaseTextFieldConfiguration.styled';

const TEXT_FIELD_MODULE_TYPE = [
  {
    label: 'Heading',
    value: 'heading',
    optionProps: { className: 'text-format--heading' },
  },
  {
    label: 'Title',
    value: 'title',
    optionProps: { className: 'text-format--title' },
  },
  {
    label: 'Subtitle',
    value: 'subtitle',
    optionProps: { className: 'text-format--subtitle' },
  },
  {
    label: 'Body text',
    value: 'body-text',
    optionProps: { className: 'text-format--body' },
  },
] as RadioGroupOption[];

export type BaseTextFieldConfigurationProps = TextDataModule;
const BaseTextFieldConfiguration: FC<BaseTextFieldConfigurationProps> = (
  props
) => {
  return (
    <StyledBaseTextFieldConfiguration className="esg-text-field-configuration">
      <FormField
        name="textFormat"
        component={RadioGroupField}
        options={TEXT_FIELD_MODULE_TYPE}
        orientation="column"
        validate={validators.general.required('Text type is required')}
      />
    </StyledBaseTextFieldConfiguration>
  );
};

export default BaseTextFieldConfiguration;
