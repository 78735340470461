import { Dropdown, elevate_l, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledTagSearchContent = css`
  .wcl-dropdown {
    &__content {
      ${elevate_l}

      border: unset;
      border-radius: 6px;
      padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_8};

      hr {
        margin: ${theme.sizes.SIZE_8} 0;
      }
    }
  }

  .esg-tag-search {
    &__content {
      &__option {
        ${flex('row', 'flex-start', 'center')};
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_20)};
        font-weight: 500;

        cursor: pointer;
        border-radius: 6px;
        gap: ${theme.sizes.SIZE_4};
        transition: background 100ms ease-in-out;
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8};

        .wcl-icon {
          ${size(theme.sizes.SIZE_24)};
        }

        &:focus {
          background-color: var(--GRAY-D9);
        }

        &:hover {
          background-color: var(--GRAY-EF);
        }
      }

      &__options {
        ${flex('column')}
        ${flexGap(theme.sizes.SIZE_8, 'column')};

        max-height: 300px;
        overflow: auto;
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        font-weight: 400;
        padding: 0 ${theme.sizes.SIZE_8};
        margin: 0 0 ${theme.sizes.SIZE_16};
      }

      &__chips {
        .wcl-checkbox-group__checkboxes {
          display: grid;
          gap: ${theme.sizes.SIZE_12};
          padding: 0 ${theme.sizes.SIZE_8};
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }

        .wcl-checkbox {
          width: 100%;

          &--checked {
            .wcl-checkbox__label {
              border: unset;
              background-color: unset;

              .kinto-status-element {
                border: ${theme.sizes.SIZE_2} solid var(--GRAY-C1);
              }
            }
          }

          &__label {
            padding: 0;
            border: unset;
            border-radius: 0;
            background-color: unset;

            > span,
            & {
              width: 100%;
            }
          }

          .kinto-status-element {
            width: 100%;
            border-radius: ${theme.sizes.SIZE_8};
          }
        }
      }
    }
  }
`;

const StyledTagSearch = styled(Dropdown)`
  ${StyledTagSearchContent}

  height: fit-content;
  width: ${theme.sizes.SIZE_400};

  .esg-tag-search {
    &__container {
      ${flex('row')};

      gap: 8px;
      background-color: #f5f5f6;
      flex-wrap: wrap;
      border: 1px solid red;
      border-top: unset;
    }
  }
`;

export { StyledTagSearch, StyledTagSearchContent };
