import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateUpOnDelete = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (id: string) => {
      if (location.pathname.includes(id)) {
        const splitPathname = location.pathname.split('/');

        const index = splitPathname.findIndex((el) => el === id);

        const finalLocation = splitPathname.slice(0, index - 1).join('/');

        navigate(finalLocation);
      }
    },
    [location.pathname, navigate]
  );

  return { handleNavigate };
};

export default useNavigateUpOnDelete;
