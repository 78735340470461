import { Spinner } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import * as Styles from './Loading.styled';

export type LoadingProps = {
  className?: string;
};

const Loading: FC<LoadingProps> = ({ className }) => {
  const [hover, setHover] = useState(false);

  const [isSlowLoading, setIsSlowLoading] = useState(false);

  useEffect(() => {
    const intervalTimeout = setInterval(() => {
      setIsSlowLoading((prev) => !prev);
    }, 3000);

    return () => {
      clearInterval(intervalTimeout);
    };
  }, []);

  return (
    <Styles.LoadingStyled className={classNames('esg-loading', className)}>
      <div
        className="esg-loading__content"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <Spinner size={40} color="#000" />
        <div className="esg-loading__content__loading">
          {hover
            ? 'Please wait'
            : !isSlowLoading
              ? 'Loading'
              : 'This may take a while'}
        </div>
      </div>
    </Styles.LoadingStyled>
  );
};

export default Loading;
