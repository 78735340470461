import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';

import { generateUniqueId } from '../../../../utils/generateUID';

export const generateModuleData = (
  selectedModule?: DataModuleEnum
): ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined =>
  selectedModule && {
    index: 0,
    id: generateUniqueId(),
    type: selectedModule,
    title: '',
    hasConditions: false,
  };
