import { RouteObject } from 'react-router-dom';

import UserAssignment from './pages/UserAssignment';
import UserDetails from './pages/UserDetails';
import UserNotifications from './pages/UserNotifications';

export default [
  { path: 'personal-details', element: <UserDetails /> },
  {
    path: 'notifications',
    element: <UserNotifications />,
  },
  {
    path: 'assignments',
    element: <UserAssignment />,
  },
] as RouteObject[];
