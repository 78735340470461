import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCampaignChip = styled(Link)`
  ${flex('row', 'center', 'center')};
  ${fontSize(theme.fontSizes.FONT_14)};
  min-width: ${theme.sizes.SIZE_240};

  padding: 5px ${theme.sizes.SIZE_10};
  gap: ${theme.sizes.SIZE_8};

  color: var(--BLACK-00);
  text-decoration: none;

  border-radius: ${theme.sizes.SIZE_8};
  border: 1px solid var(--GRAY-98);

  background-color: var(--GRAY-EF);
`;

export { StyledCampaignChip };
