import { Dropdown, theme } from '@faxi/web-component-library';
import styled, { css } from 'styled-components';

const StyledCalendarField = styled(Dropdown)`
  .esg-calendar-field {
  }
`;

const PortalContainer = css`
  .wcl-calendar {
    --SECONDARY_1_1: var(--BLACK-00);

    padding: ${theme.sizes.SIZE_8};
    max-width: ${theme.sizes.SIZE_304};
  }
`;

export { PortalContainer,StyledCalendarField };
