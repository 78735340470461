import { Select } from '@faxi/web-component-library';
import InlineEditable from 'components/_molecules/InlineEditable';
import { DropdownDataModule, InlineModuleElement } from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type DropdownCanvasModuleProps = InlineModuleElement<DropdownDataModule>;

const DropdownCanvasModule: FC<DropdownCanvasModuleProps> = ({
  showIcon,
  ...rest
}) => (
  <StyledModuleContainer className="esg-dropdown-canvas-module">
    <InlineEditable
      data-no-dnd="true"
      placeholderText={'Click to enter/edit dropdown description'}
      {...rest}
    />
    {showIcon && <Select options={[]} disabled placeholder="Select Option" />}
  </StyledModuleContainer>
);

export default memo(DropdownCanvasModule);
