import { APP_URI } from 'config';
import { FC } from 'react';
import { RouteObject } from 'react-router-dom';

import { ModuleActionsProvider } from '../../../components/_organisms/BuilderCanvas/providers/ModuleActions.provider';
import Campaigns from '../../../pages/Campaigns';
import { Campaign, Topic } from '../../../pages/Campaigns/components';
import SubSubTopic from '../../../pages/Campaigns/components/SubSubTopic';
import SubTopic from '../../../pages/Campaigns/components/SubTopic';
import { CONDITIONAL_CONFIGURABLE_MODULES } from '../../../pages/Campaigns/configs/conditionalModules';
import { DndDataProvider } from '../../../providers/DndDataProvider';

const SubSubTopicWrapper: FC<{ level: 1 | 2 | 3 }> = (props) => {
  return (
    <DndDataProvider>
      <ModuleActionsProvider
        configurableModules={CONDITIONAL_CONFIGURABLE_MODULES}
      >
        <SubSubTopic level={props.level} />
      </ModuleActionsProvider>
    </DndDataProvider>
  );
};

export default [
  { index: true, element: <Campaigns /> },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN,
    element: <Campaign />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC,
    element: <Topic />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC,
    element: <SubTopic level={0} />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC,
    element: <SubSubTopicWrapper level={1} />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC,
    element: <SubSubTopicWrapper level={2} />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC_SUBSUBSUBSUBTOPICS_SUBSUBSUBSUBTOPIC,
    element: <SubSubTopicWrapper level={3} />,
  },
] as RouteObject[];
