import { UploadDataModule } from 'models';
import { FC, PropsWithChildren } from 'react';

import SelectModuleField from '../../../../../components/_fields/SelectModuleField';
import { BASE_CONFIGURABLE_MODULES } from '../../baseModules';
import BaseUploadFieldConfiguration from '../../baseModules/BaseUploadFieldConfiguration/BaseUploadFieldConfiguration.component';

export type ConditionalUploadFieldConfigurationProps =
  PropsWithChildren<UploadDataModule>;

const ConditionalUploadFieldConfiguration: FC<
  ConditionalUploadFieldConfigurationProps
> = (props) => {
  return (
    <BaseUploadFieldConfiguration
      {...props}
      renderModuleField={(fieldName) => (
        <SelectModuleField
          name={fieldName}
          label="If file is uploaded"
          parentId={props.id}
          configurableModules={BASE_CONFIGURABLE_MODULES}
          fullWidth
        />
      )}
    />
  );
};

export default ConditionalUploadFieldConfiguration;
