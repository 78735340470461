import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

const StyledSectionCanvasModule = styled(StyledModuleContainer)`
  min-height: 200px;
  ${flex('column', 'initial', 'stretch')};
  padding: ${theme.sizes.SIZE_10} 0;

  .esg-section-canvas-module {
    &__empty {
      ${flex('column', 'center', 'center')};
      gap: ${theme.sizes.SIZE_12};
      padding: ${theme.sizes.SIZE_20} 0;
      margin: auto;
      ${fontSize(theme.fontSizes.FONT_16)};
      text-align: center;

      svg {
        ${fontSize(theme.fontSizes.FONT_34)};
        color: var(--GRAY-C1);
      }
    }
  }
`;

export { StyledSectionCanvasModule };
