import { Input, InputProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import { FC } from 'react';

type ValueOnChange = (event: string) => void;
export type InputFieldProps = FieldProps<string, ValueOnChange> &
  InputProps & {
    unit?: string;
  };

const InputField: FC<InputFieldProps> = (props) => {
  const { error, dirty, touched, unit, required, ...rest } = props;
  const showError = useFieldError(props);

  return (
    <Input
      error={showError}
      errorText={String(error)}
      aria-invalid={showError}
      required={required}
      {...(required && {
        requiredLabel: 'Required',
      })}
      {...(unit && { suffixIcon: unit })}
      {...rest}
    />
  );
};

export default InputField;
