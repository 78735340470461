import styled from 'styled-components';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

const StyledTextCanvasModule = styled(StyledModuleContainer)`
  &.esg-text-canvas-module > div:has(.esg-inline-editable__input--is-editing) {
    width: 100%;
  }
`;

export { StyledTextCanvasModule };
