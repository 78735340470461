import { UniqueIdentifier } from '@dnd-kit/core';
import { DraggingModuleConfig } from 'models';
import { FC, PropsWithChildren, useRef, useState } from 'react';

import { DndDataContext } from './DndData.context';

const DndDataProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [activeId, setActiveId] = useState<UniqueIdentifier>();

  const [draggingModule, setDraggingModule] = useState<DraggingModuleConfig>();

  const droppedInDataModules = useRef(false);

  return (
    <DndDataContext.Provider
      value={{
        activeId,
        setActiveId,
        draggingModule,
        setDraggingModule,
        droppedInDataModules,
      }}
    >
      {children}
    </DndDataContext.Provider>
  );
};

export default DndDataProvider;
