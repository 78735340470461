import { APP_URI } from 'config';
import { Navigate, RouteObject } from 'react-router-dom';

import { ForgotPassword, Login, SignUp } from './pages';

export default [
  {
    path: APP_URI.AUTH_LOGIN,
    element: <Login />,
  },
  {
    path: APP_URI.AUTH_SIGN_UP,
    element: <SignUp />,
  },
  {
    path: APP_URI.AUTH_FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    index: true,
    element: <Navigate to={APP_URI.AUTH_LOGIN} replace />,
  },
] as RouteObject[];
