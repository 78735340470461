import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledManageUserModal = styled(Modal)`
  .wcl-modal {
    &__main {
      ${flex('column', 'flex-start', 'stretch')};

      > p {
        ${fontSize(theme.sizes.SIZE_20)};

        font-weight: 700;
        text-align: center;
      }

      > .esg-user-profile-image {
        align-self: center;
      }

      gap: ${theme.sizes.SIZE_32};
      padding-top: ${theme.sizes.SIZE_32};

      .wcl-select__option {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    &__header {
      display: none;
    }

    &__close-btn {
      display: none;
    }
  }

  .esg-manage-user-modal {
  }
`;

const StyledCustomOption = styled.div`
  ${flex('row', 'space-between', 'center')};

  width: 100%;
`;

export { StyledCustomOption, StyledManageUserModal };
