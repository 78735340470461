import { SessionsDataContextType } from 'models';
import { createContext } from 'react';

export default createContext<SessionsDataContextType>({
  sessions: [],
  search: '',
  addSession: () => Promise.resolve(),
  removeSession: () => {},
  runSession: () => {},
  setSearch: () => {},
  isLoading: false,
  isRunMutating: false,
  isAddSessionMutating: false,
});
