import { Dropdown } from '@faxi/web-component-library';
import { ChoiceDataModule, InlineModuleElement } from 'models';
import { FC, memo } from 'react';

import InlineEditable from '../../../../_molecules/InlineEditable';
import Icon from '../../../../Icon';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type ChoiceCanvasModuleProps = InlineModuleElement<ChoiceDataModule>;

const ChoiceCanvasModule: FC<ChoiceCanvasModuleProps> = ({
  showIcon,
  ...props
}) => {
  const { config, ...rest } = props;
  const { options } = config || {};

  return (
    <StyledModuleContainer className="esg-choice-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit choice label'}
        {...rest}
      />
      {showIcon &&
        (options?.length ? (
          <Dropdown
            data-no-dnd="true"
            renderAsPortal
            portalClassName="esg-canvas-module--dropdown"
            body={
              <ul className="esg-canvas-module--dropdown__ul">
                {options.map((option) => (
                  <li key={option.label?.toString()}>
                    <span className="esg-canvas-module--dropdown__ul__radio" />
                    <p>{option.label}</p>
                  </li>
                ))}
              </ul>
            }
            trigger={
              <p>
                {`(${options.length} elements)`} <Icon name="chevron-down" />
              </p>
            }
          />
        ) : (
          <p>(0 elements)</p>
        ))}
    </StyledModuleContainer>
  );
};

export default memo(ChoiceCanvasModule);
