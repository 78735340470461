import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledChoicePreview = styled.div`
  ${flex('column', 'space-between', 'flex-start')};
  gap: ${theme.sizes.SIZE_20};
  width: 100%;
  overflow: hidden;

  .esg-choice-preview {
    &__conditional-el {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_16};
      width: 100%;
    }
  }
`;

export { StyledChoicePreview };
