import {
  Button,
  Divider,
  Heading,
  ModalProps,
  ModalRef,
  StatusElement,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import { A4_FORMAT_HEIGHT, FEATURES, MM_TO_PX } from 'config';
import isEmpty from 'lodash.isempty';
import {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { generatePreviewFieldName, insertPageBreaks } from 'utils';

import useGetGampaignItem from '../../../../../../../../api/hooks/useGetCampaignItem';
import ModuleElement from '../../../../../../../../components/_organisms/BuilderCanvas/components/ModuleElement';
import Icon from '../../../../../../../../components/Icon';
import {
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigType,
} from '../../../../../../../../models';
import { useFormBuilder } from '../../../../../../context/FormBuilder';
import { StyledFormBuilderPreviewModal } from './FormBuilderPreviewModal.styled';
import { handleExportPDF } from './utils';

export type FormBuilderPreviewModalProps = PropsWithChildren<{}> & ModalProps;
export type ModuleWithConfig = IDataModule & {
  config: Record<string, any>;
};

const ELEMENTS_GAP = 32;
const PAGE_PADDING = 10;

const FormBuilderPreviewModal: ForwardRefRenderFunction<
  ModalRef,
  FormBuilderPreviewModalProps
> = (props, ref) => {
  const [modal, modalRef] = useCallbackRef<ModalRef>();
  useImperativeHandle(ref, () => modal, [modal]);
  const { modules } = useFormBuilder();

  const { showOverlay, hideOverlay } = useUtilities();

  const elementsWrapperRef = useRef<HTMLDivElement>(null);
  const isPreviewPageFormatted = useRef<boolean>(false);

  const initialPreviewElementRef = useRef<HTMLDivElement>(null);

  const {
    subsubtopic = '',
    subsubsubtopic = '',
    subsubsubsubtopic = '',
  } = useParams<{
    subsubtopic: string;
    subsubsubtopic: string;
    subsubsubsubtopic: string;
  }>();

  const { campaignItem } = useGetGampaignItem(
    subsubsubsubtopic || subsubsubtopic || subsubtopic
  );

  const { name = '', description = '' } = campaignItem || {};

  const initialFormData = useMemo(() => {
    /**
     * Generate a new array of all modules and their conditional elements (modules)
     */
    const generateArrayOfAllModulesWithConfigs = (
      modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
    ): ModuleConfig<ModuleConfigType, DataModuleEnum>[] => {
      return modules
        .filter(Boolean)
        .reduce(
          (acc: ModuleConfig<ModuleConfigType, DataModuleEnum>[], curr) => {
            if (curr.elements && curr.type === 'section') {
              return [
                ...acc,
                ...generateArrayOfAllModulesWithConfigs(curr.elements),
              ];
            }

            if (!isEmpty(curr.conditionalElements)) {
              return [...acc, curr, ...Object.values(curr.conditionalElements)];
            }

            return [...acc, curr];
          },
          []
        );
    };

    const allModules = generateArrayOfAllModulesWithConfigs(modules);

    return allModules.reduce((acc: Record<string, unknown>, module) => {
      const { type, id, config = {} } = module as ModuleWithConfig;

      switch (type) {
        case DataModuleEnum.CHOICE:
          acc[generatePreviewFieldName(id, DataModuleEnum.CHOICE)] =
            config.defaultValue || '';
          break;
        case DataModuleEnum.CHECKLIST:
          acc[generatePreviewFieldName(id, DataModuleEnum.CHECKLIST)] =
            (config.defaultValues || [])?.map(
              ({ value }: { value: string }) => value
            ) || [];
          break;
        case DataModuleEnum.INPUT:
          acc[generatePreviewFieldName(id, DataModuleEnum.INPUT)] =
            config.defaultValue;
          break;
        case DataModuleEnum.SWITCH:
          acc[generatePreviewFieldName(id, DataModuleEnum.SWITCH)] =
            config.defaultValue;
          break;
        case DataModuleEnum.DROPDOWN:
          acc[generatePreviewFieldName(id, DataModuleEnum.DROPDOWN)] =
            config.defaultValue;
          break;
        default:
          break;
      }
      return acc;
    }, {});
  }, [modules]);

  const handlePDFLoading = useCallback(
    (v: boolean) => (v ? showOverlay : hideOverlay)('.wcl-overlay__content'),
    [hideOverlay, showOverlay]
  );

  useEffect(() => {
    if (
      elementsWrapperRef.current &&
      initialPreviewElementRef.current &&
      !isPreviewPageFormatted.current
    ) {
      const INITIAL_PAGE_OFFSET = initialPreviewElementRef.current.clientHeight;

      if (elementsWrapperRef.current.children) {
        insertPageBreaks(
          elementsWrapperRef.current.children,
          A4_FORMAT_HEIGHT - 2 * PAGE_PADDING * MM_TO_PX,
          ELEMENTS_GAP,
          INITIAL_PAGE_OFFSET
        );

        isPreviewPageFormatted.current = true;
      }
    }
  });

  return (
    <StyledFormBuilderPreviewModal
      ref={modalRef}
      className="esg-form-builder-preview-modal"
      titleSize="big"
      onClose={() => (isPreviewPageFormatted.current = false)}
      hasCloseButton={false}
      icon={
        <Fragment>
          <div className="esg-form-builder-preview-modal__header-actions">
            {FEATURES.EXPORT_FORM_PREVIEW && (
              <Button
                onClick={() =>
                  handleExportPDF(
                    elementsWrapperRef,
                    initialPreviewElementRef,
                    handlePDFLoading,
                    PAGE_PADDING,
                    ELEMENTS_GAP
                  )
                }
                icon={<Icon name="file-pdf" />}
                variant="delete-outline"
                iconPosition="right"
              >
                Export to PDF
              </Button>
            )}
            <Button
              onClick={() => modal.close()}
              icon={<Icon name="xmark" />}
              iconPosition="right"
              variant="outline"
            >
              Exit Preview
            </Button>
          </div>
        </Fragment>
      }
      {...props}
    >
      <div ref={initialPreviewElementRef}>
        <header className="esg-form-builder-preview-modal__header">
          <Icon name="file" /> <Heading level="1">{name}</Heading>
          <StatusElement status="active">
            <Icon name="magnifying-glass-chart" />
            <small>Data Collection</small>
          </StatusElement>
        </header>
        {description && (
          <p className="esg-form-builder-preview-modal__subheader">
            {description}
          </p>
        )}
        <Divider />
      </div>

      {modules.length === 0 ? (
        <div className="esg-form-builder-preview-modal__no-data">
          <p>No form modules have been selected.</p>
          <div className="esg-form-builder-preview-modal__actions">
            <Button onClick={() => modal.close()}>Close</Button>
          </div>
        </div>
      ) : (
        <Form initialData={initialFormData} onSubmit={async () => {}}>
          <div
            ref={elementsWrapperRef}
            className="esg-form-builder-preview-modal__content"
          >
            {modules.map((module) => (
              <ModuleElement
                modulesType="preview-module"
                type={module.type}
                module={module}
                key={module.id}
                moduleElement={ModuleElement}
                elements={module.elements}
              />
            ))}
          </div>

          <div className="esg-form-builder-preview-modal__actions">
            <Button disabled type="submit">
              Submit
            </Button>
          </div>
        </Form>
      )}
    </StyledFormBuilderPreviewModal>
  );
};

export default forwardRef(FormBuilderPreviewModal);
