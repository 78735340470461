import { Checkbox } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { FC, memo,PropsWithChildren } from 'react';

import { StyledCheckboxIconLabel } from './CheckboxIconLabel.styled';

export type CheckboxIconLabelProps = PropsWithChildren<{
  checked?: boolean;
  onCheck?: () => void;
  name: string;
}>;

const CheckboxIconLabel: FC<CheckboxIconLabelProps> = ({
  onCheck,
  checked,
  name,
}) => (
  <StyledCheckboxIconLabel className="esg-checkbox-icon-label">
    {onCheck && <Checkbox checked={checked} onChange={onCheck} />}
    <Icon name="shield" />
    <p>{name}</p>
  </StyledCheckboxIconLabel>
);

export default memo(CheckboxIconLabel);
