import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseIntegrationFieldConfiguration = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_40};

  .wcl-select {
    max-width: ${theme.sizes.SIZE_240};
  }
`;

export { StyledBaseIntegrationFieldConfiguration };
