import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledApprovalChip = styled.div`
  ${flex('row', 'center', 'center')};

  gap: ${theme.sizes.SIZE_12};

  padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};

  border: 1px solid var(--GRAY-C1);

  ${fontSize(theme.fontSizes.FONT_14)};
  font-weight: 400;

  svg {
    ${size('18px')}
  }

  .esg-approval-chip {
    &__label {
      text-align: center;
    }
  }
`;

export { StyledApprovalChip };
