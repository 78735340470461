import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledErrorBoundary = styled.div`
  ${flex('row', 'center', 'center')};
  ${size('100vw', '100vh')};

  .esg-error-boundary {
    &__animation {
      max-width: 500px;
      max-height: 500px;
    }
  }
`;

export { StyledErrorBoundary };
