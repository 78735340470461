import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import { create } from 'zustand';

import storageService from '../services/storageService';

type ModuleState = {
  localFormModules: ModuleConfig<ModuleConfigType, DataModuleEnum>[];
  setLocalFormModules: (
    modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  ) => void;
  clearCurrentModules: () => void;
};

const modulesStore = create<ModuleState>((set) => ({
  localFormModules: [],
  setLocalFormModules: (modules) => {
    set({ localFormModules: modules });
    storageService.setItem('MODULES', modules);
  },
  clearCurrentModules: () => {
    set({ localFormModules: [] });
    storageService.removeItem('MODULES');
  },
}));

export const useModulesStore = modulesStore;
