import { PermissionSections, UserRoleForm } from 'models';

export const USER_ROLE_FORM_DATA: UserRoleForm = {
  [PermissionSections.ORGANISATION]: {
    icon: 'leaf',
    label: PermissionSections.ORGANISATION,
    actions: {
      read: {
        label: 'Read Organisations and Companies',
        name: 'read_organisations',
        excludes: [
          'create_organisations',
          'edit_organisations',
          'delete_organisations',
        ],
        includes: [],
      },
      create: {
        label: 'Create Organisations and Companies',
        name: 'create_organisations',
        excludes: [],
        includes: ['read_organisations'],
      },
      edit: {
        label: 'Edit Organisations and Companies',
        name: 'edit_organisations',
        excludes: [],
        includes: ['read_organisations'],
      },
      delete: {
        label: 'Delete Organisations and Companies',
        name: 'delete_organisations',
        excludes: [],
        includes: ['read_organisations'],
      },
    },
  },
  [PermissionSections.USER]: {
    icon: 'users',
    label: PermissionSections.USER,
    actions: {
      read: {
        label: 'Read Users',
        name: 'read_users',
        excludes: ['edit_users', 'delete_users', 'create_users'],
        includes: [],
      },
      create: {
        label: 'Create Users',
        name: 'create_users',
        excludes: [],
        includes: ['read_users'],
      },
      edit: {
        label: 'Edit Users',
        name: 'edit_users',
        excludes: [],
        includes: ['read_users'],
      },
      delete: {
        label: 'Delete Users',
        name: 'delete_users',
        excludes: [],
        includes: ['read_users'],
      },
      'assign_roles': {
        label: 'Assign Roles to users',
        name: 'assign_roles',
        excludes: [],
        includes: [],
      },
      'assign_users': {
        label: 'Assign Users to Topics / Forms / Sections',
        name: 'assign_users',
        excludes: [],
        includes: [],
      },
    },
  },
  [PermissionSections.CAMPAIGN]: {
    icon: 'leaf',
    label: PermissionSections.CAMPAIGN,
    actions: undefined,
  },
  [PermissionSections.SESSION]: {
    icon: 'chart-bar',
    label: PermissionSections.SESSION,
    actions: undefined,
  },
  [PermissionSections.ROLE]: {
    icon: 'users',
    label: PermissionSections.ROLE,
    actions: undefined,
  },
};
