import { Image, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledImageOption = styled(Image)`
  ${size('24px')};
  border-radius: 50%;
`;

const StyledAssignContributors = styled.div`
  .esg-assign-contributors {
    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_24};

      strong {
        font-weight: 600;
      }
    }

    &__assignees {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_12};

      &__subtitle {
        margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_4};
        ${fontSize(theme.fontSizes.FONT_14)};
      }
    }
  }
`;

export { StyledAssignContributors, StyledImageOption };
