import { useCallbackRef } from '@faxi/web-component-library';
import { FormField, useFormContextValues } from '@faxi/web-form';
import isEmpty from 'lodash.isempty';
import { DataModuleEnum, UploadDataModule } from 'models';
import { FC, useMemo } from 'react';
import { generatePreviewFieldName } from 'utils';

import { useHeightAnimation } from '../../../../../../hooks';
import { FileInput } from '../../../../../_atoms';
import {
  StyledConditionalElement,
  StyledUploadPreview,
} from './UploadPreview.styled';

export type UploadPreviewProps = UploadDataModule;

const UploadPreview: FC<UploadPreviewProps> = ({
  title,
  id,
  conditionalElements,
  moduleElement: ModuleElement,
}) => {
  const name = generatePreviewFieldName(id, DataModuleEnum.UPLOAD);
  const formValue = useFormContextValues(name)?.[name];

  const module = useMemo(
    () => !isEmpty(formValue) && conditionalElements?.['file-is-uploaded'],
    [conditionalElements, formValue]
  );

  const [uploadPreviewField, uploadPreviewFieldRef] =
    useCallbackRef<HTMLDivElement>();

  useHeightAnimation({
    element: uploadPreviewField,
    isOpen: !!module,
  });

  return (
    <>
      <StyledUploadPreview className="esg-upload-preview">
        <p className="esg-upload-preview__title">{title || 'Upload'}</p>
        <FormField
          name={name}
          component={FileInput}
          className="esg-upload-preview__file-input"
        />
      </StyledUploadPreview>

      <StyledConditionalElement ref={uploadPreviewFieldRef}>
        {module && ModuleElement && (
          <ModuleElement
            modulesType="preview-module"
            type={module.type}
            module={module}
          />
        )}
      </StyledConditionalElement>
    </>
  );
};

export default UploadPreview;
