import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import { BaseLayout } from '../../layouts';

const StyledUserProfile = styled(BaseLayout)`
  .esg-collapsible-side-panel--wrapper {
    height: 100%;
    max-width: ${theme.sizes.SIZE_304};

    nav {
      ${flex('column', 'flex-start', 'stretch')};

      gap: ${theme.sizes.SIZE_12};

      > a {
        padding: ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_8};
        color: var(--BLACK-00);
        text-decoration: none;
        border-radius: 6px;
        background-color: var(--WHITE-FF);

        &.active {
          background-color: var(--GRAY-EF);
        }
      }
    }
  }
  .esg-user-profile {
    &__user-profile-container {
      padding: ${theme.sizes.SIZE_32};
      width: 100%;
      overflow: auto;
      > div {
        > h2 {
          ${fontSize('28px', '40px')};

          font-weight: 600;
          margin: 0 0 ${theme.sizes.SIZE_48};
        }
      }
    }
  }
`;

export { StyledUserProfile };
