import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledNotificationsGrid = styled.div`
  .esg-notifications-grid {
    &__container {
      display: grid;
      grid-template-columns:
        minmax(${theme.sizes.SIZE_288}, ${theme.sizes.SIZE_320}) minmax(
          ${theme.sizes.SIZE_164},
          ${theme.sizes.SIZE_192}
        )
        minmax(${theme.sizes.SIZE_248}, ${theme.sizes.SIZE_248})
        minmax(${theme.sizes.SIZE_288}, ${theme.sizes.SIZE_320});
      align-items: center;
      gap: ${theme.sizes.SIZE_80};
      width: fit-content;
      padding: ${theme.sizes.SIZE_16};
      padding-top: ${theme.sizes.SIZE_16};
      border-bottom: solid 1px var(--GRAY-EF);
      ${fontSize(theme.fontSizes.FONT_16)};
      font-weight: 400;

      div {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_12};
        .esg-approval-chip {
          margin-left: 20%;
          width: ${theme.sizes.SIZE_208};
          justify-content: center;
        }
        span {
          margin: unset;
          color: unset;
        }
      }

      &__column {
        &__icon {
          ${size(theme.sizes.SIZE_24)};
        }
      }

      div:first-child {
        ${fontSize(theme.fontSizes.FONT_16)};
        img {
          ${size(theme.sizes.SIZE_32, theme.sizes.SIZE_32)};
        }
        .esg-basic-tag {
          ${fontSize(theme.fontSizes.FONT_14)};
          padding: 6px ${theme.sizes.SIZE_8};
        }
      }

      .esg-basic-tag {
        width: fit-content;
      }
      .esg-approval-chip {
        ${fontSize(theme.fontSizes.FONT_16)};
      }
    }
  }
`;

export { StyledNotificationsGrid };
