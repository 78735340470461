import { Breadcrumbs, Heading, Image } from '@faxi/web-component-library';
import { ApprovalProgressBar, BasicTreeView } from 'components';
import { FORM_STATUS } from 'models';
import { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { StyledSessionDashboardCompany } from './SessionDashboardCompany.styled';
import { COMPANY_TREE_NODE_DATA } from './treeViewData';

export type SessionDashboardCompanyProps = PropsWithChildren<{}>;
type SessionCompanyPageParams = { company: string; sessionId: string };

const SessionDashboardCompany: FC<SessionDashboardCompanyProps> = (props) => {
  const { company, sessionId } = useParams<SessionCompanyPageParams>();

  return (
    <StyledSessionDashboardCompany className="esg-session-dashboard-company">
      <Breadcrumbs
        className="esg-session-dashboard-company__breadcrumbs"
        splitter="/"
        crumbs={[
          { text: 'Dashboard', href: `/sessions/${sessionId}/dash` },
          { text: company!, href: '' },
        ]}
      />

      <div className="esg-session-dashboard-company__header">
        <div className="esg-session-dashboard-company__header__text">
          <Image src="/assets/images/kinto-join.png" alt="Kinto Join logo" />
          <Heading level="1">{company}</Heading>
        </div>

        <div className="esg-session-dashboard-company__header__progress">
          <ApprovalProgressBar
            title="Progress"
            progress={50}
            status={FORM_STATUS.COMPLETED}
          />
          <ApprovalProgressBar
            title="Approval"
            progress={50}
            status={FORM_STATUS.IN_PROGRESS}
          />
        </div>
      </div>

      <div className="esg-session-dashboard-company__subheaders">
        <small>Section</small>
        <small>Contributors</small>
        <small>Status</small>
      </div>
      <BasicTreeView data={COMPANY_TREE_NODE_DATA} />
    </StyledSessionDashboardCompany>
  );
};

export default SessionDashboardCompany;
