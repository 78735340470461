import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledConditionalChoiceFieldConfiguration = styled.div`
  .esg-choice-field-configuration {
    &__select-module {
      ${flex('row', 'flex-end', 'center')};
      gap: ${theme.sizes.SIZE_12};
      width: fit-content;
      flex-grow: 1;
    }
  }
`;

export { StyledConditionalChoiceFieldConfiguration };
