import { TextDataModule } from 'models';
import { FC } from 'react';

import { StyledTextPreview } from './TextPreview.styled';

export type TextPreviewProps = TextDataModule;

const TextPreview: FC<TextPreviewProps> = ({
  title,
  config: { textFormat } = {},
}) => (
  <StyledTextPreview className={`esg-text-preview--${textFormat}`}>
    {title}
  </StyledTextPreview>
);

export default TextPreview;
