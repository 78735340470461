import classNames from 'classnames';
import { FC } from 'react';

import { StyledBuilderTools } from './BuilderTools.styled';
import DataModulesTool from './components/DataModulesTool';
import PreviewTool from './components/PreviewTool';

const BuilderTools: FC<{ className?: string; items: string[] }> = ({
  className,
  items,
}) => {
  return (
    <StyledBuilderTools className={classNames('esg-builder-tools', className)}>
      <PreviewTool />
      <div className="esg-builder-tools__divider" />
      <DataModulesTool items={items} />
    </StyledBuilderTools>
  );
};

export default BuilderTools;
