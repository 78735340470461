import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled from 'styled-components';

const StyledUserRoles = styled(BaseLayout)`
  .esg-collapsible-side-panel--wrapper {
    ${flex('column', 'space-between', 'stretch')};
    height: 100%;
    max-width: ${theme.sizes.SIZE_304};

    .esg-collapsible-side-panel {
      > button {
        width: 100%;
        margin-bottom: ${theme.sizes.SIZE_32};
      }
    }
  }
  .esg-user-roles {
    &__container {
      ${flex('column')};
      ${size('100%')};
      overflow: auto;
      padding: ${theme.sizes.SIZE_32};
    }

    &__nav {
      ${flex('column', 'flex-start', 'stretch')};

      gap: ${theme.sizes.SIZE_12};

      &__link {
        ${flex('row', 'space-between', 'center')};
        ${fontSize('14px', '20px')};
        flex: 1;
        text-decoration: none;
        color: var(--BLACK--00);
        padding: 0 ${theme.sizes.SIZE_8};
        border-radius: '6px';
        cursor: pointer;

        > div:first-of-type {
          ${flex('row', 'flex-start', 'center')};

          gap: ${theme.sizes.SIZE_12};
        }

        &--active {
          background-color: var(--GRAY-EF);
        }
      }
    }
  }
`;

export { StyledUserRoles };
