const equalField =
  (message: string, fieldName: string) =>
  (field: any, fields: Record<string, any>) => {
    const otherField = fields[fieldName];

    if (field === otherField.value) return undefined;
    else return message;
  };

export { equalField };
