import { EmptyFolder } from 'components';
import Icon from 'components/Icon';
import { Each } from 'helpers';
import { TreeNodeElement } from 'models';
import { FC, MouseEvent, PropsWithChildren } from 'react';

import SectionCard from '../SectionCard';
import { StyledFoldersPreview } from './FoldersPreview.styled';

export type FoldersView = 'list' | 'grid';

export type FoldersPreviewProps = PropsWithChildren<{
  folder?: TreeNodeElement;
  onSelect?: (folder: TreeNodeElement) => void;
  onEdit?: (folder: TreeNodeElement, event: MouseEvent) => void;
  onDelete?: (folder: TreeNodeElement, event: MouseEvent) => void;
  view?: FoldersView;
}>;

const FoldersPreview: FC<FoldersPreviewProps> = (props) => {
  const { folder, onSelect, onEdit, onDelete, view = 'grid' } = props;

  if (!folder?.children?.length) return <EmptyFolder />;

  return (
    <StyledFoldersPreview className="esg-folders-preview">
      <Each
        containerAs="div"
        className="esg-folders-preview__container"
        data-view={view}
        of={folder.children || []}
        render={(folder) => (
          <SectionCard
            view={view}
            withMenu
            title={folder.name!}
            description={folder.description}
            prefixElement={<Icon name="building" />}
            onSelect={() => onSelect?.(folder)}
            onEdit={(e) => onEdit?.(folder, e)}
            onDelete={(e) => onDelete?.(folder, e)}
            onAddDescriptionCallback={(e) => onEdit?.(folder, e)}
          />
        )}
      />
    </StyledFoldersPreview>
  );
};

export default FoldersPreview;
