import { AxiosError } from 'axios';

import authBus from '../modules/authBus';

function getStringError(error: AxiosError<any>): string {
  if (!error?.response?.data) return "Couldn't parse error";

  let {
    response: {
      data: { message },
    },
  } = error;

  if (!message) return "Couldn't parse error";

  message =
    typeof message === 'string'
      ? message
      : typeof message.errors[0].message === 'string'
        ? message.errors[0].message
        : 'Invalid data';
  return message;
}

function snackBarSuccessMessage(message: string): void {
  authBus.broadcastEvent('show_snackbar', {
    props: {
      variant: 'success',
      actionButtonText: 'Dismiss',
      text: message,
    },
    config: {
      disappearAfter: 5000,
    },
  });
}

function snackBarErrorMessage(error: AxiosError | string): void {
  authBus.broadcastEvent('show_snackbar', {
    props: {
      variant: 'error',
      actionButtonText: 'Dismiss',
      text: typeof error === 'string' ? error : getStringError(error),
    },
    config: {
      disappearAfter: 5000,
    },
  });
}

export { snackBarErrorMessage, snackBarSuccessMessage };
