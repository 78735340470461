import { getColor, Tooltip } from '@faxi/web-component-library';
import { FieldProps, FormField } from '@faxi/web-form';
import Icon from 'components/Icon';
import {
  DataModuleEnum,
  InputDataModule,
  InputFieldTypes,
  InputPreviewType,
} from 'models';
import { FC, useMemo } from 'react';
import { generatePreviewFieldName } from 'utils';

import { DateField, EmailField, NumericField, SimpleField } from './components';
import { StyledInputPreview } from './InputPreview.styled';

const FIELD_PROPS_KEYS = [
  'dirty',
  'error',
  'onBlur',
  'onChange',
  'touched',
  'value',
] as (keyof FieldProps)[];

type InputPreviewModule = Partial<{
  [K in InputFieldTypes]: FC<InputDataModule>;
}>;

export const fieldComponents: InputPreviewModule = {
  [InputFieldTypes.SIMPLE_FIELD]: SimpleField,
  [InputFieldTypes.NUMERIC_FIELD]: NumericField,
  [InputFieldTypes.DATE_FIELD]: DateField,
  [InputFieldTypes.EMAIL_FIELD]: EmailField,
};

const InputPreview: FC<InputPreviewType> = (props) => {
  const {
    renderFormField = true,
    config = { type: InputFieldTypes.SIMPLE_FIELD },
    ...rest
  } = props;

  const FieldComponent = useMemo(
    () =>
      fieldComponents[config.type] as FC<
        FieldProps<any> & Partial<InputPreviewType>
      >,
    [config]
  );

  const fieldProps = useMemo(
    () =>
      Object.entries(rest).reduce(
        (props, [key, value]) =>
          FIELD_PROPS_KEYS.includes(key as keyof FieldProps)
            ? { ...props, [key]: value }
            : props,
        {} as Record<keyof FieldProps, any>
      ),
    [rest]
  );

  const component = useMemo(
    () =>
      renderFormField ? (
        <FormField
          {...fieldProps}
          name={generatePreviewFieldName(rest.id, DataModuleEnum.INPUT)}
          component={FieldComponent}
          config={config}
        />
      ) : (
        <FieldComponent config={config} {...rest} />
      ),
    [FieldComponent, config, renderFormField, rest, fieldProps]
  );

  return (
    <StyledInputPreview className="esg-input-preview">
      <div className="esg-input-preview__title">
        <span>{props.title || 'Title of component'}</span>
        {config?.helperText && (
          <Tooltip content={config.helperText}>
            <span
              className="esg-input-preview__tooltip"
              tabIndex={0}
              role="tooltip"
              aria-label={config.helperText}
            >
              <Icon name="circle-info" color={getColor('--GRAY-40')} />
            </span>
          </Tooltip>
        )}
      </div>

      {config?.type && component}
    </StyledInputPreview>
  );
};

export default InputPreview;
