import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledFolderDescription = styled.div`
  max-width: 100%;

  .esg-overflow-tooltip-text {
    max-height: ${theme.sizes.SIZE_48};

    > button {
      padding: 8px !important;
    }
  }
`;

export { StyledFolderDescription };
