import { css } from 'styled-components';

const snackbarsStyles = css`
  .snack-bar {
    &--error .button--ghost:hover {
      background-color: var(--RED-D5);
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export default snackbarsStyles;
