import { FC } from 'react';

import { BlockUI } from '../../../../helpers';
import useSessionDashboardGridCards from '../../hooks/useSessionDashboardGridCards';
import { SessionDashboardCard } from './components';
import { StyledSessionDashboard } from './SessionDashboard.styled';

export type SessionDashboardProps = {};

const SessionDashboard: FC<SessionDashboardProps> = () => {
  const { data: sessionDashboardData, isLoading } =
    useSessionDashboardGridCards();

  return (
    <StyledSessionDashboard
      title="Organisations"
      className="esg-session-dashboard"
      direction="column"
    >
      <BlockUI loading={isLoading} fallbackCondition={!sessionDashboardData}>
        <div className="esg-session-dashboard__content">
          {sessionDashboardData.map((data) => (
            <SessionDashboardCard key={data.name} {...data} />
          ))}
        </div>
      </BlockUI>
    </StyledSessionDashboard>
  );
};

export default SessionDashboard;
