import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSearchTreeView = styled.div`
  ${size('100%', '100%')};

  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_16};
  resize: horizontal;
  overflow: auto;
  border-right: 1px solid var(--GRAY-C1);

  .input__container {
    margin-bottom: ${theme.sizes.SIZE_8};
  }
`;

export { StyledSearchTreeView };
