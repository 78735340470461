import { PermissionSections } from 'models';
import { createContext } from 'react';

export type UserContextProps = {
  user?: {
    permissions: Record<PermissionSections, Array<string>>;
    firstName: string;
    lastName: string;
    userRouterPermissions: PermissionSections[];
    id: string;
    email: string;
  };
  logoutUser: () => Promise<void>;
};

export default createContext<UserContextProps>({
  logoutUser: () => new Promise(() => {}),
});
