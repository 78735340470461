import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledTextPreview = styled.div`
  ${fontSize(theme.sizes.SIZE_16)}
  word-break: break-word;

  &.esg-text-preview {
    &--heading {
      ${fontSize(theme.sizes.SIZE_32)}
      font-weight: 700;
    }
    &--title {
      ${fontSize(theme.sizes.SIZE_24)}
      font-weight: 600;
    }
    &--subtitle {
      ${fontSize(theme.sizes.SIZE_20)}
      font-weight: 500;
    }
    &--body {
      ${fontSize(theme.sizes.SIZE_16)}
    }
  }
`;

export { StyledTextPreview };
