import { MouseEvent } from 'react';

export const copyToClipboard =
  (successCallback: Function) =>
  async (event: MouseEvent<HTMLElement>, text: string) => {
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(text);

      successCallback();
    } catch (e) {
      console.error(e);
    }
  };
