import {
  Button,
  Select,
  SelectOption,
  useUtilities,
} from '@faxi/web-component-library';
import api from 'api';
import useGetCampaigns from 'api/hooks/useGetCampaigns';
import { CheckboxTreeView, CompanyManagement, EmptyFolder } from 'components';
import Icon from 'components/Icon';
import { BlockUI } from 'helpers';
import cloneDeep from 'lodash.clonedeep';
import { createTreeWithParentReferences } from 'mock/treeViewData';
import { CampaignItem, TreeNodeElement } from 'models';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API_ROUTES } from '../../../../../api/routes';
import { toggleCheckByIds } from '../../../../../components/_organisms/CheckboxTreeView/utils';
import { useOrganisationProvider } from '../../providers/Organisation';
import { StyledCompany } from './Company.styled';
import {
  compareArrays,
  findCheckedNodes,
  mapCampaignToTreeNode,
  mapTreeCheckedToArray,
} from './utils';

const Company: FC = () => {
  const { company } = useParams<{ company: string }>();

  const [campaign, setCampaign] = useState<SelectOption>();
  const [campaignTree, setCampaignTree] = useState<TreeNodeElement>();
  const [isTreeChanged, setIsTreeChanged] = useState(false);

  const initialCampaignTree = useRef<TreeNodeElement>();

  const removeBtnRef = useRef<HTMLButtonElement>(null);

  const { prompts, showSnackBar, showOverlay, hideOverlay } = useUtilities();
  const { activeOrganisation } = useOrganisationProvider();

  const { request: detachCampaign } = api.useMutation(
    API_ROUTES.COMPANIES.COMPANY_CAMPAIGN(company as string),
    {
      onSuccess: () => {
        initialCampaignTree.current = undefined;
        setCampaign(undefined);
        setCampaignTree(undefined);
        showSnackBar({
          variant: 'success',
          text: `${campaign?.label} has been detached.`,
          actionButtonText: 'Dismiss',
        });
      },
      onError: () => {
        showSnackBar({
          variant: 'error',
          text: `An error occurred while detaching ${campaign?.label}.`,
          actionButtonText: 'Dismiss',
        });
      },
    }
  );

  const { campaigns, isLoading: loadingCampaigns } = useGetCampaigns();

  const {
    data: { data: companyCampaign } = {},
    isLoading: loadingCompany,
    mutate: companyCampaignMutate,
  } = api.useCompanyCampaign(company!);

  const { isLoading: loadingTree, campaign: campaignTreeData } =
    api.useGetCampaignTree(campaign?.value, !!campaign?.value);

  const loading = loadingTree || loadingCompany;

  const { request: attachCampaign } = api.useMutation(
    API_ROUTES.COMPANIES.COMPANY_CAMPAIGNS,
    {
      onSuccess: () => {
        initialCampaignTree.current = cloneDeep(campaignTree);
        setIsTreeChanged(false);
        showSnackBar({
          variant: 'success',
          text: `${campaign?.label} has been attached to ${activeOrganisation?.name}.`,
          actionButtonText: 'Dismiss',
        });
      },
      onError: (error) => {
        console.error('Error attaching campaign:', error);
        showSnackBar({
          variant: 'error',
          text: 'Failed to attach campaign. Please try again.',
          actionButtonText: 'Dismiss',
        });
      },
    }
  );

  const selectOptions = useMemo(
    () =>
      campaigns?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) as SelectOption<string>[],
    [campaigns]
  );

  const handleDelete = useCallback(async () => {
    const proceed = await prompts.delete({
      type: 'delete',
      title: `Remove ${campaign?.label}`,
      content: `Are you sure you want to remove ${campaign?.label} from ${activeOrganisation?.name}?`,
      submitBtnText: 'Remove',
      cancelBtnText: 'Do not remove',
      btnIcon: 'trash-can',
      iconPosition: 'left',
      submitBtnVariant: 'delete-ghost',
      triggerRef: removeBtnRef.current as HTMLButtonElement,
      titleIcon: <Icon name="triangle-exclamation" />,
    });

    if (!proceed) return;
    showOverlay('body');
    try {
      await detachCampaign('DELETE', {});
      companyCampaignMutate();
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('body');
    }
  }, [
    prompts,
    campaign,
    activeOrganisation,
    detachCampaign,
    showOverlay,
    hideOverlay,
    companyCampaignMutate,
  ]);

  const handleSaveChanges = useCallback(async () => {
    const checkedNodes = findCheckedNodes(campaignTree);

    if (!checkedNodes.length) {
      await handleDelete();
      return;
    }

    showOverlay('body');
    try {
      await attachCampaign('POST', {
        data: {
          organisationId: activeOrganisation?.id,
          campaignId: campaign?.value,
          selectedElementsIds: checkedNodes,
        },
      });
      companyCampaignMutate();
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('body');
    }
  }, [
    campaignTree,
    showOverlay,
    attachCampaign,
    campaign?.value,
    handleDelete,
    activeOrganisation?.id,
    hideOverlay,
    companyCampaignMutate,
  ]);

  useEffect(() => {
    const campaignTreeWithParentReferences =
      campaignTreeData &&
      createTreeWithParentReferences(
        mapCampaignToTreeNode(campaignTreeData as CampaignItem),
        null
      );

    if (campaignTreeWithParentReferences) {
      let campaignTreeWithCheckedNodes = campaignTreeWithParentReferences;

      if (companyCampaign?.campaign) {
        campaignTreeWithCheckedNodes = toggleCheckByIds(
          campaignTreeWithParentReferences,
          companyCampaign.campaign.dataCollectionElements.map((el) => el.id)
        );
      }

      setCampaignTree(campaignTreeWithCheckedNodes);
      initialCampaignTree.current = cloneDeep(campaignTreeWithCheckedNodes);
    }
  }, [campaignTreeData, companyCampaign]);

  useEffect(() => {
    setCampaign(
      companyCampaign?.campaign && {
        value: companyCampaign.campaign.id,
        label: companyCampaign.campaign.name,
      }
    );

    if (!companyCampaign?.campaign) {
      setCampaignTree(undefined);
    }
  }, [companyCampaign, company]);

  return (
    <StyledCompany className="esg-company">
      <div className="esg-company__campaign">
        <BlockUI loading={loading}>
          {!campaign ? (
            <Select
              async
              value={campaign}
              loading={loadingCampaigns}
              className="esg-company__select"
              placeholder="Attach campaign"
              options={selectOptions}
              onChange={setCampaign}
            />
          ) : (
            <div className="esg-company__selected-campaign">
              <p className="esg-company__selected-campaign__title">Campaign</p>
              <div className="esg-company__selected-campaign__discard">
                <Icon name="leaf" />
                <p>{campaign.label}</p>
                <Button
                  ref={removeBtnRef}
                  variant="ghost"
                  icon={<Icon name="xmark" />}
                  onClick={handleDelete}
                />
              </div>
            </div>
          )}

          {campaignTree ? (
            <CheckboxTreeView
              filteredData={campaignTree}
              onCheck={() => {
                const initialTreeCheckedStates = mapTreeCheckedToArray(
                  initialCampaignTree.current!
                );
                const treeCheckedStates = mapTreeCheckedToArray(campaignTree);

                setIsTreeChanged(
                  !compareArrays(initialTreeCheckedStates, treeCheckedStates)
                );

                setCampaignTree(cloneDeep(campaignTree));
              }}
            />
          ) : (
            <div className="esg-company__campaign__no-data">
              <EmptyFolder title="No attached campaigns." iconName="file" />
            </div>
          )}

          {campaign && (
            <Button
              variant="outline"
              onClick={handleSaveChanges}
              disabled={!isTreeChanged}
            >
              Save
            </Button>
          )}
        </BlockUI>
      </div>

      <div className="esg-company__vertical" />

      <CompanyManagement className="esg-company__company-management" />
    </StyledCompany>
  );
};

export default Company;
