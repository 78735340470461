import { Organisation, TreeNodeElement } from 'models';
import { createContext } from 'react';
import { KeyedMutator } from 'swr';

export type OrganisationContextProps = {
  loading: boolean;
  tree?: TreeNodeElement;
  activeOrganisation?: Organisation;
  mutateOrganisations: KeyedMutator<Organisation>;
  deleteOrganisation: (
    org: Organisation,
    trigger?: HTMLButtonElement,
    callback?: () => void
  ) => Promise<void>;
};

export default createContext<OrganisationContextProps>({
  tree: { id: '', name: '' },
  loading: false,
  activeOrganisation: undefined,
  deleteOrganisation: () => new Promise(() => {}),
  mutateOrganisations: (() => {}) as KeyedMutator<Organisation>,
});
