import env from 'env';

const apiVersion = env.VITE_API_VERSION;

export const USERS = {
  LOGIN: `/users/${apiVersion}/auth`,
  NEW_PASSWORD: `/users/${apiVersion}/password-challenge`,
  USER: `/users/${apiVersion}`,
  USER_ID: (id: string) => `/users/${apiVersion}/${id}`,
  LOGOUT: `/users/${apiVersion}/sign-out`,
  ROLES: `/users/${apiVersion}/roles`,
  REFRESH_TOKEN: `/users/${apiVersion}/refresh-token`,
  PROFILE: `/users/${apiVersion}/profile`,
};
