import { InputDataModule, InputModuleConfig } from 'models';
import { FC } from 'react';

import QuickConfigPreview from '../QuickConfigPreview';
import { SimpleInputField } from '../SimpleInput/SimpleInput.component';
import { StyledNumericInput } from './NumericInput.styled';

export type NumericInputProps = InputDataModule;

const NumericInput: FC<NumericInputProps> = (props) => {
  return (
    <StyledNumericInput className="esg-numeric-input">
      <QuickConfigPreview {...props} />

      <SimpleInputField
        switchLabel="Tooltip helper text"
        configKey="helperText"
        placeholder="Helper text"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="Custom placeholder"
        configKey="placeholder"
        placeholder="Placeholder"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        className="esg-numeric-input__unit"
        switchLabel="Numeric unit"
        configKey="unit"
        placeholder="Unit"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="XBRL Tag"
        configKey="xbrl"
        placeholder="XBRL"
        config={props.config as InputModuleConfig}
      />
    </StyledNumericInput>
  );
};

export default NumericInput;
