import {
  ButtonProps,
  IName,
  StatusElement,
  StatusElementProps,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { FolderDescription, ThreeDotMenu } from 'components';
import { useDefaultCardMenu } from 'hooks';
import { FC, memo, ReactNode, useMemo } from 'react';
import { EventCardType } from 'types';

import { LayoutPreview } from '../../../models';
import Icon, { INameExtended } from '../../Icon';
import { StyledSectionCard } from './SectionCard.styled';

export type SectionCardProps = {
  className?: string;
  prefixElement?: ReactNode;
  status?: StatusElementProps<INameExtended>;
  title: string;
  type?: string;
  description?: string;
  view: LayoutPreview;
  withMenu?: boolean;
  menuItems?: ButtonProps[];
  hasActions?: boolean;
  onSelect?: () => void;
  onEdit?: EventCardType;
  onDelete?: EventCardType;
  onDuplicate?: EventCardType;
  onAddDescriptionCallback?: EventCardType;
};

const SectionCard: FC<SectionCardProps> = (props) => {
  const {
    prefixElement = <Icon name="building" />,
    title,
    description,
    status,
    view,
    withMenu = false,
    menuItems: pMenuItems,
    hasActions = true,
    className,
    onEdit,
    onDelete,
    onSelect,
    onAddDescriptionCallback,
  } = props;

  const defaultMenuItems = useDefaultCardMenu(onEdit!, onDelete!);

  const menuItems = useMemo(
    () => pMenuItems || defaultMenuItems,
    [pMenuItems, defaultMenuItems]
  );

  return (
    <StyledSectionCard
      onClick={onSelect}
      $view={view}
      className={classNames('esg-section-card', 'hover-effect', className)}
    >
      {withMenu && (
        <ThreeDotMenu
          className="esg-section-card__menu"
          menuItems={menuItems}
        />
      )}

      <div className="esg-section-card__content">
        <div className="esg-section-card__content__icon">{prefixElement}</div>
        <p className="esg-section-card__content__title">{title}</p>
      </div>

      <div className="esg-section-card__description">
        <FolderDescription
          description={description}
          hasActions={hasActions}
          onChange={onAddDescriptionCallback}
        />

        {status && (
          <StatusElement
            className="esg-section-card__status"
            {...(status as StatusElementProps<IName>)}
          />
        )}
      </div>
    </StyledSectionCard>
  );
};

export default memo(SectionCard);
