import { css } from 'styled-components';

const selectStyles = css`
  .wcl-select {
    &__option[class]:hover,
    &__option--active[class] {
      background-color: var(--GRAY-EF);
    }
  }
`;

export default selectStyles;
