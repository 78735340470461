import { Button, Input } from '@faxi/web-component-library';
import classNames from 'classnames';
import { InlineEditableType } from 'models';
import {
  FC,
  FocusEvent,
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Icon from '../../Icon';
import { StyledInlineEditable } from './InlineEditable.styled';

const InlineEditable: FC<InlineEditableType> = ({
  value,
  placeholderText,
  onBlur,
  onSave,
  onChange,
  onDiscard,
  conditionalElements,
  hasConditions,
  id,
  moduleElement,
  ...rest
}) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);

  const initialValue = useRef(value);

  const handleDiscard = useCallback(() => {
    onChange?.(initialValue.current || '');
    onDiscard?.();
    setIsEditing(false);
  }, [onChange, onDiscard]);

  const handleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement, Element>) => {
      if (
        !e.relatedTarget?.classList?.contains(
          'esg-inline-editable__input__actions__action'
        )
      )
        handleDiscard();

      onBlur?.(e);
    },
    [handleDiscard, onBlur]
  );

  const handleSave = useCallback(() => {
    onSave(
      inputContainerRef?.current?.getElementsByTagName('input')?.[0].value
    );
    setIsEditing(false);
  }, [onSave]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;

      e.stopPropagation();
      e.preventDefault();

      handleSave();
    },
    [handleSave]
  );

  useEffect(() => {
    const input =
      inputContainerRef?.current?.getElementsByTagName('input')?.[0];

    if (isEditing && input) {
      input.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    initialValue.current =
      inputContainerRef?.current?.getElementsByTagName('input')?.[0].value;
  }, [isEditing]);

  return (
    <StyledInlineEditable
      className="esg-inline-editable"
      isEditing={isEditing}
      {...rest}
    >
      <div
        className={classNames('esg-inline-editable__input', {
          'esg-inline-editable__input--is-editing': isEditing,
        })}
      >
        <Input
          tabIndex={0}
          ref={inputContainerRef as LegacyRef<HTMLInputElement>}
          type="text"
          onBlur={handleInputBlur}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        <div className="esg-inline-editable__input__actions">
          <Button
            onClick={handleSave}
            icon={<Icon name="check" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
          <Button
            onClick={handleDiscard}
            icon={<Icon name="xmark" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
        </div>
      </div>

      <p onClick={() => setIsEditing(true)}>{value || placeholderText}</p>
    </StyledInlineEditable>
  );
};

export default InlineEditable;
