import { IName, useUtilities } from '@faxi/web-component-library';
import { ButtonVariants } from '@faxi/web-component-library/build/components/_atoms/Button';
import Icon from 'components/Icon';
import { MouseEvent } from 'react';

interface DeleteCampaignItemOptions {
  submitBtnText?: string;
  cancelBtnText?: string;
  title?: string;
  content?: string;
  btnIcon?: IName;
  iconPosition?: 'left' | 'right';
  submitBtnVariant?: ButtonVariants;
  titleIcon?: React.ReactNode;
  overlayClass?: string;
}

const useDeleteCampaignItem = (onSubmit: (id: string) => Promise<void>) => {
  const { prompts, showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const onDelete =
    (
      id: string,
      name: string,
      onSuccess?: () => void,
      options: DeleteCampaignItemOptions = {}
    ) =>
    async (e?: MouseEvent) => {
      const {
        submitBtnText = 'Delete',
        cancelBtnText = 'Do not delete',
        title = `Delete ${name}`,
        content = `Are you sure you want to delete ${name} from campaigns?`,
        btnIcon = 'trash-can',
        iconPosition = 'left',
        submitBtnVariant = 'delete-ghost',
        titleIcon = <Icon name="triangle-exclamation" />,
        overlayClass = '.esg-campaign-entity',
      } = options;

      await prompts.delete({
        type: 'delete',
        submitBtnText,
        cancelBtnText,
        title,
        content,
        btnIcon,
        iconPosition,
        submitBtnVariant,
        titleIcon,
        triggerRef: e?.target as HTMLButtonElement,
        onSubmit: async () => {
          try {
            showOverlay(overlayClass);
            await onSubmit(id);

            onSuccess?.();

            showSnackBar({
              text: `Successfully deleted ${name} from campaigns.`,
              variant: 'success',
              actionButtonText: 'Dismiss',
            });
          } catch (error) {
            console.error('Error deleting campaign item:', error);
          } finally {
            hideOverlay(overlayClass);
          }
        },
      });
    };

  return { onDelete };
};

export default useDeleteCampaignItem;
