import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import { css } from 'styled-components';

export const modalStyles = css`
  .wcl-overlay[class] {
    --modal-animate-duration: 200ms;
    --modal-transform-y: ${theme.sizes.SIZE_24};

    background-color: var(--GRAY-8F-B3);
    transition-duration: var(--modal-animate-duration);

    .wcl-modal {
      max-width: ${theme.sizes.SIZE_600};
      min-width: ${theme.sizes.SIZE_504};
      box-shadow: 0px 32px 64px 0px #00000033;

      &__header__title {
        text-align: start;
        color: var(--PRIMARY_1_1);
      }
    }

    &.prompt-modal {
      .wcl-overlay__content {
        padding-top: ${theme.sizes.SIZE_200};
      }

      .wcl-modal {
        padding: ${theme.sizes.SIZE_32};

        &__header {
          &__icon {
            min-height: unset;
            height: ${theme.sizes.SIZE_24};
            font-size: ${theme.sizes.SIZE_32};
            width: ${theme.sizes.SIZE_32};
          }

          &__title {
            ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_32)};
            font-weight: 400;
            color: var(--BLACK-00);
          }
        }
      }

      .prompt-modal__content {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

        padding: 0;
        font-weight: 400;
        color: var(--BLACK-00);
        max-width: ${theme.sizes.SIZE_400};
      }

      //delete prompt styles
      &.delete {
        .wcl-modal {
          &__header__icon {
            color: var(--RED-D5);
          }

          &__footer {
            ${flex('row', 'flex-end', 'center')};
            ${flexGap(theme.sizes.SIZE_24, 'row')};

            button {
              padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_24};

              &:hover {
                opacity: 0.8;
              }
            }

            button[id='close'] {
              color: var(--BLACK-00);
            }

            button[id='submit'] {
              color: var(--WHITE-FF);
              background-color: var(--RED-EB);
            }
          }
        }
      }
    }

    &.enter,
    &.exit-active {
      .wcl-modal {
        animation: exiting var(--modal-animate-duration) ease-in;
      }
    }

    &.enter-active {
      .wcl-modal {
        animation: entering var(--modal-animate-duration) ease-out;
      }
    }

    @keyframes entering {
      0% {
        opacity: 0;
        transform: translate3d(0, var(--modal-transform-y), 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes exiting {
      0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      100% {
        opacity: 0;
        transform: translate3d(0, var(--modal-transform-y), 0);
      }
    }
  }
`;

export default modalStyles;
