import { AuthBody, NewUserAuthBody } from 'models';

import storageService from './storageService';

export default {
  get token(): string {
    return storageService.getItem<string>('SESSION_TOKEN');
  },

  get newUserToken(): string {
    return storageService.getItem<string>('USER_ID_FOR_SP');
  },

  get newUserSession(): string {
    return storageService.getItem<string>('NEW_PASSWORD_SESSION');
  },

  get refreshToken(): string {
    return storageService.getItem<string>('REFRESH_TOKEN');
  },

  saveAuthBody({ accessToken, refreshToken, idToken }: AuthBody) {
    storageService.setItem('SESSION_TOKEN', accessToken);
    storageService.setItem('REFRESH_TOKEN', refreshToken);
    storageService.setItem('ID_TOKEN', idToken);
  },

  saveNewUserAuthBody({ session, userIdForSrp }: NewUserAuthBody) {
    storageService.setItem('NEW_PASSWORD_SESSION', session);
    storageService.setItem('USER_ID_FOR_SP', userIdForSrp);
  },

  removeNewUserAuthBody() {
    storageService.removeItem('NEW_PASSWORD_SESSION');
    storageService.removeItem('USER_ID_FOR_SP');
  },

  removeAuthBody() {
    storageService.removeItem('SESSION_TOKEN');
    storageService.removeItem('REFRESH_TOKEN');
    storageService.removeItem('ID_TOKEN');
  },
};
