import { CompanyCampaignAPI } from 'models';
import useSWR, { SWRConfiguration } from 'swr';

import { API_ROUTES } from '../routes';

const useCompanyCampaign = (
  organisationId: string,
  config?: SWRConfiguration
) =>
  useSWR<{ data: CompanyCampaignAPI }, Error>(
    API_ROUTES.COMPANIES.COMPANY_CAMPAIGN(organisationId),
    config
  );

export default useCompanyCampaign;
