export const STORAGE_KEYS = {
  SESSION_TOKEN: 'SESSION_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  NEW_PASSWORD_SESSION: 'NEW_PASSWORD_SESSION',
  USER_ID_FOR_SP: 'USER_ID_FOR_SP',
  ID_TOKEN: 'ID_TOKEN',
  MODULES: 'MODULES',
};

type StorageKey = keyof typeof STORAGE_KEYS;

export default {
  ram: {} as Record<string, any>,
  setItem(key: StorageKey, value: Record<string, any> | string) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: StorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: StorageKey): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      return JSON.parse(this.ram[key]);
    }
  },
};
