import styled from 'styled-components';

import FoldersLayout from '../../../../../layouts/FoldersLayout';

const StyledFolderWrapper = styled(FoldersLayout)`
  .esg-tree-node-component__sub-folders {
    width: 100%;
    overflow-x: auto;
  }
`;

export { StyledFolderWrapper };
