import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledChosenValue = styled.div`
  ${flex('row')};
  ${flexGap(theme.sizes.SIZE_8, 'row')};

  .esg-chosen-value {
    &__label {
      ${flex('row', 'center', 'center')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
      color: var(--GRAY-54);
    }

    &__content {
      ${size('fit-content')};
      ${flex('row', 'center', 'center')};
      ${flexGap(theme.sizes.SIZE_8, 'row')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_16)};

      font-weight: 600;
      background-color: var(--GRAY-EF);
      border: 1px solid var(--GRAY-98);
      border-radius: ${theme.sizes.SIZE_8};
      padding: 4px ${theme.sizes.SIZE_12};

      .button {
        ${size(theme.sizes.SIZE_24)};

        min-height: unset;
        transition: transform 75ms ease-in-out;
      }
    }
  }
`;

export { StyledChosenValue };
