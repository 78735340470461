import { Expander, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledPreviewTool = styled(Expander)`
  height: unset !important;

  .expander {
    &__trigger {
      width: 100%;

      &__title {
        ${flex('row', 'space-between', 'center')};
      }
    }

    &__container {
      width: 100%;
    }
  }

  .esg-preview-tool {
    padding-top: ${theme.sizes.SIZE_32};

    > button {
      width: 100%;
    }
  }
`;

export { StyledPreviewTool };
