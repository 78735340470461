import api from 'api';
import Icon from 'components/Icon';
import { useMemo } from 'react';

const useRunSessionCampaign = (selectedCampaignId?: string) => {
  const { data } = api.useCompanyCampaigns();

  const companyCampaigns = useMemo(() => {
    if (!data?.children) return [];

    return data.children
      .filter((company) => !!company.campaign)
      .map((company) => company.campaign);
  }, [data?.children]);

  const potentialCampaignsOptions = useMemo(() => {
    return companyCampaigns
      .map((campaign) => ({
        value: campaign.campaign.id,
        label: campaign.campaign.name,
      }))
      .filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
  }, [companyCampaigns]);

  const comapniesWithAttachedCampaign = useMemo(() => {
    if (!selectedCampaignId) return [];

    return companyCampaigns
      .filter((campaign) => campaign.campaign.id === selectedCampaignId)
      .map((campaign) => campaign.company);
  }, [companyCampaigns, selectedCampaignId]);

  const companyOptions = useMemo(() => {
    return comapniesWithAttachedCampaign.map((company) => ({
      value: company.id,
      label: company.id,
      customLabel: (
        <span className="company-checkbox-label">
          <Icon name="shield" />
          {company.name}
        </span>
      ),
    }));
  }, [comapniesWithAttachedCampaign]);

  return {
    companyOptions,
    comapniesWithAttachedCampaign,
    potentialCampaignsOptions,
  };
};

export default useRunSessionCampaign;
