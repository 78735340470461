import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSwitchPreview = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${fontSize(theme.fontSizes.FONT_14)};

  width: 100%;
`;

const StyledConditionalField = styled.div<{ $open: boolean }>`
  overflow: hidden;

  opacity: ${({ $open }) => ($open ? 1 : 0)};
  transition: opacity 250ms linear;
`;

export { StyledConditionalField, StyledSwitchPreview };
