import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseTextFieldConfiguration = styled.div`
  .wcl-radio-group {
    gap: 40px;
  }

  .text-format {
    &--heading {
      > span {
        ${fontSize(theme.sizes.SIZE_32)}
        font-weight: 700;
      }
    }
    &--title {
      > span {
        ${fontSize(theme.sizes.SIZE_24)}
        font-weight: 600;
      }
    }
    &--subtitle {
      > span {
        ${fontSize(theme.sizes.SIZE_20)}
        font-weight: 500;
      }
    }
    &--body {
      > span {
        ${fontSize(theme.sizes.SIZE_16)}
      }
    }
  }
  .esg-text-field-configuration {
  }
`;

export { StyledBaseTextFieldConfiguration };
