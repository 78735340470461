import classNames from 'classnames';
import Icon from 'components/Icon';
import { FC, memo } from 'react';

import { TreeNodeElement } from '../../../../../../../models';

export type NodeIconProps = {
  isOpen: boolean;
  onClick?: () => void;
  node: TreeNodeElement & { canDelete?: boolean };
};

const NodeIcon: FC<NodeIconProps> = ({ isOpen, onClick, node }) => (
  <>
    <Icon
      className={classNames('esg-chevron-icon', {
        'esg-chevron-icon--open': isOpen,
      })}
      name="chevron-right"
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    />

    <Icon
      className="esg-node-icon"
      color={node.color}
      name={node.iconName || `folder${isOpen ? '-open' : ''}`}
    />
  </>
);

export default memo(NodeIcon);
