import { Divider } from '@faxi/web-component-library';
import { UploadDataModule, UploadModuleConfig } from 'models';
import { FC } from 'react';

import { SimpleInputField } from '../BaseInputFieldConfiguration/components/SimpleInput/SimpleInput.component';
import { StyledBaseUploadFieldConfiguration } from './BaseUploadFieldConfiguration.styled';

export type BaseUploadFieldConfigurationProps = UploadDataModule;

const BaseUploadFieldConfiguration: FC<BaseUploadFieldConfigurationProps> = ({
  hasConditions = true,
  config,
  renderModuleField,
}) => {
  return (
    <StyledBaseUploadFieldConfiguration className="esg-integration-field-configuration">
      <p>Please set up File upload options</p>

      {hasConditions && (
        <>
          {renderModuleField?.('file-is-uploaded')}

          <Divider />
        </>
      )}
      <SimpleInputField
        switchLabel="XBRL Tag"
        configKey="xbrl"
        placeholder="XBRL"
        config={config as UploadModuleConfig}
      />
    </StyledBaseUploadFieldConfiguration>
  );
};

export default BaseUploadFieldConfiguration;
