import { SectionDataModule } from 'models';
import { FC } from 'react';

import InputField from '../../../../../_fields/InputField';
import Icon from '../../../../../Icon';
import { StyledSectionPreview } from './SectionPreview.styled';

export type SectionPreviewProps = SectionDataModule;

const SectionPreview: FC<SectionPreviewProps> = ({
  title,
  moduleElement: ModuleElement,
  elements,
}) => {
  if (!elements?.length || !ModuleElement) return <></>;

  return (
    <StyledSectionPreview className="esg-section-preview">
      <div className="esg-section-preview__header">
        <InputField
          placeholder="Assign to section"
          prefixIcon={<Icon name="plus" />}
          disabled
        />
      </div>
      <div className="esg-section-preview__title">{title}</div>

      {elements.map((module, index) => (
        <ModuleElement
          modulesType="preview-module"
          type={module.type}
          module={module}
          className="esg-section-preview__module"
          key={index}
        />
      ))}
    </StyledSectionPreview>
  );
};

export default SectionPreview;
