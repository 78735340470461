import { Breadcrumb, Breadcrumbs } from '@faxi/web-component-library';
import { FC, memo } from 'react';

import Icon, { INameExtended } from '../../Icon';
import FolderDescription, {
  FolderDescriptionProps,
} from '../FolderDescription';
import { StyledFolderNavHeader } from './FolderNavHeader.styled';

export type FolderNavHeaderProps = {
  crumbs: Breadcrumb[];
  title: string;
  className?: string;
  icon?: INameExtended;
  updateDescription?: FolderDescriptionProps['onChange'];
} & Omit<FolderDescriptionProps, 'onChange'>;

const FolderNavHeader: FC<FolderNavHeaderProps> = (props) => {
  const {
    crumbs,
    title,
    updateDescription,
    className,
    icon = 'folder',
    ...rest
  } = props;

  return (
    <StyledFolderNavHeader className="esg-folder-nav-header">
      <Breadcrumbs
        className="esg-folder-nav-header__breadcrumbs"
        crumbs={crumbs}
        splitter="/"
      />

      <div className="esg-folder-nav-header__header">
        <Icon name={icon} />
        <h2>{title}</h2>
      </div>

      <FolderDescription onChange={updateDescription} {...rest} />
    </StyledFolderNavHeader>
  );
};

export default memo(FolderNavHeader);
