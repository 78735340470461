import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const commonRowStyles = css`
  ${flex('row', 'flex-start', 'flex-start')};
  min-width: ${theme.sizes.SIZE_784};
  border-bottom: ${theme.sizes.SIZE_1} solid var(--GRAY-C1);
  padding: ${theme.sizes.SIZE_20} 0;

  > div:first-of-type {
    width: ${theme.sizes.SIZE_296};
    ${flex('row', 'flex-start', 'center')};
    gap: ${theme.sizes.SIZE_10};
  }
`;

const StyledPermissionHeaderRow = styled.div`
  ${commonRowStyles};
  border-bottom: ${theme.sizes.SIZE_1} solid var(--GRAY-C1);

  > div:last-of-type {
    ${flex('row', 'flex-start', 'flex-start')};

    > div:first-of-type {
      flex: 1;
    }

    > div:last-of-type {
      width: ${theme.sizes.SIZE_152};
    }

    flex: 1;
  }
`;

const StyledPermissionRow = styled.div`
  ${commonRowStyles};

  > div:last-of-type {
    flex: 1;
  }

  .esg-user-role-permissions {
    &__checkbox {
      gap: 0;

      > div {
        height: ${theme.sizes.SIZE_48};

        &.indented {
          padding-left: ${theme.sizes.SIZE_32};

          &:not(:has(+ .indented)):not(:last-child) {
            border-bottom: solid 2px var(--GRAY-EF);
          }
        }

        &.underlined:not(:last-child) {
          border-bottom: solid 2px var(--GRAY-EF);
        }
      }

      label {
        width: 100%;
        padding-right: ${theme.sizes.SIZE_96};

        justify-content: space-between;

        .wcl-checkbox__box {
          background-color: var(--WHITE-FF);

          &--checked {
            background-color: var(--BLACK-00);

            svg {
              color: var(--WHITE-FF);
            }
          }
        }
      }

      &-subactions {
        margin-left: ${theme.sizes.SIZE_32};
      }
    }
  }
`;

export { StyledPermissionHeaderRow, StyledPermissionRow };
