import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCampaignLayout = styled.main`
  ${flex('column')};

  > div {
    overflow: auto;
  }

  .esg-campaign-layout {
    &__content {
      ${flex('row')};
      height: 100%;
      overflow: auto;

      .esg-collapsible-side-panel--expanded {
        width: 25%;
      }

      .esg-sub-sub-topic {
        flex-grow: 1;
      }
    }
  }
`;

export { StyledCampaignLayout };
