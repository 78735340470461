import api from 'api';
import { Loading } from 'components';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ProfileDetails from '../../../../../../components/ProfileDetails';

const UserByIdDetails: FC = () => {
  const { userId = '' } = useParams();

  const { data: user, isLoading } = api.useGetUser(userId);
  const { data: users, isLoading: isUsersLoading } = api.useGetUsers();

  const selectedUser = useMemo(
    () => users?.find((u) => u.id === user?.id),
    [user?.id, users]
  );

  if (isLoading || isUsersLoading) {
    return <Loading />;
  }

  return (
    <ProfileDetails
      user={
        user && {
          ...user,
          roles: selectedUser?.roles ?? [],
        }
      }
    />
  );
};

export default UserByIdDetails;
