import { Switch } from '@faxi/web-component-library';
import InlineEditable from 'components/_molecules/InlineEditable';
import { InlineModuleElement,SwitchDataModule } from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type SwitchCanvasModuleProps = InlineModuleElement<SwitchDataModule>;

const SwitchCanvasModule: FC<SwitchCanvasModuleProps> = ({
  showIcon = true,
  ...rest
}) => (
  <StyledModuleContainer className="esg-switch-canvas-module">
    <InlineEditable
      data-no-dnd="true"
      placeholderText="Click to enter/edit switch label"
      {...rest}
    />
    {showIcon && <Switch value={false} onChange={() => {}} />}
  </StyledModuleContainer>
);

export default memo(SwitchCanvasModule);
