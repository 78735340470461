// api.ts
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import env from 'env';

import { ApiData, AuthBody } from '../models';
import authBus, { AUTH_BUS_EVENTS } from '../modules/authBus';
import authService from '../services/authService';
import credentialsService from '../services/credentialsService';
import { API_ROUTES } from './routes';

//Extend AxiosRequestConfig to include the _retry property
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

type CustomAxiosError<T = any> = AxiosError<T> & {
  config: CustomAxiosRequestConfig; //Override the config property with our custom config type
};

const api: AxiosInstance = axios.create({
  baseURL: env.VITE_API_ENDPOINT,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = credentialsService.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: CustomAxiosError) => {
    const { config: request, response } = error;

    try {
      if (response) {
        if ([401, 403].includes(response.status) && !request._retry) {
          request._retry = true;

          const { data } = await api.post<ApiData<AuthBody>>(
            API_ROUTES.USERS.REFRESH_TOKEN,
            { refreshToken: credentialsService.refreshToken }
          );
          authService.loginUser(data.data);

          return api(request);
        }
      }
    } catch (e) {
      console.error('Token refresh failed');
      authBus.broadcastEvent(AUTH_BUS_EVENTS.LOGOUT);
      return Promise.reject(e);
    }

    authBus.broadcastEvent('show_snackbar', {
      props: {
        text:
          (error.response?.data as { message: string })?.message ||
          'An error occurred, please try again later.',
        variant: 'error',
        actionButtonText: 'Dismiss',
      },
    });
    return Promise.reject(error);
  }
);

export default api;
