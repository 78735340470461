import { Expander, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDataModulesTool = styled(Expander)`
  &.esg-data-modules-tool--wrapper {
    height: unset;
  }

  .expander {
    &__trigger {
      width: 100%;

      &__title {
        ${flex('row', 'space-between', 'center')};
      }
    }

    &__container {
      width: 100%;
      overflow: unset;
    }
  }
  .esg-data-modules-tool {
    ${flex('column')};

    padding-top: ${theme.sizes.SIZE_32};
    gap: ${theme.sizes.SIZE_32};

    > button {
      width: 100%;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      ${flex('column')};
      gap: ${theme.sizes.SIZE_8};
    }
  }
`;

export { StyledDataModulesTool };
