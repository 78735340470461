import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { CollapsibleSidePanel } from 'components';
import styled from 'styled-components';

const StyledBuilderTools = styled(CollapsibleSidePanel)`
  .esg-collapsible-side-panel {
    ${flex('column')};
    gap: ${theme.sizes.SIZE_20};
  }

  .esg-collapsible-side-panel--expanded {
    width: 25%;
  }

  .esg-builder-tools {
    &__divider {
      border-top: 1px solid var(--GRAY-EF);
    }
  }
`;

export { StyledBuilderTools };
