const APP_URI = {
  BASE: '/',

  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_SIGN_UP: '/auth/signup',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',

  ACCOUNT_SETTINGS: '/account-settings',

  NEW_PASSWORD_REQUIRED: '/setup-password',

  DASHBOARD: '/dashboard',

  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_CAMPAIGN: '/campaigns/:campaign',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC: '/campaigns/:campaign/topics/:topic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic/subsubsubtopics/:subsubsubtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC_SUBSUBSUBSUBTOPICS_SUBSUBSUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic/subsubsubtopics/:subsubsubtopic/subsubsubsubtopics/:subsubsubsubtopic',

  ORGANISATIONS: '/organisations',
  ORGANISATIONS_ORGANISATION: '/organisations/:organisation',
  ORGANISATIONS_ORGANISATION_COMPANY:
    '/organisations/:organisation/companies/:company',

  SESSIONS: '/sessions',
  SESSIONS_NAME: ':sessionId',
  SESSIONS_COMPANY: ':company',

  SESSION_RUN: 'run',
  SESSION_DASHBOARD: 'dash',
  SESSION_ACTIVITY: 'activity',
  SESSION_MEMBERS: 'members',
  SESSION_REPORT: 'report',
  SESSION_REPORT_COMPANY: 'companies/:company',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC: 'companies/:company/topics/:topic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',
  USERS: '/users',
  USER_ROLES: '/users/user-roles',
  USER_BY_ID_PROFILE: '/users/:userId',
  ADMIN: '/admin',

  TASKS: '/tasks',
  TASKS_TASK: '/tasks/:taskId',
  TASKS_TASK_DATA_COLLECTION:
    '/tasks/:taskId/company-session/:companyId/data-collection/:collectionId',

  PROFILE: '/profile',
  PERSONAL_DETAILS: '/profile/personal-details',
  NOTIFICATIONS: '/profile/notifications',
  ASSIGNMENTS: '/profile/assignments',
};

export { APP_URI };
