import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledReportStatus = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_16};

  .esg-report-status {
    &__title {
      ${fontSize(theme.fontSizes.FONT_14)};
      font-weight: 600;
    }

    &__content {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_32, 'column')};
    }

    &__approval {
      display: grid;
      grid-template-columns: ${theme.sizes.SIZE_104} 1fr;
      gap: ${theme.sizes.SIZE_12};
      align-items: center;

      .kinto-status-element,
      .kinto-progress-bar {
        width: 100%;
      }
    }
  }

  .esg-approval-chip,
  .esg-select-field {
    flex: 0 0 234px;
  }
`;

export { StyledReportStatus };
