import { Breadcrumbs } from '@faxi/web-component-library';
import { useGetSessionTree } from 'api/hooks/sessions';
import { CampaignSessionTreeNode } from 'models';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MAP_ID } from '../../constants';
import { useBreadcrumbs } from '../../hooks';
import { SessionEntryName } from '../../types';
import { findAllAncestorNames } from '../../utils';

export type SessionBreadCrumbsProps = PropsWithChildren<{
  name: SessionEntryName;
}>;

const SessionBreadCrumbs: FC<SessionBreadCrumbsProps> = (props) => {
  const { name } = props;
  const params = useParams();
  const { company } = params;
  const { sessionTreeWithOrganisationRoot } = useGetSessionTree(
    params?.sessionId || ''
  );

  const crumbNames = useMemo(() => {
    const workingNode = company
      ? sessionTreeWithOrganisationRoot?.children?.find((c) => c.id === company)
      : sessionTreeWithOrganisationRoot;

    const names = findAllAncestorNames(
      workingNode as CampaignSessionTreeNode,
      params?.[MAP_ID[name]] as string
    );

    return company && names
      ? [sessionTreeWithOrganisationRoot!.name, ...names]
      : names;
  }, [company, name, params, sessionTreeWithOrganisationRoot]);

  const { crumbs } = useBreadcrumbs(crumbNames);
  return <Breadcrumbs splitter="/" crumbs={crumbs} />;
};

export default SessionBreadCrumbs;
