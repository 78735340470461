import {
  PromptModalProps,
  Switch,
  SwitchProps,
  useUtilities,
} from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';
import { FC, useCallback, useRef } from 'react';

type ValueOnChange = (event: boolean) => void;

export type SwitchFieldProps = FieldProps<string, ValueOnChange> &
  SwitchProps & {
    tooltipContent?: string;
    promptSwitch?: boolean;
    promptModalProps?: PromptModalProps;
    onPromptSubmit?: () => void;
  };

// the purpose of this component is to exclute props which FormField adds
const SwitchField: FC<SwitchFieldProps> = (
  props: SwitchFieldProps
): JSX.Element => {
  const {
    dirty,
    error,
    touched,
    value,
    tooltipContent,
    promptSwitch = false,
    promptModalProps,
    className,
    label,
    onChange,
    onPromptSubmit,
    ...rest
  } = props;

  const { prompt } = useUtilities();

  const switchRef = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;

      if (!promptSwitch || !target.checked) {
        onChange?.(target.checked);
        return;
      }

      const promptProps = {
        submitBtnText: 'Ok',
        triggerRef: switchRef.current,
        ...promptModalProps,
      } as PromptModalProps;

      const proceed = await prompt(promptProps);
      if (!proceed) return;

      onChange?.(!value);
      onPromptSubmit?.();
    },
    [onChange, onPromptSubmit, prompt, value, promptModalProps, promptSwitch]
  );

  return (
    <Switch
      ref={switchRef}
      className={className}
      label={label}
      value={value}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

export default SwitchField;
