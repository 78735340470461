import { useFormContextValues } from '@faxi/web-form';
import { InputModuleConfig, InputPreviewType } from 'models';
import { FC, PropsWithChildren, useMemo } from 'react';

import { InputPreview } from '../../../../../../../components/_organisms/BuilderCanvas/components/previewElements';
import { StyledQuickConfigPreview } from './QuickConfigPreview.styled';

export type QuickConfigPreviewProps = PropsWithChildren<InputPreviewType>;

const QuickConfigPreview: FC<QuickConfigPreviewProps> = (props) => {
  const { renderFormField = false, ...rest } = props;

  const value = useFormContextValues(
    'type',
    'unit',
    'helperText',
    'placeholder',
    'minDate',
    'maxDate',
    'xbrl'
  ) as InputModuleConfig;

  const configProps: InputModuleConfig = useMemo(
    () => ({
      type: value.type,
      unit: value.unit,
      minDate: value.minDate,
      maxDate: value.maxDate,
      placeholder: value.placeholder,
      helperText: value.helperText,
    }),
    [value]
  );

  return (
    <StyledQuickConfigPreview className="esg-quick-config-preview">
      <p className="esg-quick-config-preview__title">Quick preview:</p>

      <InputPreview
        renderFormField={renderFormField}
        config={configProps}
        {...rest}
      />
    </StyledQuickConfigPreview>
  );
};

export default QuickConfigPreview;
