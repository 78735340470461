import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCompanyManagement = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_40};

  flex: 1;

  .esg-company-management {
    &__buttons {
      ${flex('row', 'space-between', 'center')};

      @media screen and (max-width: 480px) {
        ${flex('column', 'center', 'flex-start')};
      }

      gap: ${theme.sizes.SIZE_20};
      .wcl-tabs {
        width: ${theme.sizes.SIZE_208};
        height: ${theme.sizes.SIZE_46};
        i {
          margin: 0 !important;
        }
        &::before,
        label {
          width: ${theme.sizes.SIZE_104};
        }
      }
    }
  }
`;

export { StyledCompanyManagement };
