import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledQuickConfigPreview = styled.div`
  .esg-input-preview {
    margin-top: ${theme.sizes.SIZE_4};
    border: 1px solid var(--GRAY-D9);
    border-radius: ${theme.sizes.SIZE_8};
    margin: 0 0 ${theme.sizes.SIZE_12};
    padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_20};
  }

  .esg-quick-config-preview {
    &__title {
      ${flex('row')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};
      color: var(--GRAY-54);
    }
  }
`;

export { StyledQuickConfigPreview };
