import { useUtilities } from '@faxi/web-component-library';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import useGetRoles from '../../../../../api/hooks/users/useGetRoles';
import UserRoleHeader from './components/UserRoleHeader';
import UserRolePermissions from './components/UserRolePermissions';
import { USER_ROLE_FORM_DATA } from './data';
import { StyledUserRole } from './UserRole.styled';

export type UserRoleProps = {};

const UserRole: FC<UserRoleProps> = () => {
  //TODO: change with getRoleById when BE is ready
  const { data: userRoles } = useGetRoles();
  const { roleId } = useParams();
  const userRole = userRoles?.find((role) => role.id === roleId)!;

  const { showSnackBar } = useUtilities();

  return (
    <StyledUserRole className="esg-user-role">
      <UserRoleHeader
        description={userRole.description}
        name={userRole.name}
        color={userRole.color}
        onEdit={() => {
          //TODO: implement edit role when BE is ready
          showSnackBar({
            variant: 'error',
            text: 'No edit role action (Missing BE)',
          });
        }}
      />
      <UserRolePermissions
        onSubmit={() => Promise.resolve()}
        permissions={USER_ROLE_FORM_DATA}
      />
    </StyledUserRole>
  );
};

export default UserRole;
