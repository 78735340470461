import { SessionsPageParams } from 'models';
import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { SelectedSessionProvider } from '../../context/SelectedSession/';
import { SessionSidebar } from './components';
import { StyledSessionLayout } from './SessionLayout.styled';

const SessionLayout: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();

  if (!sessionId) return <></>;

  return (
    <StyledSessionLayout className="esg-sessions-layout">
      <SelectedSessionProvider sessionId={sessionId}>
        <SessionSidebar />
        <div className="esg-sessions-layout__content">
          <Outlet />
        </div>
      </SelectedSessionProvider>
    </StyledSessionLayout>
  );
};

export default SessionLayout;
