import { Contributor } from 'models';

export const DEFAULT_CONTRIBUTORS: Contributor[] = [
  {
    name: 'Jovan Aritonovic',
    src: '/assets/images/avatars/joca.png',
  },
  {
    name: 'Nikola Cvetanovic',
    src: '/assets/images/avatars/cvele.png',
  },
  {
    name: 'Andrija Cvetkovic',
    src: '/assets/images/avatars/kumca.png',
  },
  {
    name: 'Dusan Simijonovic',
    src: '/assets/images/avatars/dule.jpeg',
  },
  {
    name: 'Nemanja Stojanovic',
    src: '/assets/images/avatars/nemca.jpeg',
  },
];
