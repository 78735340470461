import { GlowScroll } from '@faxi/web-component-library';
import api from 'api';
import { Loading } from 'components';
import { APP_URI } from 'config';
import { CampaignItem } from 'models';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import authBus from '../../../../modules/authBus';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import CampaignEntity from '../CampaignEntity';

const Campaign: FC = () => {
  const { campaign = '' } = useParams<{
    campaign: string;
  }>();

  const {
    campaign: currentCampaign,
    isLoading,
    mutate,
  } = api.useGetCampaign(campaign);

  const { mutating, submitForm } = useCampaignFormEntitySubmit(
    campaign,
    mutate as KeyedMutator<any>,
    currentCampaign as CampaignItem
  );

  const navigateTo = useCallback(
    (id: string) =>
      APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC.replace(':topic', id).replace(
        ':campaign',
        campaign
      ),
    [campaign]
  );

  useEffect(() => {
    authBus.addEventListener('delete_campaign_entity', ({ id }) => {
      currentCampaign?.children.some((item) => item?.id === id) && mutate();
    });
  }, [campaign, currentCampaign, mutate]);

  return isLoading ? (
    <Loading />
  ) : (
    <GlowScroll variant="gray">
      <CampaignEntity
        campaignItem={currentCampaign as CampaignItem}
        modalProps={{ loading: mutating }}
        navigateTo={navigateTo}
        entityType="topic"
        onSubmit={submitForm}
        onMutate={mutate}
      />
    </GlowScroll>
  );
};

export default Campaign;
