import { GlowScroll } from '@faxi/web-component-library';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useGetGampaignItem from '../../../../api/hooks/useGetCampaignItem';
import { APP_URI } from '../../../../config';
import authBus from '../../../../modules/authBus';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import { campaignItemTypeSelectOptions } from '../../utils';
import CampaignEntity from '../CampaignEntity';

type SubTopicProps = { level: 0 | 1 | 2 | 3 };

const SubTopic: FC<SubTopicProps> = ({ level }) => {
  const {
    topic = '',
    campaign = '',
    subtopic = '',
    subsubtopic = '',
    subsubsubtopic = '',
    subsubsubsubtopic = '',
  } = useParams<{
    topic: string;
    campaign: string;
    subtopic: string;
    subsubtopic: string;
    subsubsubtopic: string;
    subsubsubsubtopic: string;
  }>();

  const subtopics = [subtopic, subsubtopic, subsubsubtopic, subsubsubsubtopic];

  const { campaignItem, isLoading, mutate } = useGetGampaignItem(
    subtopics[level]
  );

  const { mutating, submitForm } = useCampaignFormEntitySubmit(
    subtopics[level],
    mutate,
    campaignItem
  );

  const navigateTo = useCallback(
    (name: string) => {
      const replacePathParams = (url: string, level: number) => {
        const keys = [':subsubtopic', ':subsubsubtopic', ':subsubsubsubtopic'];

        let finalUrl = url
          .replace(':campaign', campaign)
          .replace(':topic', topic)
          .replace(':subtopic', subtopic);

        if ([1, 2].includes(level)) {
          finalUrl = finalUrl.replace(':subsubtopic', subsubtopic);
        }

        if (level === 2) {
          finalUrl = finalUrl.replace(':subsubsubtopic', subsubsubtopic);
        }

        return finalUrl.replace(keys[level], name);
      };

      const appUris = [
        APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC,
        APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC,
        APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC_SUBSUBSUBSUBTOPICS_SUBSUBSUBSUBTOPIC,
      ];

      return replacePathParams(appUris[level], level);
    },
    [campaign, level, subsubsubtopic, subsubtopic, subtopic, topic]
  );

  useEffect(() => {
    authBus.addEventListener('delete_campaign_entity', ({ id }) => {
      campaignItem?.children.some((item) => item?.id === id) && mutate();
    });
  }, [campaignItem, mutate]);

  return (
    <GlowScroll variant="gray">
      <CampaignEntity
        campaignItem={campaignItem}
        navigateTo={navigateTo}
        entityType="subsubtopic"
        isLoading={isLoading}
        onSubmit={submitForm}
        modalProps={{
          chooseType: true,
          loading: mutating,
          fieldProps: {
            type: {
              options:
                level < 2
                  ? campaignItemTypeSelectOptions
                  : [campaignItemTypeSelectOptions[0]],
            },
          },
        }}
        onMutate={mutate}
      />
    </GlowScroll>
  );
};

export default SubTopic;
