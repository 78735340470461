import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledNumericInput = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  margin-top: ${theme.sizes.SIZE_32};

  .esg-numeric-input {
    &__preview {
      ${flex('column')};
      margin: ${theme.sizes.SIZE_24} 0;

      &__title {
        ${flex('row')};
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        color: var(--GRAY-54);
      }

      &,
      .esg-input-preview {
        width: 100%;
      }

      .esg-input-preview {
        margin-top: ${theme.sizes.SIZE_4};
        padding: ${theme.sizes.SIZE_24} 0;
        border-top: 1px solid var(--GRAY-98);
      }
    }
  }
`;

export { StyledNumericInput };
