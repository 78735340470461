import { elevate_xxl, focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDataModule = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${fontSize('14px', '20px')};
  ${focusStyles};
  gap: ${theme.sizes.SIZE_12};
  padding: ${theme.sizes.SIZE_12};
  border-radius: ${theme.sizes.SIZE_8};
  border: ${theme.sizes.SIZE_1} solid var(--GRAY-C1);
  background: white;
  cursor: grab;
  min-height: ${theme.sizes.SIZE_56};
  touch-action: none;

  > i {
    ${size('24px')}
  }

  > p {
    margin: 0;
  }

  &:hover {
    ${elevate_xxl};
  }

  &.esg-data-module-tool {
    &--active {
      background: var(--GRAY-C1);
    }

    &--is-dragging {
      opacity: 0.2;
    }

    &--is-grabbing {
      cursor: grabbing;
    }
  }
`;

export { StyledDataModule };
