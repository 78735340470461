import { InlineModuleElement,TextDataModule } from 'models';
import { FC, memo } from 'react';

import InlineEditable from '../../../../_molecules/InlineEditable';
import { StyledTextCanvasModule } from './TextCanvasModule.styled';

export type TextCanvasModuleProps = InlineModuleElement<TextDataModule>;

const TextCanvasModule: FC<TextCanvasModuleProps> = ({
  showIcon,
  conditionalElements,
  hasConditions,
  ...props
}) => (
  <StyledTextCanvasModule className="esg-text-canvas-module">
    <InlineEditable
      data-no-dnd="true"
      placeholderText={'Click to enter/edit text'}
      {...props}
    />
  </StyledTextCanvasModule>
);

export default memo(TextCanvasModule);
