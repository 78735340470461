import { FormulaDataModule } from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type FormulaCanvasModuleProps = FormulaDataModule;

const FormulaCanvasModule: FC<FormulaCanvasModuleProps> = () => {
  return (
    <StyledModuleContainer className="esg-formula-canvas-module">
      Formula
    </StyledModuleContainer>
  );
};

export default memo(FormulaCanvasModule);
