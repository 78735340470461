import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCompany = styled.div`
  ${flex('row')};
  height: fit-content;
  flex: 1;

  gap: ${theme.sizes.SIZE_24};

  .esg-company {
    &__company-management {
      flex: 100%;
    }

    &__vertical {
      flex: 0;
      border-left: 1px solid var(--GRAY-EF);
    }

    &__campaign {
      flex: 100%;
      ${flex('column')};
      gap: ${theme.sizes.SIZE_40};

      &__no-data {
        ${flex('row', 'center', 'center')};
        height: 100%;

        p {
          ${fontSize(theme.fontSizes.FONT_16)};
        }
      }
    }

    &__campaign-menu {
      margin: ${theme.sizes.SIZE_24} 0;
    }

    &__select {
      max-width: ${theme.sizes.SIZE_304};
    }

    &__selected-campaign {
      ${flex('row')};

      gap: ${theme.sizes.SIZE_20};

      &__title {
        ${flex('row', 'center', 'center')};
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        color: var(--GRAY-54);
      }

      &__discard {
        ${flex('row', 'center', 'center')};
        ${flexGap(theme.sizes.SIZE_10, 'row')};
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        background-color: var(--GRAY-EF);
        border: 1px solid var(--GRAY-98);
        border-radius: ${theme.sizes.SIZE_8};
        padding: 6px ${theme.sizes.SIZE_10};

        .button {
          min-height: unset;
          color: black;
          padding: ${theme.sizes.SIZE_8} !important;
          width: ${theme.sizes.SIZE_32};
        }
      }
    }
  }
`;

export { StyledCompany };
