import { FormField } from '@faxi/web-form';
import { FormFooter, InputField } from 'components';
import { useValidations } from 'hooks';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { StyledSignUp } from './SignUp.styled';

const SignUp: FC = () => {
  const { validations } = useValidations();

  const handleSubmit = useCallback(async () => {}, []);

  return (
    <StyledSignUp className="esg-sign-up" onSubmit={handleSubmit}>
      <FormField
        name="email"
        type="text"
        placeholder="Email"
        component={InputField}
        validate={validations.email}
      />

      <FormField
        name="password"
        type="password"
        placeholder="Password"
        component={InputField}
        validate={validations.password}
      />

      <FormField
        name="repeatPassword"
        type="password"
        placeholder="Repeat password"
        component={InputField}
        validate={validations.repeatPassword}
      />

      <FormFooter submitLabel="Sign up" />

      <p className="auth-text">
        Already a member?
        <Link to="../"> Login</Link>
      </p>
    </StyledSignUp>
  );
};

export default SignUp;
