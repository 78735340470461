import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseUploadFieldConfiguration = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_40};
`;

export { StyledBaseUploadFieldConfiguration };
