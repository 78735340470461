import { Button, Icon, useEffectSkipFirst } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { StyledCollapsibleSidePanel } from './CollapsibleSidePanel.styled';

export type CollapsibleSidePanelProps = PropsWithChildren<{
  className?: string;
  expandDirection?: 'left' | 'right';
}>;

const CollapsibleSidePanel: FC<CollapsibleSidePanelProps> = ({
  children,
  className,
  expandDirection = 'left',
}) => {
  const [expanded, setExpanded] = useState(true);

  const [hidden, setHidden] = useState(!expanded);

  const iconName = useMemo(
    () =>
      (expandDirection === 'right' && expanded) ||
      (expandDirection === 'left' && !expanded)
        ? 'chevron-left'
        : 'chevron-right',
    [expandDirection, expanded]
  );

  useEffectSkipFirst(() => {
    setTimeout(() => setHidden(!expanded), 350);
  }, [expanded]);

  return (
    <StyledCollapsibleSidePanel
      className={classNames(
        'esg-collapsible-side-panel--wrapper',
        { 'esg-collapsible-side-panel--expanded': expanded },
        className
      )}
      expandDirection={expandDirection}
      expanded={expanded}
    >
      {!hidden && <div className="esg-collapsible-side-panel">{children}</div>}
      <Button
        tabIndex={0}
        className="esg-collapsible-side-panel__collapse"
        variant="primary-invert"
        aria-expanded={expanded}
        onClick={() => setExpanded((prev) => !prev)}
        icon={<Icon name={iconName} />}
      />
    </StyledCollapsibleSidePanel>
  );
};

export default CollapsibleSidePanel;
