import { Campaign } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaigns = () => {
  const { data, error, isLoading, mutate } = useSWR<{ data?: Campaign[] }>(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEMS
  );

  return {
    campaigns: data?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetCampaigns;
