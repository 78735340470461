import { Method } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

import defaultMutation, {
  MutationArgs,
  RequestArgs,
} from '../../defaultMutation';

type TriggerParams = {
  method: Method;
  args: RequestArgs;
};

const useMutationWithDynamicPath = <TData = any, TError = Error>(
  config?: SWRMutationConfiguration<TData, TError, string, MutationArgs>
) => {
  const [url, setUrl] = useState<string>('');
  const [triggerParams, setTriggerParams] = useState<TriggerParams>();

  const { trigger, ...rest } = useSWRMutation<
    TData,
    TError,
    string,
    MutationArgs
  >(url, defaultMutation, config);

  const request = useCallback(
    (url: string, method: Method, args: RequestArgs) => {
      setTriggerParams({ method, args });
      setUrl(url);
    },
    []
  );

  const apiCall = useCallback(
    async (triggerParams: TriggerParams) => {
      try {
        return await trigger(triggerParams as TriggerParams);
      } catch (err) {
        console.error('Error making request:', err);
        throw err;
      }
    },
    [trigger]
  );

  useEffect(() => {
    if (url && triggerParams) {
      apiCall(triggerParams);
    }
  }, [apiCall, triggerParams, url]);

  return {
    request,
    ...rest,
  };
};

export default useMutationWithDynamicPath;
