import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledAssignedContributor = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${elevate_l};

  gap: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_8};
  border-radius: ${theme.sizes.SIZE_8};

  &.selectable {
    cursor: pointer;
    transition: transform 100ms ease-in-out;

    &:hover {
      transform: scale3d(1.018, 1.018, 1.018);
    }
  }

  & > div {
    ${flex('row', 'flex-start', 'center')};
    gap: ${theme.sizes.SIZE_8};
  }

  p {
    ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};
    font-weight: 400;
  }

  img {
    ${size(theme.sizes.SIZE_24)};
    border-radius: 50%;
  }

  .esg-assigned-contributor {
    &__menu > .esg-three-dot-menu__dots {
      ${size(theme.sizes.SIZE_20)};
    }
  }
`;

export { StyledAssignedContributor };
