import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBuilderCanvas = styled.div`
  ${flex('column')};
  padding: ${theme.sizes.SIZE_32} 0;
  gap: ${theme.sizes.SIZE_12};
  flex-grow: 1;

  .esg-builder-canvas {
    &__drop-area {
      ${flex('column', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_20};
      flex-grow: 1;

      &__drop-modules {
        ${flex('column', 'center', 'center')};
        gap: ${theme.sizes.SIZE_12};
        padding: ${theme.sizes.SIZE_20};
        ${fontSize(theme.fontSizes.FONT_16)};
        text-align: center;
        border: ${theme.sizes.SIZE_1} solid var(--GRAY-C1);
        border-radius: ${theme.sizes.SIZE_8};
        border-style: dashed;
        width: 100%;

        svg {
          ${fontSize(theme.fontSizes.FONT_34)};
          color: var(--GRAY-C1);
        }
      }
    }
  }
`;

export { StyledBuilderCanvas };
