import { theme } from '@faxi/web-component-library';
import { flex, flexGap } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledEmailInput = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  margin-top: ${theme.sizes.SIZE_32};

  .esg-email-input {
  }
`;

export { StyledEmailInput };
