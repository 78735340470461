import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import { Form } from '@faxi/web-form';
import styled from 'styled-components';

const StyledDataCollection = styled(Form)`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_20, 'column')};
  ${size('100%')};

  .esg-loading {
    margin: auto 0;
  }

  .esg-data-collection {
    &__title {
      ${fontSize(theme.fontSizes.FONT_34, theme.sizes.SIZE_56)};

      font-weight: 400;
      margin: 0 0 ${theme.sizes.SIZE_24};
    }
  }
`;

export { StyledDataCollection };
