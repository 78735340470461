import { DataModuleEnum, ModuleConfigMapperType } from 'models';

import BaseCheckListFieldConfiguration from './BaseCheckListFieldConfiguration/BaseCheckListFieldConfiguration.component';
import BaseChoiceFieldConfiguration from './BaseChoiceFieldConfiguration/BaseChoiceFieldConfiguration.component';
import BaseDropdownFieldConfiguration from './BaseDropdownFieldConfiguration/BaseDropdownFieldConfiguration.component';
import BaseInputFieldConfiguration from './BaseInputFieldConfiguration';
import BaseIntegrationFieldConfiguration from './BaseIntegrationFieldConfiguration/BaseIntegrationFieldConfiguration.component';
import BaseSwitchFieldConfiguration from './BaseSwitchFieldConfiguration/BaseSwitchFieldConfiguration.component';
import BaseTextFieldConfiguration from './BaseTextFieldConfiguration';
import BaseUploadFieldConfiguration from './BaseUploadFieldConfiguration/BaseUploadFieldConfiguration.component';

export const BASE_CONFIGURABLE_MODULES: ModuleConfigMapperType = {
  [DataModuleEnum.CHECKLIST]: BaseCheckListFieldConfiguration,
  [DataModuleEnum.INPUT]: BaseInputFieldConfiguration,
  [DataModuleEnum.CHOICE]: BaseChoiceFieldConfiguration,
  [DataModuleEnum.DROPDOWN]: BaseDropdownFieldConfiguration,
  [DataModuleEnum.SWITCH]: BaseSwitchFieldConfiguration,
  [DataModuleEnum.INTEGRATION]: BaseIntegrationFieldConfiguration,
  [DataModuleEnum.UPLOAD]: BaseUploadFieldConfiguration,
  [DataModuleEnum.TEXT_BOX]: BaseTextFieldConfiguration,
};
