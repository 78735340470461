import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Logo = styled.div`
  ${flex('column', 'center', 'center')};

  position: relative;
  height: fit-content;

  .name {
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    transition-property: opacity, transform;

    path:last-of-type {
      opacity: 1;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      transition-property: opacity;
    }
  }

  &[data-expanded='false'] {
    .name {
      transform: translate3d(-1px, 0, 0);

      path:last-of-type {
        opacity: 0;
      }
    }
  }
`;
