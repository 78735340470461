import { theme } from '@faxi/web-component-library';
import { flex, flexGap, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleContainer = styled.div`
  ${flex('row', 'space-between', 'center')};
  flex-wrap: wrap;
  gap: ${theme.sizes.SIZE_8};
  flex: 1;

  > label {
    pointer-events: none;
  }

  .input {
    pointer-events: none;
  }

  .wcl-divider {
    width: 100%;
    border-color: var(--SUCCESS_2_1);
  }

  .wcl-dropdown__trigger {
    ${flex('row', 'flex-start', 'center')};

    gap: ${theme.sizes.SIZE_12};

    i {
      transform: rotate(0deg);
      transition: transform 0.3s ease-in-out;
    }

    &--open {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  .esg-integration-canvas-module {
    &__content {
      ${flex('row', 'center', 'center')};
      ${flexGap(theme.sizes.SIZE_32, 'row')};
    }

    &__image {
      ${size(theme.sizes.SIZE_40)};
      object-fit: contain;
    }
  }
`;

export { StyledModuleContainer };
