import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserProfileImage = styled.div`
  ${flex('column', 'center', 'center')};
  ${size(theme.sizes.SIZE_136)};
  background-color: var(--GRAY-EF);
  border-radius: 50%;
  margin-bottom: ${theme.sizes.SIZE_48};
  align-self: flex-start;
  color: var(--GRAY-98);
  position: relative;

  > i {
    svg {
      ${size(theme.sizes.SIZE_104)};
    }
  }

  .esg-user-profile-image {
    &__plus {
      ${size(theme.sizes.SIZE_32)};
      ${flex('column', 'center', 'center')};

      svg {
        stroke: currentColor;
        stroke-width: ${theme.sizes.SIZE_20};
      }

      color: var(--TEAL-00);
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 3px solid var(--WHITE-FF);
      background-color: var(--GRAY-EF);
    }
  }
`;

export { StyledUserProfileImage };
