import { useUtilities } from '@faxi/web-component-library';
import { isAxiosError } from 'axios';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import useMutation from '../../api/hooks/mutations/useMutation';
import { API_ROUTES } from '../../api/routes';
import { APP_URI } from '../../config';
import authBus, { AUTH_BUS_EVENTS } from '../../modules/authBus';
import authService from '../../services/authService';
import credentialsService from '../../services/credentialsService';
import storageService from '../../services/storageService';
import { getUserInformationFromToken } from '../../utils';
import UserContext, { UserContextProps } from './User.context';

type UserProviderProps = {
  children?: ReactNode;
};

const UserProvider: FC<UserProviderProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const [userTokenInformation, setUserTokenInformation] =
    useState<UserContextProps['user']>();

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const { request: logout } = useMutation<void>(API_ROUTES.USERS.LOGOUT);

  const updateUserInformation = useCallback((token?: string) => {
    const info = getUserInformationFromToken(token);

    setUserTokenInformation(info);
  }, []);

  const logoutUser = useCallback(async () => {
    if (credentialsService.newUserToken) {
      authService.logoutNewUser();
      navigate(APP_URI.AUTH_LOGIN);
      return;
    }

    if (!credentialsService.token) return;

    try {
      showOverlay('body');
      await logout('DELETE', {});
    } catch (e) {
      showSnackBar({
        text:
          isAxiosError<{ message: string }>(e) && e?.response?.data?.message
            ? e?.response?.data?.message
            : 'An error occurred, please try again later.',
        variant: 'error',
        actionButtonText: 'Dismiss',
      });
    } finally {
      //TODO: no refresh token logic, request will fail if token expiered
      //for now we logout user even if logout api call fail

      localStorage.clear();
      updateUserInformation();
      hideOverlay('body');
      navigate(APP_URI.AUTH_LOGIN);
    }
  }, [
    hideOverlay,
    logout,
    navigate,
    showOverlay,
    showSnackBar,
    updateUserInformation,
  ]);

  useEffect(() => {
    const userIdToken = storageService.getItem<string>('ID_TOKEN');
    updateUserInformation(userIdToken);

    const unsubscribeUpdateUser = authBus.addEventListener<string>(
      AUTH_BUS_EVENTS.UPDATE_USER,
      updateUserInformation
    );

    return () => {
      unsubscribeUpdateUser();
    };
  }, [updateUserInformation]);

  useEffect(() => {
    const unsubscribeLogout = authBus.addEventListener<void>(
      AUTH_BUS_EVENTS.LOGOUT,
      logoutUser
    );

    return () => {
      unsubscribeLogout();
    };
  }, [logoutUser]);

  return (
    <UserContext.Provider
      value={{
        user: userTokenInformation,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserProvider = (): UserContextProps => useContext(UserContext);

export { UserProvider, useUserProvider };
