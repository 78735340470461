import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const LoadingStyled = styled.div`
  ${flex('column', 'center', 'center')};

  width: 100%;
  height: 100%;
  margin: auto 0;

  .esg-loading {
    &__content {
      ${flex('column', 'center', 'center')};
      gap: ${theme.sizes.SIZE_8};

      &__loading {
        transition: all 500ms;
      }

      &:hover {
        transform: scale(1.2);
        cursor: progress;

        .esg-loading__content__loading {
          transform: rotate3d(0, 0, 1, 360deg);
        }
      }
    }
  }
`;
