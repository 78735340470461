import { ProgressData, TreeNodeElement } from 'models';

import CompanyTreeNode from '../../../../../../../../pages/Sessions/components/SessionDashboardCompany/components/CompanyTreeNode';

export type UserAssignementsTreeNodeProps = Omit<
  TreeNodeElement,
  'children' | 'parent' | 'created' | 'modified'
> & {
  progress: ProgressData;
  children?: UserAssignementsTreeNodeProps[];
  parent?: UserAssignementsTreeNodeProps | null;
};

export const USER_ASSIGNMENT_TREE_NODE_DATA_WITHOUT_PARENTS: UserAssignementsTreeNodeProps =
  {
    id: '1',
    element: 'All',
    name: 'All',
    progress: {
      currentStep: 85,
      numberOfSteps: 100,
      status: 'In Progress',
    },
    children: [
      {
        id: '2',
        element: 'Environment',
        name: 'Environment',
        progress: {
          currentStep: 2,
          numberOfSteps: 8,
          status: 'Completed',
        },
        children: [
          {
            id: '3',
            element: 'E1 - Climate Change',
            name: 'E1 - Climate Change',
            progress: {
              currentStep: 3,
              numberOfSteps: 5,
              status: 'In Progress',
            },
            children: [
              {
                id: '4',
                element: 'E1 - 1 - Climate Change Adaptation',
                name: 'E1 - 1 - Climate Change Adaptation',
                progress: {
                  currentStep: 4,
                  numberOfSteps: 4,
                  status: 'In Progress',
                },
              },
              {
                id: '5',
                element: 'E1 - 2 - Climate Change Mitigation',
                name: 'E1 - 2 - Climate Change Mitigation',
                progress: {
                  currentStep: 4,
                  numberOfSteps: 4,
                  status: 'In Progress',
                },
              },
            ],
          },
          {
            id: '6',
            element: 'E2 - Pollution',
            name: 'E2 - Pollution',
            progress: {
              currentStep: 3,
              numberOfSteps: 5,
              status: 'In Progress',
            },
            children: [
              {
                id: '7',
                element: 'E2 - 1 - Pollution of air',
                name: 'E2 - 1 - Pollution of air',
                progress: {
                  currentStep: 4,
                  numberOfSteps: 4,
                  status: 'In Progress',
                },
              },
              {
                id: '8',
                element: 'E2 - 2 - Pollution of water',
                name: 'E2 - 2 - Pollution of water',
                progress: {
                  currentStep: 4,
                  numberOfSteps: 4,
                  status: 'In Progress',
                },
              },
            ],
          },
        ],
      },
      {
        id: '9',
        element: 'Social',
        name: 'Social',
        progress: {
          currentStep: 1,
          numberOfSteps: 6,
          status: 'In Progress',
        },
        children: [
          {
            id: '10',
            element: 'Freedom of Expression',
            name: 'Freedom of Expression',
            progress: {
              currentStep: 2,
              numberOfSteps: 7,
              status: 'Completed',
            },
            children: [
              {
                id: '11',
                element: 'E1 - 1 - Right to Education',
                name: 'E1 - 1 - Right to Education',
                progress: {
                  currentStep: 3,
                  numberOfSteps: 3,
                  status: 'In Review',
                },
              },
              {
                id: '12',
                element: 'E2 - 2 - Protection from Discrimination',
                name: 'E2 - 2 - Protection from Discrimination',
                progress: {
                  currentStep: 4,
                  numberOfSteps: 5,
                  status: 'In Progress',
                },
              },
            ],
          },
          {
            id: '13',
            element: 'E2 - Social Welfare',
            name: 'E2 - Social Welfare',
            progress: {
              currentStep: 1,
              numberOfSteps: 3,
              status: 'In Progress',
            },
            children: [
              {
                id: '14',
                element: 'E2 - 1 - Community Support',
                progress: {
                  currentStep: 2,
                  numberOfSteps: 2,
                  status: 'Completed',
                },
                name: 'E2 - 1 - Community Support',
              },
            ],
          },
        ],
      },
    ],
  };

const createTreeWithCompanyNodes = (
  node: UserAssignementsTreeNodeProps
): UserAssignementsTreeNodeProps => {
  node.element = <CompanyTreeNode {...node} />;
  node.children = node.children?.map((child) =>
    createTreeWithCompanyNodes(child)
  );

  return node;
};

const USER_ASSIGNMENT_NODE_DATA = createTreeWithCompanyNodes(
  USER_ASSIGNMENT_TREE_NODE_DATA_WITHOUT_PARENTS
);

export { USER_ASSIGNMENT_NODE_DATA };
