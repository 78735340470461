import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled from 'styled-components';

export const StyledUsers = styled(BaseLayout)`
  .esg-users {
    &__container {
      padding: ${theme.sizes.SIZE_32};
      width: 100%;
      height: fit-content;
      overflow: auto;

      &__header {
        ${flex('row', 'space-between', 'center')};
        gap: ${theme.sizes.SIZE_16};
        @media screen and (max-width: 520px) {
          ${flex('column', 'center', 'flex-start')};
        }

        &__buttons {
          ${flex('row', 'flex-start', 'center')};
          gap: ${theme.sizes.SIZE_16};
        }

        .wcl-tabs {
          width: ${theme.sizes.SIZE_208};
          height: ${theme.sizes.SIZE_46};
          i {
            margin: 0 !important;
          }
          &::before,
          label {
            width: ${theme.sizes.SIZE_104};
          }
        }
      }

      &__cards {
        ${flex('column')};
        margin-top: ${theme.sizes.SIZE_48};
        gap: ${theme.sizes.SIZE_20};
      }
    }
  }
`;
