import {
  Breadcrumbs,
  Divider,
  Heading,
  useCallbackRef,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import api from 'api';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import { RunSessionData,SessionsPageParams } from 'models';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSessionsData } from 'store/SessionsData';

import {
  CalendarField,
  CheckboxListField,
  SelectField,
} from '../../../../components';
import { CheckboxIconLabel } from './components';
import useRunSessionCampaign from './hooks/useRunSessionCampaign';
import {
  StyledRunSession,
  StyledRunSessionButton,
  StyledSubheader,
} from './RunSession.styled';

const validations = {
  deadline: validators.general.required('Deadline is required'),
  campaign: validators.general.required('Campaign is required'),
  companies: validators.general.required('Please select at least one row'),
};

const RunSession: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();
  const { sessions, runSession } = useSessionsData();

  const [form, formRef] = useCallbackRef<FormRef>();

  const { campaign: campaignId } = useFormRefValues(form, 'campaign') || {};

  const location = useLocation();

  const { data, error, isLoading } = api.useCompanyCampaigns();

  const { companyOptions, potentialCampaignsOptions } =
    useRunSessionCampaign(campaignId);

  const selectedSession = useMemo(
    () => sessions.find((session) => session.id === sessionId),
    [sessions, sessionId]
  );

  const handleRunSession = useCallback(
    async (values: RunSessionData & { campaign: string }) => {
      if (!sessionId) return;

      const { campaign, ...rest } = values;

      runSession(sessionId, rest);
    },
    [sessionId, runSession]
  );

  if (!sessionId || !selectedSession) return <></>;

  return (
    <StyledRunSession
      direction="column"
      title="Sessions"
      className="esg-run-session"
    >
      <BlockUI loading={isLoading}>
        <Form onSubmit={handleRunSession} ref={formRef}>
          <Breadcrumbs
            splitter="/"
            crumbs={[
              { text: 'Sessions', href: APP_URI.SESSIONS },
              { text: selectedSession?.name, href: location.pathname },
            ]}
          />
          <header className="esg-run-session__header--title">
            <Icon name="bars-progress-solid" />
            <Heading level="1">{selectedSession?.name}</Heading>
          </header>
          <section className="esg-run-session__header--actions">
            <StyledSubheader>{selectedSession.description}</StyledSubheader>
            <StyledRunSessionButton type="submit">
              Start Session
            </StyledRunSessionButton>
          </section>
          <Divider />
          <BlockUI
            fallbackCondition={!!error}
            fallback={
              <p className="esg-run-session__subtitle">
                Something went wrong. We're unable to retrieve the data.
              </p>
            }
          >
            <div className="esg-run-session__organisation">
              <StyledSubheader className="esg-run-session__subtitle">
                Organisation
              </StyledSubheader>

              {data?.name && <CheckboxIconLabel name={data.name} />}
            </div>

            <div className="esg-run-session__content">
              <div className="esg-run-session__content__row">
                <StyledSubheader className="esg-run-session__subtitle">
                  Campaign
                </StyledSubheader>
                <FormField
                  name="campaign"
                  component={SelectField}
                  validate={validations.campaign}
                  options={potentialCampaignsOptions}
                />
              </div>
              {campaignId && (
                <>
                  <div className="esg-run-session__content__row">
                    <StyledSubheader>Companies</StyledSubheader>
                    <FormField
                      component={CheckboxListField}
                      name="companyIds"
                      options={companyOptions}
                      className="company-checklist"
                      validate={validations.companies}
                    />
                  </div>
                  <div className="esg-run-session__content__row">
                    <StyledSubheader className="esg-run-session__subtitle">
                      Deadline
                    </StyledSubheader>
                    <FormField
                      name="deadline"
                      component={CalendarField}
                      validate={validations.deadline}
                      disablePast
                    />
                  </div>
                </>
              )}
            </div>
          </BlockUI>
        </Form>
      </BlockUI>
    </StyledRunSession>
  );
};

export default RunSession;
