import { ButtonProps } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { useMemo } from 'react';
import { EventCardType } from 'types';

const useDefaultCardMenu = (onEdit: EventCardType, onDelete: EventCardType) => {
  const menuItems = useMemo(
    () =>
      [
        {
          children: 'Edit',
          icon: <Icon name="pen" />,
          variant: 'ghost',
          iconPosition: 'left',
          onClick: (e) => {
            e.stopPropagation();
            onEdit?.(e);
          },
        },
        {
          children: 'Delete',
          icon: <Icon name="trash-can" className="color-secondary" />,
          iconPosition: 'left',
          variant: 'delete-ghost',
          onClick: (e) => {
            e.stopPropagation();
            onDelete?.(e);
          },
        },
      ] as ButtonProps[],
    [onDelete, onEdit]
  );

  return menuItems;
};

export default useDefaultCardMenu;
