import { TabOption } from '@faxi/web-component-library';
import { useState } from 'react';

import { INameExtended } from '../components/Icon';
import { LayoutPreview } from '../models';

export const previewTabs = [
  { iconName: 'grip', value: 'grid' },
  { iconName: 'bars', value: 'list' },
] as TabOption<LayoutPreview, INameExtended>[];

const useLayoutPreviewMode = (initialView: LayoutPreview = 'grid') => {
  const [view, setView] = useState<LayoutPreview>(initialView);

  return { view, setView };
};

export default useLayoutPreviewMode;
