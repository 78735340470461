import { CampaignItem } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

const useGetGampaignItem = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<{ data: CampaignItem }>(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(id)
  );

  return {
    campaignItem: data?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetGampaignItem;
