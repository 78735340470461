import { useCallback, useEffect, useRef } from 'react';

type HeightAnimationProps = {
  element: HTMLElement | null | undefined;
  isOpen: boolean;
  shouldUseFitContent?: boolean;
  animateInitially?: boolean;
  startHeight?: number;
};

/**
 * Use this hook to create an animated collapsible component.
 *
 * The `window.requestAnimationFrame()` method tells the browser you wish to perform an animation.
 * It requests the browser to call a user-supplied callback function before the next repaint.
 *
 * See more: https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
 */
const useHeightAnimation = ({
  element,
  isOpen,
  shouldUseFitContent = true,
  animateInitially = false,
  startHeight = 0,
}: HeightAnimationProps) => {
  const lastHeightRef = useRef(0);
  const requestRef = useRef(0);

  const shouldAnimate = useRef<boolean>(animateInitially);
  const defaultOpen = useRef<boolean>(isOpen);

  /**
   * The function to call when it's time to update our animation for the next repaint.
   */
  const animate = useCallback(
    (first: number, last: number, currentTime = Date.now()) => {
      const diff = last - first;

      if (!diff || !element) return;

      const elapsedTime = Date.now() - currentTime;

      let finalDuration = 0;

      if (shouldAnimate.current || defaultOpen.current !== isOpen) {
        /**
         * Acceleration factor to form an illusion of speed between different sized elements
         */
        const heightHundreds = Math.abs(diff) / 100;

        finalDuration =
          Math.min(Math.abs(diff) * 1.75, 500) + heightHundreds * 20;

        if (defaultOpen.current !== isOpen) shouldAnimate.current = true;
      }

      if (elapsedTime >= finalDuration) {
        const openElHeight = shouldUseFitContent ? 'fit-content' : `${last}px`;

        element.style.height = isOpen ? openElHeight : '0px';

        lastHeightRef.current = last;
      } else {
        const ratio = elapsedTime / finalDuration;
        const height = first + ratio * diff;

        lastHeightRef.current = height;
        element.style.height = `${height}px`;

        /**
         * The `requestAnimationFrame` will run our callback function when the screen
         * is ready to accept the next screen repaint.
         */
        requestRef.current = requestAnimationFrame(() =>
          animate(first, last, currentTime)
        );
      }
    },
    [element, isOpen, shouldUseFitContent]
  );

  useEffect(() => {
    if (element) {
      if (!isOpen) element.style.height = `${startHeight}px`;
      else element.removeAttribute('style');

      const first = isOpen ? startHeight : element.scrollHeight;
      const last = isOpen ? element.scrollHeight : startHeight;

      if (last - first) {
        requestRef.current = requestAnimationFrame(() => animate(first, last));
      }
    }

    return () => cancelAnimationFrame(requestRef.current);
  }, [isOpen, element, animate, startHeight]);
};

export default useHeightAnimation;
