import { Divider } from '@faxi/web-component-library';
import { DividerDataModule } from 'models';
import { FC } from 'react';

export type DividerPreviewProps = DividerDataModule;

const DividerPreview: FC<DividerPreviewProps> = () => (
  <Divider className="esg-divider-preview" />
);

export default DividerPreview;
