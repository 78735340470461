import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import BaseLayout from '../BaseLayout';

const StyledFoldersLayout = styled(BaseLayout)`
  .esg-folders-layout {
    &__tree-view {
      &[class] {
        height: 100%;
      }

      .esg-collapsible-side-panel {
        ${flex('column')};

        height: 100%;
        padding: ${theme.sizes.SIZE_40} ${theme.sizes.SIZE_8};
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        font-weight: 500;
        color: var(--BLACK-00);
        margin: 0 0 ${theme.sizes.SIZE_24};
        padding-left: ${theme.sizes.SIZE_32};
      }
    }

    &__content {
      ${flex('column')};
      ${size('100%')};

      overflow: auto;
      padding: ${theme.sizes.SIZE_40};

      header {
        margin: 0 0 ${theme.sizes.SIZE_32};
      }
    }
  }
`;

export { StyledFoldersLayout };
