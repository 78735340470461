import { PointerSensor } from '@dnd-kit/core';

import { handler } from '../utils';

export class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler,
    },
  ] as (typeof PointerSensor)['activators'];
}
