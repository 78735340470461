import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseSwitchFieldConfiguration = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  margin-bottom: ${theme.sizes.SIZE_40};
  gap: ${theme.sizes.SIZE_32};

  .esg-switch-field-configuration {
    &__divider {
      width: 100%;
      border-top: 1px solid var(--GRAY-EF);
    }

    &__xbrl-has-value .input__container__placeholder {
      font-size: 0.6875rem;
      top: 0.25rem;
      color: var(--PRIMARY_1_1);
    }
  }

  .esg-simple-input-field {
    justify-content: space-between;
  }

  strong {
    font-weight: 600;
  }
`;

export { StyledBaseSwitchFieldConfiguration };
