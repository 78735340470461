import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const FormActions = styled.div`
  ${flex('row', 'flex-end', 'center')}

  width: 100%;
  gap: ${theme.sizes.SIZE_10};

  .button {
    width: fit-content;
    min-width: ${theme.sizes.SIZE_104};
  }
`;
