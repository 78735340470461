import {
  CampaignSessionTreeNode,
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigType,
  SessionData,
  SessionProgress,
} from 'models';
import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { API_ROUTES } from '../../routes';
import useOrganisationsSWR from '../useOrganisationsSWR';

//Get one root organisation - Toyota
const useGetSessions = (props: { search: string }) => {
  const { search } = props ?? {};

  const { data, ...rest } = useOrganisationsSWR<{
    sessions: SessionData[];
  }>((organisationId) =>
    API_ROUTES.COMPANIES.ORGANISATION_CAMPAIGN_SESSIONS(organisationId, search)
  );

  return {
    sessions: data?.sessions,
    ...rest,
  };
};

const useGetSessionTree = (sessionId: string) => {
  const response = useSWR<CampaignSessionTreeNode>(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSION_TREE(sessionId)
  );

  const sessionTreeWithOrganisationRoot = useMemo(() => {
    const tree = response.data;
    if (!tree) return undefined;

    return {
      ...tree,
      children: tree.children!.map((subsession) => ({
        ...subsession.company,
        companySessionId: subsession.id,
        children: subsession.campaign?.children,
      })),
    } as unknown as CampaignSessionTreeNode;
  }, [response.data]);

  return { ...response, sessionTreeWithOrganisationRoot };
};

const useGetCampaignSessionForm = (sessionId?: string, formId?: string) => {
  const { data, ...rest } = useSWR(
    sessionId && formId
      ? API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSION_FORM(sessionId, formId)
      : ''
  );

  return {
    status: data?.status,
    approval: data?.approval,
    sessionFormId: data?.id,
    progress: data?.progress,
    elements: data?.elements as ModuleConfig<
      ModuleConfigType,
      DataModuleEnum
    >[],
    ...rest,
  };
};

const useGetCampaignSessionTree = (
  sessionId?: string,
  config?: SWRConfiguration
) => {
  const { data, ...rest } = useSWR(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSION_TREE(sessionId as string),
    config
  );

  return {
    data,
    ...rest,
  };
};

const useGetSessionProgress = (sessionId: string) => {
  const response = useSWR<SessionProgress[]>(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSION_PROGRESS(sessionId)
  );

  return response;
};

const useGetSessionsProgress = () => {
  const response = useSWR<SessionProgress[]>(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_SESSIONS_PROGRESS
  );

  return response;
};

export {
  useGetCampaignSessionForm,
  useGetCampaignSessionTree,
  useGetSessionProgress,
  useGetSessions,
  useGetSessionsProgress,
  useGetSessionTree,
};
