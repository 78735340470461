import { Input } from '@faxi/web-component-library';
import InlineEditable from 'components/_molecules/InlineEditable';
import { InlineModuleElement,InputDataModule } from 'models';
import { FC, memo } from 'react';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type InputCanvasModuleProps = InlineModuleElement<InputDataModule>;

const InputCanvasModule: FC<InputCanvasModuleProps> = ({
  showIcon,
  ...rest
}) => (
  <StyledModuleContainer className="esg-input-canvas-module">
    <InlineEditable
      data-no-dnd="true"
      placeholderText={'Click to enter/edit field label'}
      {...rest}
    />
    {showIcon && <Input disabled placeholder="Text" />}
  </StyledModuleContainer>
);

export default memo(InputCanvasModule);
