import { CheckListDataModule } from 'models';
import { FC, PropsWithChildren } from 'react';

import SelectModuleField from '../../../../../components/_fields/SelectModuleField';
import { useRemoveConditionalModule } from '../../../../../hooks';
import { BASE_CONFIGURABLE_MODULES } from '../../baseModules';
import BaseCheckListFieldConfiguration from '../../baseModules/BaseCheckListFieldConfiguration/BaseCheckListFieldConfiguration.component';

export type ConditionalCheckListFieldConfigurationProps =
  PropsWithChildren<CheckListDataModule>;

const ConditionalCheckListFieldConfiguration: FC<
  ConditionalCheckListFieldConfigurationProps
> = (props) => {
  const { handleRemoveModule } = useRemoveConditionalModule(props.id);

  return (
    <BaseCheckListFieldConfiguration
      {...props}
      onRemoveModule={(v) => handleRemoveModule(v)}
      renderModuleField={(fieldName) => (
        <SelectModuleField
          name={fieldName}
          label="If selected"
          parentId={props.id}
          configurableModules={BASE_CONFIGURABLE_MODULES}
        />
      )}
    />
  );
};

export default ConditionalCheckListFieldConfiguration;
