import { InputField } from 'components';
import Icon from 'components/Icon';
import { InputPreviewType } from 'models';
import { FC } from 'react';
import { ExtractComponentProps } from 'types';

export type EmailFieldProps = ExtractComponentProps<typeof InputField> &
  InputPreviewType;

const EmailField: FC<EmailFieldProps> = (props) => {
  const { type = 'email', config, ...rest } = props;

  return (
    <InputField
      type={type}
      prefixIcon={<Icon name="envelope" />}
      unit={config?.unit}
      placeholder={config?.placeholder}
      {...rest}
    />
  );
};

export default EmailField;
