import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import { Form } from '@faxi/web-form';
import styled from 'styled-components';

const StyledForgotPassword = styled(Form)`
  .esg-forgot-password {
    &__password-title {
      ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_24)};

      text-align: center;
      margin: 0 0 ${theme.sizes.SIZE_8};
    }

    &__password-content {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      text-align: center;
      margin: 0 0 ${theme.sizes.SIZE_32};
    }
  }
`;

export { StyledForgotPassword };
