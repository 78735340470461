import { Button, Tabs, useModalUtilities } from '@faxi/web-component-library';
import classNames from 'classnames';
import FoldersPreview from 'components/_molecules/FoldersPreview';
import Icon from 'components/Icon';
import {
  LayoutPreview,
  Organisation as OrganisationType,
  TreeNodeElement,
} from 'models';
import { FC, PropsWithChildren, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useLayoutPreviewMode } from '../../../hooks';
import { previewTabs } from '../../../hooks/useLayoutPreviewMode';
import CompanyModal from '../../../routes/subrouters/Organisations/modals/CompanyModal';
import { useOrganisationProvider } from '../../../routes/subrouters/Organisations/providers/Organisation';
import { buildOrganisationTreePath } from '../../../routes/subrouters/Organisations/utils';
import { StyledCompanyManagement } from './CompanyManagement.styled';

export type CompanyManagementProps = PropsWithChildren<{ className?: string }>;

const CompanyManagement: FC<CompanyManagementProps> = ({ className }) => {
  const { company } = useParams();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { activeOrganisation, mutateOrganisations, deleteOrganisation } =
    useOrganisationProvider();
  const [activeCompany, setActiveCompany] = useState<TreeNodeElement>();

  const { open, triggerRef, openModal, closeModal } = useModalUtilities();
  const buttonTrigger = triggerRef.current as HTMLButtonElement;

  const { view, setView } = useLayoutPreviewMode('grid');

  return (
    <StyledCompanyManagement
      className={classNames(className, 'esg-company-management')}
    >
      <div className="esg-company-management__buttons">
        <Button
          className="esg-organisation__create-btn"
          variant="outline"
          iconPosition="left"
          icon={<Icon name="plus" />}
          onClick={openModal}
        >
          Add company
        </Button>
        {activeOrganisation?.children.length! > 1 && (
          <Tabs<LayoutPreview>
            value={view}
            className="esg-folders-preview__tabs"
            tabs={previewTabs}
            onChange={setView}
            changeOrientationOnResponsive={false}
          />
        )}
      </div>
      <FoldersPreview
        folder={activeOrganisation}
        onSelect={(f) =>
          navigate(buildOrganisationTreePath(company ? 2 : 1, f.id, pathname))
        }
        onEdit={(c) => {
          setActiveCompany(c);
          openModal();
        }}
        onDelete={(org, e) =>
          deleteOrganisation(
            org as OrganisationType,
            e.target as HTMLButtonElement
          )
        }
        view={view}
      />
      {open && (
        <CompanyModal<OrganisationType>
          renderAsPortal
          initialData={activeCompany}
          conditionallyControlled
          triggerRef={buttonTrigger}
          mutator={mutateOrganisations}
          onClose={() => {
            closeModal();
            setActiveCompany(undefined);
          }}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
          }}
        />
      )}
    </StyledCompanyManagement>
  );
};

export default CompanyManagement;
