import {
  PermissionSections,
  RouterPermissionSections,
  TokenInformation,
} from 'models';

import {
  INITIAL_PERMISSIONS,
  ROUTER_PERMISSION_SECTIONS,
} from '../constants/Permissions';
import { UserContextProps } from '../providers/User/User.context';
import { decodeJwt } from './decodeJwt';

const isRouterPermissionSection = (
  section: PermissionSections
): section is RouterPermissionSections => ROUTER_PERMISSION_SECTIONS[section];

const parsePermissionsArray = (permissionsArray: Array<string>) => {
  let permissionsObject = INITIAL_PERMISSIONS;

  if (permissionsArray?.length) {
    permissionsObject = permissionsArray.reduce((prev, curr) => {
      const splitStringArray = curr.split(':');

      return {
        ...prev,
        [splitStringArray[1]]: [
          ...prev[splitStringArray[1] as PermissionSections],
          splitStringArray[0],
        ],
      };
    }, INITIAL_PERMISSIONS);
  }

  return permissionsObject;
};

export const getUserInformationFromToken = (
  userIdToken?: string
): UserContextProps['user'] => {
  if (!userIdToken) return undefined;

  const {
    first_name,
    last_name,
    email,
    permissions: permissionsString,
    'cognito:username': id,
  } = decodeJwt<TokenInformation>(userIdToken);

  if (!permissionsString) {
    return undefined;
  }

  const permissionsArray = JSON.parse(permissionsString) as Array<string>;

  const permissions = parsePermissionsArray(permissionsArray);

  const userRouterPermissions = Object.entries(permissions)
    .filter(
      ([key, value]) =>
        value?.length && isRouterPermissionSection(key as PermissionSections)
    )
    .map(([key]) => key) as RouterPermissionSections[];

  return {
    firstName: first_name,
    lastName: last_name,
    permissions,
    userRouterPermissions,
    email,
    id,
  };
};
