import {
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import { FC, PropsWithChildren } from 'react';

import CanvasModule from '../../../../../pages/Campaigns/components/Dnd/components/CanvasModule';
import useTemporaryModules from '../../hooks/useTemporaryModules';

type CanvasModuleWrapperProps = PropsWithChildren<{
  module: IDataModule;
  onConfig: (module: IDataModule) => void;
  onClickDelete: () => void;
  onDuplicate: (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) => void;
}>;

const CanvasModuleWrapper: FC<CanvasModuleWrapperProps> = ({
  children,
  module,
  onConfig,
  onClickDelete,
  onDuplicate,
}) => {
  const { onInitialiseConfig } = useTemporaryModules();

  return (
    <CanvasModule
      module={module}
      id={module.id}
      onConfig={onInitialiseConfig}
      onClickDelete={onClickDelete}
      onDuplicate={onDuplicate}
    >
      {children}
    </CanvasModule>
  );
};

export default CanvasModuleWrapper;
