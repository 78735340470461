import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionLayout = styled.div`
  ${flex('row')};

  overflow-y: auto;

  .esg-sessions-layout {
    &__content {
      ${size('100%')};

      overflow-y: auto;
    }
  }
`;

export { StyledSessionLayout };
