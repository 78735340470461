import { theme } from '@faxi/web-component-library';
import { flex, flexGap } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseDropdownFieldConfiguration = styled.div`
  hr {
    margin: ${theme.sizes.SIZE_20} 0;
  }

  .esg-dropdown-field-configuration {
    &__options,
    &__content {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_20, 'column')};
      margin: 0 0 ${theme.sizes.SIZE_20};
    }

    &__content {
      margin: 0;
    }

    &__option {
      ${flex('row', 'flex-start', 'center')};

      width: 100%;
      gap: ${theme.sizes.SIZE_20};

      .button--ghost {
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
      }
    }
  }
`;

export { StyledBaseDropdownFieldConfiguration };
