import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledProfileDetails = styled.div`
  max-width: ${theme.sizes.SIZE_504};

  > ul {
    ${flex('column', 'flex-start', 'stretch')};
    gap: ${theme.sizes.SIZE_32};
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      ${flex('row', 'space-between', 'flex-start')};

      > * {
        ${fontSize('14px', theme.sizes.SIZE_20)};
      }

      > span {
        font-weight: 600;
      }
    }
  }

  > .wcl-divider {
    margin: ${theme.sizes.SIZE_32} 0;
  }

  .esg-profile-details {
    &__role {
      ${flex('row', 'space-between', 'center')};

      > span {
        color: var(--GRAY-70);
      }
    }
  }
`;

export { StyledProfileDetails };
