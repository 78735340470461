import { DataModuleEnum, IDataModule } from 'models';
import { useCallback } from 'react';

import { useFormBuilder } from '../../../../pages/Campaigns/context/FormBuilder';
import { useModulesStore } from '../../../../store/modulesStore';
import { useModuleActionsProvider } from '../providers/ModuleActions.provider';

/**
 * Configuration of 'complex' modules consists of all sub-module configurations (conditional modules + section submodules).
 * While configuring we need to temporarily store changes of all related modules until submit action.
 *
 * The reason why we use 'useModulesStore' is to localise components rerenders.
 *
 * Use this hook to start the process.
 */
const useTemporaryModules = () => {
  const { modules } = useFormBuilder();
  const { setLocalFormModules } = useModulesStore();

  const { handleConfigModule } = useModuleActionsProvider();

  const onInitialiseConfig = useCallback(
    (module: IDataModule<DataModuleEnum>) => {
      setLocalFormModules(modules);
      handleConfigModule(module);
    },
    [handleConfigModule, modules, setLocalFormModules]
  );

  return { onInitialiseConfig };
};

export default useTemporaryModules;
