import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { SessionsDataProvider } from 'store/SessionsData';

const SessionsLayout: FC = () => (
  <SessionsDataProvider>
    <Outlet />
  </SessionsDataProvider>
);

export default SessionsLayout;
