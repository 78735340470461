import { APP_URI } from 'config';
import { Navigate,RouteObject } from 'react-router-dom';

import FolderWrapper from './layouts/FolderWrapper';
import { Company,Organisation, Organisations } from './pages';

export default [
  {
    path: '',
    element: <Organisations />,
  },
  {
    path: '',
    element: <FolderWrapper />,
    children: [
      {
        path: APP_URI.ORGANISATIONS_ORGANISATION,
        element: <Organisation />,
      },
      {
        path: APP_URI.ORGANISATIONS_ORGANISATION_COMPANY,
        element: <Company />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="" />,
  },
] as RouteObject[];
