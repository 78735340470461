import { Image } from '@faxi/web-component-library';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { StyledAuth } from './Auth.styled';

const AuthRouter: FC = () => {
  return (
    <StyledAuth className="esg-auth">
      <div className="app-name">
        <Image src="/assets/svg/toyota_dark.svg" alt="" />
        <h1>ESG Navigator</h1>
      </div>

      <Outlet />
    </StyledAuth>
  );
};

export default AuthRouter;
