import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSelectModuleField = styled.div<{ $fullWidth: boolean }>`
  ${flex('row', 'flex-end', 'center')};
  gap: ${theme.sizes.SIZE_12};
  min-width: fit-content;
  flex-grow: 1;

  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  justify-content: ${({ $fullWidth }) =>
    $fullWidth ? 'space-between' : 'flex-end'};

  .esg-select-module-field {
    &__label {
      white-space: nowrap;
    }

    &__select {
      visibility: visible;
      width: ${theme.sizes.SIZE_320};

      .input.wcl-select__trigger {
        width: 100%;
      }

      .input__container__placeholder {
        display: flex;
      }

      &--is-selected {
        visibility: hidden;
        width: 0;

        .input__container__placeholder {
          display: none;
        }
      }
    }
  }
`;

export { StyledSelectModuleField };
