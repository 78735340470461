import { ESGNameIcon } from 'icons';
import { FC } from 'react';

import * as Styles from './AnimatedLogo.styled';

type AnimatedLogoProps = {
  expanded: boolean;
};

const AnimatedLogo: FC<AnimatedLogoProps> = (props) => {
  const { expanded } = props;

  return (
    <Styles.Logo className="esg-animated-logo" data-expanded={expanded}>
      <ESGNameIcon className="name" />
    </Styles.Logo>
  );
};

export default AnimatedLogo;
