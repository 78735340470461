import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';

/**
 * Function to update a specific key in the conditionalElements
 * of a module with a matching parentId.
 */
const updateNestedModules = (
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[],
  parentId: string,
  name: string,
  updateFn: (
    module: ModuleConfig<ModuleConfigType, DataModuleEnum>
  ) => ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined
): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
  modules.map((m) => {
    if (m.id === parentId) {
      const updatedConditionalElements = {
        ...m.conditionalElements,
        [name]: updateFn(m.conditionalElements?.[name]),
      };

      return { ...m, conditionalElements: updatedConditionalElements };
    }

    if (m.elements) {
      const updatedElements = updateNestedModules(
        m.elements,
        parentId,
        name,
        updateFn
      );

      return { ...m, elements: updatedElements };
    }

    return m;
  });

export const updateModuleElement =
  (
    parentId: string,
    name: string,
    updateFn: (
      module: ModuleConfig<ModuleConfigType, DataModuleEnum>
    ) => ModuleConfig<ModuleConfigType, DataModuleEnum> | undefined
  ) =>
  (
    modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  ): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
    updateNestedModules(modules, parentId, name, updateFn);
