import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledPasswordField = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_8};

  > p {
    ${fontSize('12px', '18px')};

    color: var(--GRAY-98);
  }
`;

export { StyledPasswordField };
