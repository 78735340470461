import { SWRConfiguration } from 'swr';

import fetcher from './fetcher';

const MINUTES_TO_MILLISECONDS = 60 * 1000;

export const swrConfig: SWRConfiguration = {
  fetcher,
  revalidateOnFocus: false, // Revalidate data when window is focused
  shouldRetryOnError: true, // Retry on error
  dedupingInterval: 2 * MINUTES_TO_MILLISECONDS, // Avoid re-fetching the same data within this time
  errorRetryInterval: 5000, // Interval for retrying a request after an error
  errorRetryCount: 3, // Number of retry attempts
  suspense: false, // If set to true, the component will be thrown into a "suspense" state while fetching data
};

export default swrConfig;
