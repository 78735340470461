import { Contributor } from 'models';

import { StyledImageOption } from './AssignContributors.styled';
import { DEFAULT_CONTRIBUTORS } from './data';

const filterByName = (name: string) => (prev: Contributor[]) =>
  prev.filter((contributor) => contributor.name !== name);

const updateAdminByName =
  (name: string, isAdmin?: boolean) => (prev: Contributor[]) =>
    prev.map((contributor) =>
      contributor.name === name
        ? { ...contributor, isAdmin: !isAdmin }
        : contributor
    );

const getContributorsOptions = (selectedContributors: Contributor[]) =>
  DEFAULT_CONTRIBUTORS.filter(
    (contributor) =>
      !selectedContributors.some(
        (selected) => selected.name === contributor.name
      )
  ).map(({ name, src }) => ({
    label: name,
    value: name,
    icon: <StyledImageOption src={src} alt="" />,
  }));

export { filterByName, getContributorsOptions, updateAdminByName };
