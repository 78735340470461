import { Select, SelectOption, SelectProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

export type SelectFieldProps = Omit<
  FieldProps<string, (event: string) => void> & {
    className?: string;
    description?: string;
    disabled?: boolean;
  },
  'value'
> &
  SelectProps<string>;

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const {
    className,
    error,
    value,
    options,
    onChange,
    multiple,
    touched,
    dirty,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const selectOnChange = useCallback(
    (value: any) => {
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <div className={classNames('esg-select-field', className)}>
      <Select
        {...rest}
        multiple={multiple as any}
        options={options}
        value={value as string}
        closeOptionsOnClear
        renderAsPortal
        deleteInputAriaLabel="Delete input"
        chevronBtnAriaLabel="Show select options"
        onChange={(ev: SelectOption | Array<SelectOption>) =>
          selectOnChange(
            Array.isArray(ev) ? ev.map((el) => ({ value: el.value })) : ev.value
          )
        }
        error={showError}
        errorText={String(error)}
      />
    </div>
  );
};

export default SelectField;
