import {
  Button,
  Divider,
  Icon,
  useUtilities,
} from '@faxi/web-component-library';
import {
  FieldArray,
  FormField,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import { CheckListDataModule, CheckListModuleConfig } from 'models';
import { FC, PropsWithChildren, useMemo } from 'react';

import InlineEditableField from '../../../../../components/_fields/InlineEditableField';
import SelectField from '../../../../../components/_fields/SelectField';
import { SelectFieldProps } from '../../../../../components/_fields/SelectField/SelectField.component';
import { generateUniqueId } from '../../../../../utils/generateUID';
import { SimpleInputField } from '../BaseInputFieldConfiguration/components/SimpleInput/SimpleInput.component';
import { StyledBaseCheckListFieldConfiguration } from './BaseCheckListFieldConfiguration.styled';

export type BaseCheckListFieldConfigurationProps =
  PropsWithChildren<CheckListDataModule>;

const validations = {
  label: [validators.general.required('Label is required')],
  options: [validators.general.requiredArray('Required at least one')],
};
const BaseCheckListFieldConfiguration: FC<
  BaseCheckListFieldConfigurationProps
> = ({ onRemoveModule, renderModuleField, config }) => {
  const { options = [] } =
    (useFormContextValues('options') as {
      options: { label: string }[];
    }) || {};

  const baseClass = 'esg-check-list-field-configuration';

  const { showSnackBar } = useUtilities();

  const selectDefaultValuesOptions = useMemo(
    () =>
      options
        .filter((el) => !!el?.label)
        .map((el) => ({ ...el, value: el?.label })),
    [options]
  );

  return (
    <StyledBaseCheckListFieldConfiguration className="esg-check-list-field-configuration">
      <FieldArray name="options" validate={validations.options}>
        {({ fields, add, remove }) => (
          <div className={`${baseClass}__fields`}>
            {fields?.map(({ name, value }, index) => (
              <div className={`${baseClass}__fields__field`} key={value?.key}>
                <div className={`${baseClass}__fields__field__checkbox`} />
                <FormField
                  name={`${name}.label`}
                  component={InlineEditableField}
                  validate={validations.label}
                  placeholderText={'Click here to edit label'}
                  handleNoSave={() =>
                    showSnackBar({
                      text: `Can't save with the same label.`,
                      variant: 'error',
                      actionButtonText: 'Dismiss',
                    })
                  }
                  shouldSave={(text) =>
                    fields?.filter(({ value }) => text === value?.label)
                      .length <= 1
                  }
                />

                {renderModuleField?.(value.key)}

                <Button
                  variant="ghost"
                  onClick={() => {
                    onRemoveModule?.(value.key);
                    remove(index);
                  }}
                >
                  Remove item
                </Button>
              </div>
            ))}
            <Button
              variant="ghost"
              icon={<Icon name="plus" />}
              iconPosition="left"
              onClick={() => add({ key: generateUniqueId() })}
              className="esg-ghost-button-padded"
            >
              Add option
            </Button>
          </div>
        )}
      </FieldArray>

      <Divider />

      <SimpleInputField<CheckListModuleConfig, SelectFieldProps>
        switchLabel="Default selected option"
        configKey="defaultValues"
        placeholder="Default value"
        multiple
        renderAsPortal
        component={SelectField}
        options={selectDefaultValuesOptions}
        config={config as CheckListModuleConfig}
      />

      <SimpleInputField
        switchLabel="XBRL Tag"
        configKey="xbrl"
        placeholder="XBRL"
        config={config as CheckListModuleConfig}
      />
    </StyledBaseCheckListFieldConfiguration>
  );
};

export default BaseCheckListFieldConfiguration;
