import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledUserRole = styled.div`
  .esg-user-role-header {
    margin-bottom: ${theme.sizes.SIZE_48};
  }
`;

export { StyledUserRole };
