import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import { createContext, Dispatch, SetStateAction } from 'react';

export type FormBuilderContextProps = {
  selectedDataModule?: string;
  selectDataModule?: (dataModule: string) => void;
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[];
  setModules: Dispatch<
    SetStateAction<ModuleConfig<ModuleConfigType, DataModuleEnum>[]>
  >;
  loadingNewModule?: boolean;
};

export default createContext<FormBuilderContextProps>({
  modules: [],
  setModules: () => {},
});
