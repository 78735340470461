import { Campaign } from 'models';
import useSWR from 'swr';

import { API_ROUTES } from '../routes';

const useGetCampaign = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<{ data: Campaign }, Error>(
    API_ROUTES.CAMPAIGNS.CAMPAIGN_ITEM(id)
  );

  return {
    campaign: data?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetCampaign;
