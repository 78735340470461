import { CompanyCampaignsAPI } from 'models';
import useSWR, { SWRConfiguration } from 'swr';

import { API_ROUTES } from '../routes';

const useCompanyCampaigns = (shouldFetch = true, config?: SWRConfiguration) =>
  useSWR<CompanyCampaignsAPI, Error>(
    shouldFetch && API_ROUTES.COMPANIES.COMPANY_CAMPAIGNS,
    config
  );

export default useCompanyCampaigns;
