import useGetUserProfile from 'api/hooks/users/useGetUserProfile';
import { Loading } from 'components';
import { FC } from 'react';

import ProfileDetails from '../../../../../../components/ProfileDetails';

const UserDetails: FC = () => {
  const { data: user, isLoading } = useGetUserProfile();

  const {
    firstName = '',
    lastName = '',
    email = '',
    id = '',
    organisation,
    roles = [],
    jobTitle = '',
  } = user || {};

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ProfileDetails
      user={{
        firstName,
        lastName,
        email,
        id,
        organisation,
        roles,
        jobTitle,
      }}
    />
  );
};

export default UserDetails;
