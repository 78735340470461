import { DataModuleEnum } from 'models';

const DEFAULT_FORBIDDEN_VALUES = Object.values(DataModuleEnum);

/**
 * Recursively removes forbidden IDs from an array of elements.
 */
const deleteProhibitedIdsFromArray = (
  arr: Record<string, any>[],
  prohibitedIdsList = DEFAULT_FORBIDDEN_VALUES
): void => {
  for (const el of arr) {
    if (el.id && prohibitedIdsList.includes(el.id)) delete el.id;
    if (el.elements) deleteProhibitedIdsFromArray(el.elements);
  }
};

/**
 * Removes forbidden IDs from the provided data structure.
 */
export const deleteProhibitedIds = (data: Record<string, any>): void => {
  if (data.form?.elements) deleteProhibitedIdsFromArray(data.form.elements);
  if (data.elements) deleteProhibitedIdsFromArray(data.elements);
};
