import { ErrorBoundary } from 'components';
import { APP_URI, FEATURES } from 'config';
import { BaseLayout, CreateCampaignLayout, Root } from 'layouts';
import { PermissionSections } from 'models';
import { Navigate, RouteObject } from 'react-router-dom';

import { USER_PROFILE_NAV } from '../constants';
import SessionsLayout from '../layouts/SessionsLayout';
import { CampaignItemProvider } from '../pages/Campaigns/context/CampaignItem';
import { FormBuilderProvider } from '../pages/Campaigns/context/FormBuilder';
import CreateNewPassword from '../pages/CreateNewPassword';
import UserProfile from '../pages/UserProfile';
import { CampaignProvider } from '../store/CampaignProvider';
import {
  AuthRouter,
  authRoutes,
  campaignsRoutes,
  orgRoutes,
  sessionsRoutes,
  tasksRoutes,
} from './subrouters';
import { OrganisationProvider } from './subrouters/Organisations/providers/Organisation';
import { userRoutes } from './subrouters/Users';
import { userProfileRoutes } from './subrouters/Users/subrouters/UserProfile';

export type ExtendRouteObject = RouteObject & {
  authorized?: boolean;
  onlyPublic?: boolean;
  permissionsGroup?: Array<PermissionSections> | 'pending';
  children?: ExtendRouteObject[];
};

const Routes: ExtendRouteObject[] = [
  {
    path: APP_URI.AUTH,
    onlyPublic: true,
    authorized: false,
    element: <AuthRouter />,
    children: authRoutes,
  },
  {
    path: APP_URI.NEW_PASSWORD_REQUIRED,
    element: <CreateNewPassword />,
    authorized: true,
    permissionsGroup: 'pending',
  },
  {
    path: APP_URI.BASE,
    authorized: true,
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_URI.ACCOUNT_SETTINGS,
        element: <BaseLayout title="Account settings" />,
      },
      {
        path: APP_URI.PROFILE,
        element: <UserProfile navLinks={USER_PROFILE_NAV} />,
        children: userProfileRoutes,
      },
      {
        roles: ['admin', 'super-admin'],
        permissionsGroup: [PermissionSections.SESSION],
        path: APP_URI.SESSIONS,
        element: <SessionsLayout />,
        children: sessionsRoutes,
      },
      {
        permissionsGroup: [PermissionSections.ORGANISATION],
        path: APP_URI.ORGANISATIONS,
        children: orgRoutes,
        element: <OrganisationProvider />,
      },
      {
        permissionsGroup: [PermissionSections.CAMPAIGN],
        path: APP_URI.CAMPAIGNS,
        element: (
          <CampaignProvider>
            <CampaignItemProvider>
              <FormBuilderProvider>
                <CreateCampaignLayout />
              </FormBuilderProvider>
            </CampaignItemProvider>
          </CampaignProvider>
        ),
        children: campaignsRoutes,
      },
      {
        permissionsGroup: [PermissionSections.USER],
        path: APP_URI.USERS,
        children: userRoutes,
      },
      ...(FEATURES.TASKS_PAGES
        ? [
            {
              permissionsGroup: [PermissionSections.USER],
              path: APP_URI.TASKS,
              children: tasksRoutes,
            },
          ]
        : []),
      {
        index: true,
        element: <Navigate to={APP_URI.CAMPAIGNS} />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

export default Routes;
