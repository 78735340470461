import { SnackBarProps, useUtilities } from '@faxi/web-component-library';
import { FC, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import authBus from '../../modules/authBus';
import { UserProvider } from '../../providers/User';
import Sidebar from '../Sidebar';
import { StyledRoot } from './Root.styled';

const Root: FC = () => {
  const { showSnackBar } = useUtilities();

  const handleShowSnackBar = useCallback(
    (payload: {
      props: SnackBarProps;
      config?: {
        constant?: boolean;
        disappearAfter?: number;
      };
    }) => {
      showSnackBar(payload.props, payload.config);
    },
    [showSnackBar]
  );

  useEffect(() => {
    authBus.addEventListener('show_snackbar', handleShowSnackBar);

    return () => {
      authBus.removeEventListener('show_snackbar', handleShowSnackBar);
    };
  }, [handleShowSnackBar]);

  return (
    <UserProvider>
      <StyledRoot>
        <Sidebar />
        <Outlet />
      </StyledRoot>
    </UserProvider>
  );
};

export default Root;
