/**
 *
 * Inserts page breaks (<hr> elements) into a collection of HTML elements
 * based on the specified page height, gap between elements, and initial offset.
 *
 * @param elements - The collection of HTML elements to process.
 * @param pageHeight - The height of a single page.
 * @param gap - The gap between elements.
 * @param initialOffset - The initial offset from the top of the page.
 */
export const insertPageBreaks = (
  elements: HTMLCollection,
  pageHeight: number,
  gap: number,
  initialOffset: number
): void => {
  // Remove all existing page breaks before inserting new ones
  const pageBreaks = document.querySelectorAll('.page-break');
  pageBreaks.forEach((pageBreak) => pageBreak.remove());

  let currentPageOffset = initialOffset;
  const insertIndexes: number[] = [];

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    // Calculate the maximum height the element can have on the current page
    const maxElementHeight =
      i === 0 ? pageHeight - gap - initialOffset : pageHeight - gap;

    // If the element is too tall to fit on the current page
    if (element.clientHeight >= maxElementHeight) {
      // If not the first element and there's already some content on the page, insert a page break before this element
      if (currentPageOffset > 0 && i > 0)
        insertIndexes.push(insertIndexes.length + i);

      // Insert a page break after this element if it is not the last element
      if (i < elements.length - 1) {
        insertIndexes.push(insertIndexes.length + i + 1);
        currentPageOffset = 0;
      }
    } else {
      currentPageOffset += element.clientHeight + gap;

      // If the current page offset exceeds the page height, insert a page break
      if (currentPageOffset >= pageHeight) {
        insertIndexes.push(insertIndexes.length + i);
        currentPageOffset = element.clientHeight + gap; // Start a new page with the current element
      }
    }
  }

  // Insert <hr> elements at the calculated indexes to represent page breaks
  for (const index of insertIndexes) {
    const element = elements[index];

    if (element) {
      const newHr = document.createElement('hr');
      newHr.className = 'page-break';
      element.parentNode!.insertBefore(newHr, element);
    }
  }
};
