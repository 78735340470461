import { GlowScroll } from '@faxi/web-component-library';
import api from 'api';
import { APP_URI } from 'config';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import authBus from '../../../../modules/authBus';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import CampaignEntity from '../CampaignEntity';

const Topic: FC = () => {
  const { topic = '', campaign = '' } = useParams<{
    topic: string;
    campaign: string;
  }>();

  const { campaignItem, isLoading, mutate } = api.useGetGampaignItem(topic);

  const { mutating, submitForm } = useCampaignFormEntitySubmit(
    topic,
    mutate,
    campaignItem
  );

  const navigateTo = useCallback(
    (name: string) =>
      APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC.replace(
        ':campaign',
        campaign
      )
        .replace(':topic', topic)
        .replace(':subtopic', name),
    [campaign, topic]
  );

  useEffect(() => {
    authBus.addEventListener('delete_campaign_entity', ({ id }) => {
      campaignItem?.children.some((item) => item?.id === id) && mutate();
    });
  }, [campaignItem, mutate]);

  return (
    <GlowScroll variant="gray">
      <CampaignEntity
        campaignItem={campaignItem}
        navigateTo={navigateTo}
        entityType="subtopic"
        modalProps={{ loading: mutating }}
        isLoading={isLoading}
        onSubmit={submitForm}
        onMutate={mutate}
      />
    </GlowScroll>
  );
};

export default Topic;
