import { CampaignItem, SessionTree,TreeNodeElement  } from 'models';
import { createContext, Dispatch, SetStateAction } from 'react';

export type OrganisationContextProps = {
  loading: boolean;
  tree?: TreeNodeElement;
  session?: SessionTree;
  activeCampaignId?: string;
  activeReportNode?: CampaignItem | TreeNodeElement;
  setTree: Dispatch<SetStateAction<TreeNodeElement | undefined>>;
};

export default createContext<OrganisationContextProps>({
  tree: { id: '', name: '' },
  loading: false,
  activeCampaignId: '',
  setTree: () => {},
});
