import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledOverflowTooltipText = styled.div`
  ${flex('row', 'flex-start', 'center')};
  position: relative;
  gap: ${theme.sizes.SIZE_12};
`;

export { StyledOverflowTooltipText };
